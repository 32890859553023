import React, { Component } from 'react';
import "../assets/css/mui-multiselect.css"
import axios from 'axios';
import Loader from '../services/normalloader';
import TopNavDropdown from './TopDropdown';
import Moment from 'moment';
import * as apiService from '../services/apiService';
import { CardHeader, Card, CardBody, CardTitle, Col, Nav, NavItem, NavLink, Row, TabContent, TabPane, Button } from 'reactstrap';
import { MDBContainer, MDBBtn, MDBModal, MDBModalBody, MDBModalHeader, MDBModalFooter } from 'mdbreact';
import Switch from '@material-ui/core/Switch';
import { Checkbox, ListItemIcon, ListItemText } from "@material-ui/core"
import classnames from 'classnames';
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import { DateTime } from 'luxon';
import {
    FormBuilder,
    FieldGroup,
    FieldControl,
    Validators,
} from "react-reactive-form";
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import { FormControl, InputLabel, Select } from '@material-ui/core';
import swal from 'sweetalert';
import { DateRangePickerComponent, PresetsDirective, PresetDirective } from '@syncfusion/ej2-react-calendars';
import Thermostat from './Thermostat';
import floor_image from '../assets/images/poorvika_blueprint.jpg';
import dial_image from '../assets/images/dial_nbz.png';
import swingicon from '../assets/images/swing2.png';
import swingicon1 from '../assets/images/displayswing.png';
import schedule from '../assets/images/schedule.png';
import remote from '../assets/images/remote.png';
import '../views/LightScheduleCss.css'
const $ = require('jquery');
$.DataTable = require('datatables.net');
class LightScheduleComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedCircuitBreaker: [],
            isAllCircuitBreakerSelected: false,
            loader: false,
            showcontent: true,
            modal: false,
            activetab: "3",
            dataChange: false,
            buttontext: 'Add',
            boardbuttontext: 'Add',
            schedules: [],
            isAddingSchedule: false,
            isEditingSchedule: false,
            selectedSchedule: null,
            ChoosedThermoStat: null,
            heater_thermo_id: '',
            thermodropDownList: [],
            BoardList: [],
            AllCustomerList: [],
            dropDownList: {
                equipment: [],
                operation: [],
                speed: [],
                swing: [],
                temperature: []
            },
            newSchedule: {
                schedule_name: '',
                customer_id: '',
                events: [],
            },
            newEvent: {
                time: '',
                operation: '',
            },
            newAssignment: {
                //from_date: "",
                to_date: '',
                light_schedule_id: '',
                lboard_id: ''
            },
            thermostatList: [],
            formatScheduleList: [],
            formatBoardAssignList: [],
            formatBreakerAssignList: [],
            allLightBoardList: [],
            formatLightBoardList: [],
            allLightBreakerList: [],
            Boardselected: [],
            AssignBoardselected: [],
            dateselected: [new Date(new Date().toDateString()), new Date(new Date().toDateString())],
            from_date: "",
            to_date: '',
            searchTerm: "",
            displayData: {
                temp: 0,
                power: 'AUTO',
                fan: 'AUTO',
                swing: 'AUTO',

            }
        };
        this.allScheduleList = [];

        this.allLightBoardList = [];
        this.allLightBreakerList = [];
        this.allAssignList = [];
        this.selectedcustomer = localStorage.getItem('selectedcustomer');
        this.customerhandler = this.customerhandler.bind(this);
        this.getdropdowndata();
        //this.getThermoList();
        this.getallcustomerdata();
        this.activeTab = "3";
        this.toggle('3');
    }
    customerhandler(customerId) {
        this.selectedcustomer = customerId
        this.setState({
            showcontent: true
        });
        localStorage.setItem('selectedcustomer', customerId)
        this.toggle("3");
    }

    customerInfoForm = FormBuilder.group({
        id: [""],
        customer_id: [""],
        project_name: ["", [Validators.required]],
        main_breaker: ["", [Validators.required]],
        sub_main_breaker: ["", [Validators.required]],
        positions: ["", [Validators.required]],
        load_types: ["", [Validators.required]],
        indoor_lights: ["", [Validators.required]],
        outdoor_lights: ["", [Validators.required]],
        sign_boards: ["", [Validators.required]],
        other_loads: ["", [Validators.required]],
    });
    BoardForm = FormBuilder.group({
        id: [""],
        customer_id: [""],
        lboard: ["", [Validators.required]],
        breaker_type: [""],
        breaker_name: [""]
    });
    breakerCiruitForm = FormBuilder.group({
        id: [""],
        customer_id: [""],
        circuit_type: ["", [Validators.required]],
        lboard_id: [""],
        position: [""],
        breaker: [""],
    });
    handleSubmitControl(event, val) {
        event.preventDefault();
        this.setState({ loader: true })
        apiService.heaterController(val, this.state.heater_thermo_id).then(response => {
            this.setState({ loader: false })
            let msg = response.data[0].message
            apiService.Alerts(msg)
        }).catch(error => {
            this.setState({ loader: false })
            apiService.ErrorMessage(error)
        })
    }
    CustomerInfocancel() {
        this.setState({
            buttontext: 'Add'
        })
        this.customerInfoForm.reset()
    }
    Boardcancel() {
        this.setState({
            boardbuttontext: 'Add'
        })
        this.BoardForm.reset()
    }
    DigitalCircuitcancel() {
        this.setState({
            boardbuttontext: 'Add'
        })
        this.breakerCiruitForm.reset()
        this.breakerCiruitForm.patchValue({
            lboard_id: this.state.Boardselected,
        })
        //this.breakerCiruitForm.reset()

    }
    getCustomerInfo() {
        this.customerInfoForm.reset();
        this.setState({ loader: true })
        apiService.getCustomerInfo().then(response => {
            this.customerInfoForm.patchValue({
                customer_id: response.data[0].customer_id,
                project_name: response.data[0].project_name,
                main_breaker: response.data[0].main_breaker,
                sub_main_breaker: response.data[0].sub_main_breaker,
                positions: response.data[0].positions,
                load_types: response.data[0].load_types,
                indoor_lights: response.data[0].indoor_lights,
                outdoor_lights: response.data[0].outdoor_lights,
                sign_boards: response.data[0].sign_boards,
                other_loads: response.data[0].other_loads,
            })

            this.setState({ loader: false })
        }).catch(error => {
            this.setState({ loader: false })
            apiService.ErrorMessage(error)
        })
    }

    handleCustomerInfoSubmit(event, value) {

        event.preventDefault();
        value.customer_id = this.selectedcustomer;
        if (value.id == '' || value.id == undefined || value.id == null) {
            this.setState({ loader: true })
            apiService.updateCustomerInfo(value).then(response => {
                this.setState({ loader: false })
                apiService.Alerts("Customer Info Updated Successfully")
                this.thermocancel();
                this.toggle("3");
            }).catch(error => {
                this.setState({ loader: false })
                apiService.ErrorMessage(error)
            })
        } else {
            this.setState({ loader: true })
            apiService.updateHeaterThermostat(value).then(response => {
                this.setState({ loader: false })
                apiService.Alerts("Customer Info Updated Successfully")
                this.thermocancel();
                this.toggle("3");
            }).catch(error => {
                this.setState({ loader: false })
                apiService.ErrorMessage(error)
            })
        }

    }
    handleBoardSubmit(event, value) {

        event.preventDefault();
        if (value.id == '' || value.id == undefined || value.id == null) {
            this.setState({ loader: true })
            apiService.addLightBoard(value).then(response => {
                this.setState({ loader: false })
                apiService.Alerts("Light Board Added Successfully")
                this.Boardcancel();
                this.toggle("5");
            }).catch(error => {
                this.setState({ loader: false })
                apiService.ErrorMessage(error)
            })
        } else {
            this.setState({ loader: true })
            apiService.updateLightBoard(value).then(response => {
                this.setState({ loader: false })
                apiService.Alerts("Light Board Updated Successfully")
                this.Boardcancel();
                this.toggle("5");
            }).catch(error => {
                this.setState({ loader: false })
                apiService.ErrorMessage(error)
            })
        }

    }
    handleDegitalCircuitSubmit(event, value) {

        event.preventDefault();
        if (value.id == '' || value.id == undefined || value.id == null) {
            this.setState({ loader: true })
            apiService.addLightBreaker(value).then(response => {
                this.setState({ loader: false })
                apiService.Alerts("Digital Breaker Circuit Added Successfully")
                this.DigitalCircuitcancel();
                //this.toggle("6");
                this.getLightBreaker(this.state.Boardselected);
            }).catch(error => {
                this.setState({ loader: false })
                apiService.ErrorMessage(error)
            })
        } else {
            this.setState({ loader: true })
            apiService.updateLightBreaker(value).then(response => {
                this.setState({ loader: false })
                apiService.Alerts("Digital Breaker Circuit Updated Successfully")
                this.DigitalCircuitcancel();
                //this.toggle("6");
                this.getLightBreaker(this.state.Boardselected);
            }).catch(error => {
                this.setState({ loader: false })
                apiService.ErrorMessage(error)
            })
        }

    }
    boardedit(value) {
        this.BoardForm.patchValue({
            id: value.id,
            breaker_name: value.breaker_name,
            breaker_type: value.breaker_type,
            lboard: value.lboard,
            customer_id: value.customer_id,
        })
        this.setState({
            boardbuttontext: 'Update'
        })
    }

    boarddelete(event, value) {
        event.preventDefault();
        swal({
            title: "Are you sure?",
            text: "Once deleted, you will not be able to recover this board!",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((willDelete) => {
                if (willDelete) {
                    event.preventDefault();
                    this.setState({ loader: true })
                    apiService.deleteLightBoard(value).then(response => {
                        this.setState({ loader: false })
                        apiService.Alerts("Board Deleted Successfully")
                        this.getLightBoard();
                        this.Boardcancel();
                    }).catch(error => {
                        this.setState({ loader: false })
                        apiService.ErrorMessage(error)
                    })
                } else {
                    // swal("Your file is safe!");
                }
            });
    }
    thermostatedit(value) {
        this.thermostatForm.patchValue({
            id: value.id,
            name: value.thermo_name,
            desc: value.thermo_desc,
            board_id: value.board_id,
            customer_id: value.customer_id,
        })
        this.setState({
            buttontext: 'Update'
        })
    }

    thermostatdelete(event, value) {
        event.preventDefault();
        swal({
            title: "Are you sure?",
            text: "Once deleted, you will not be able to recover this Thermostat!",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((willDelete) => {
                if (willDelete) {
                    event.preventDefault();
                    this.setState({ loader: true })
                    apiService.deleteHeaterThermostat(value).then(response => {
                        this.setState({ loader: false })
                        apiService.Alerts("Thermostat Deleted Successfully")
                        this.getThermostat();
                        this.thermocancel();
                    }).catch(error => {
                        this.setState({ loader: false })
                        apiService.ErrorMessage(error)
                    })
                } else {
                    // swal("Your file is safe!");
                }
            });
    }
    lightBreakeredit(value) {
        this.breakerCiruitForm.patchValue({
            id: value.id,
            circuit_type: value.circuit_type,
            lboard_id: value.lboard_id,
            position: value.position,
            breaker: value.breaker,
            customer_id: value.customer_id,
        })
        this.setState({
            buttontext: 'Update'
        })
    }
    lightBreakerdelete(event, value) {
        event.preventDefault();
        swal({
            title: "Are you sure?",
            text: "Once deleted, you will not be able to recover this Light Breaker Circuit!",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((willDelete) => {
                if (willDelete) {
                    event.preventDefault();
                    this.setState({ loader: true })
                    apiService.deleteLightBreaker(value).then(response => {
                        this.setState({ loader: false })
                        apiService.Alerts("Light Breaker Circuit Deleted Successfully")
                        this.getLightBreaker(this.state.Boardselected);
                        this.DigitalCircuitcancel();
                    }).catch(error => {
                        this.setState({ loader: false })
                        apiService.ErrorMessage(error)
                    })
                } else {
                    // swal("Your file is safe!");
                }
            });
    }
    getdropdowndata() {
        this.setState({ loader: true })
        apiService.getThermostatDropdown().then(response => {
            var x = {
                equipment: response.data[0].Equipment,
                operation: [{ id: '1', value: "ON" }, { id: '0', value: "OFF" }],
                speed: response.data[2].speed,
                swing: response.data[3].swing,
                temperature: response.data[4].temperature,

            }
            this.setState({ dropDownList: x, loader: false })
        }).catch(error => {
            this.setState({ loader: false })
            apiService.ErrorMessage(error)
        })
    }
    getallcustomerdata() {
        this.setState({ loader: true })
        apiService.topCustomerDropdown().then(response => {
            var x = response.data;
            this.setState({ AllCustomerList: x, loader: false })
        }).catch(error => {
            this.setState({ loader: false })
            apiService.ErrorMessage(error)
        })
    }
    cancel() {
        this.setState({
            newEvent: {
                time: '',
                operation: '',
            }
        });
    }
    scheduleCancel() {
        // this.setState({ isAddingSchedule: false, isEditingSchedule: false })
        this.setState({
            newEvent: {
                time: '',
                operation: '',
            },
            newSchedule: {
                schedule_name: '',
                customer_id: '',
                events: [],
            },
            isAddingSchedule: false,
            isEditingSchedule: false
        });
    }
    getThermoList() {
        apiService.getHeaterThermostat().then(response => {
            this.setState({ thermostatList: response.data, loader: false })

        }, error => {
            this.setState({ loader: false })
            apiService.ErrorMessage(error)

        })
    }
    getLightBoard() {
        this.setState({ loader: true })
        apiService.getLightBoard().then(response => {
            this.setState({ loader: false })
            this.allLightBoardList = response.data;
            this.setState({ allLightBoardList: response.data, loader: false });
        }, error => {
            this.setState({ loader: false })
            apiService.ErrorMessage(error)

        })
    }
    formatLightBoardData() {

        var formatData = this.state.allLightBreakerList.map(item => ({
            ...item,
            ['board_id']: this.BindBoardName(item.lboard_id),
            ['updated_at']: Moment(item.updated_at).format('MMM DD YYYY HH:mm:ss')// Update the specified column
        }));
        this.setState({ formatLightBoardList: formatData });
    }
    getLightBreaker(e) {
        this.setState({ Boardselected: e, loader: true });
        this.setState({ selectedCircuitBreaker: [], isAllCircuitBreakerSelected: false });
        if (e == '' || e == undefined || e == null) {
            e = '0';
        }
        apiService.getLightBreaker(e).then(response => {
            this.setState({ allLightBreakerList: response.data, loader: false });
            this.allLightBreakerList = response.data;
            this.formatLightBoardData();
        }, error => {
            this.setState({ loader: false })
            apiService.ErrorMessage(error)

        })
    }
    componentDidMount() {
         //this.getCustomerInfo();
    }
    toggle(option) {
        var changeToggle = false;
        if (this.activeTab != option) {
            if (this.activeTab == '1' && (this.state.isAddingSchedule || this.state.isEditingSchedule)) {
                swal({
                    title: "There are some unsaved changes in the schedule!",
                    text: "Once the tab is switched, you will not be able to recover the entered schedule",
                    icon: "warning",
                    buttons: true,
                    dangerMode: true,
                })
                    .then((willDelete) => {
                        if (willDelete) {
                            if (true) {
                                this.activeTab = option;
                                this.setState({
                                    activetab: option,
                                    searchTerm: ''
                                })
                                if (option == "2") {
                                    this.datehandleChange1([new Date(new Date().toDateString()), new Date(new Date().toDateString())]);
                                    this.getLightBoard();
                                    this.fetchSchedules();
                                    this.fetchAssignment(this.state.AssignBoardselected);
                                }
                                if (option == "3") {
                                    this.getCustomerInfo();
                                }
                                if (option == "1") {
                                    this.fetchSchedules();
                                    this.scheduleCancel();
                                }
                                if (option == "4") {
                                    this.datehandleChange1([new Date(new Date().toDateString()), new Date(new Date().toDateString())]);
                                    this.getLightBoard();
                                    this.getLightBreaker(this.state.AssignBoardselected);
                                    this.fetchSchedules();
                                    this.fetchCircuitBreakerAssignment(this.state.AssignBoardselected);
                                }
                                if (option == "5") {
                                    this.getLightBoard();
                                }
                                if (option == "6") {
                                    this.getLightBoard();
                                    //this.getLightBreaker();
                                }
                            }
                        } else {
                            changeToggle = false;
                        }
                    });
            } else {
                changeToggle = true;
            }
        } else {
            if(this.activeTab=='3')
            {
                changeToggle=true;
            }
            else{
                changeToggle = false;
                return false;
            }
            
        }
        if (changeToggle) {
            this.activeTab = option;
            this.setState({
                activetab: option,
                searchTerm: ''
            })
            if (option == "2") {
                this.datehandleChange1([new Date(new Date().toDateString()), new Date(new Date().toDateString())]);
                this.getLightBoard();
                this.fetchSchedules();
                this.fetchAssignment(this.state.AssignBoardselected);
            }
            if (option == "3") {
                this.getCustomerInfo();
            }
            if (option == "1") {
                this.fetchSchedules();
                this.scheduleCancel();
            }
            if (option == "4") {
                this.datehandleChange1([new Date(new Date().toDateString()), new Date(new Date().toDateString())]);
                this.getLightBoard();
                this.getLightBreaker(this.state.AssignBoardselected);
                this.fetchSchedules();
                this.fetchCircuitBreakerAssignment(this.state.AssignBoardselected);
            }
            if (option == "5") {
                this.getLightBoard();
            }
            if (option == "6") {
                this.getLightBoard();
                //this.getLightBreaker();
            }
        }
    }

    fetchAssignment = (e) => {
        if (this.activeTab == "2") {
            this.allAssignList = [];
            if (e == '' || e == undefined || e == null) {
                e = '0';
            }
            this.setState({ AssignBoardselected: e, loader: true })
            apiService.getLightAssignmentListForBoard(e).then(response => {
                this.allAssignList = response.data;
                this.setState({ loader: false });
                this.formatBoardAssignmentData();
            }, error => {
                this.setState({ loader: false })
                apiService.ErrorMessage(error)

            });
        }
    };
    formatBoardAssignmentData() {

        var formatData = this.allAssignList.map(item => ({
            ...item,
            ['lboard_id']: this.BindBoardName(item.lboard_id),
            ['light_schedule_id']: this.BindScheduleName(item.light_schedule_id),
            ['updated_at']: Moment(item.from_date).format('MMM DD YYYY') + " to " + Moment(item.to_date).format('MMM DD YYYY')
        }));
        this.setState({ formatBoardAssignList: formatData });
    }
    fetchCircuitBreakerAssignment = (e) => {
        if (this.activeTab == "4") {
            this.allAssignList = [];
            if (e == '' || e == undefined || e == null) {
                e = '0';
            }
            this.setState({ AssignBoardselected: e, loader: true })
            apiService.getLightAssignmentListForCircuitBreaker(e).then(response => {
                this.allAssignList = response.data;
                this.setState({ loader: false })
                this.formatBreakerAssignmentData();
            }, error => {
                this.setState({ loader: false })
                apiService.ErrorMessage(error)

            });
        }
    };
    formatBreakerAssignmentData() {

        var formatData = this.allAssignList.map(item => ({
            ...item,
            ['lboard_id']: this.BindBoardName(item.lboard_id),
            ['light_schedule_id']: this.BindScheduleName(item.light_schedule_id),
            ['breaker_name']: this.GenerateCircuitName(item.breakers),
            ['updated_at']: Moment(item.from_date).format('MMM DD YYYY') + " to " + Moment(item.to_date).format('MMM DD YYYY')
        }));
        this.setState({ formatBreakerAssignList: formatData });
    }
    fetchSchedules = () => {
        this.setState({ loader: true })
        apiService.getLightScheduleList(this.selectedcustomer).then(response => {
            this.allScheduleList = response.data;
            this.setState({ schedules: response.data, loader: false });
            this.formatScheduleData();
        }, error => {
            this.setState({ loader: false })
            apiService.ErrorMessage(error)

        });
    };
    formatScheduleData() {

        var formatData = this.allScheduleList.map(item => ({
            ...item,
            //['board_id']: this.BindBoardName(item.lboard_id),
            ['updated_at']: Moment(item.updated_at).format('MMM DD YYYY HH:mm:ss')// Update the specified column
        }));
        this.setState({ formatScheduleList: formatData });
    }
    handleInputChange = (event) => {
        const { name, value } = event.target;
        this.setState({ newSchedule: { ...this.state.newSchedule, [name]: value } });
    };
    handleSearchInputChange = (event) => {
        const { name, value } = event.target;
        this.setState({ searchTerm: value });
    };
    handleEventInputChange = (event) => {
        const { name, value } = event.target;
        this.setState({ newEvent: { ...this.state.newEvent, [name]: value } });
    };
    handleAssignEventInputChange = (event) => {
        const { name, value } = event.target;
        this.setState({ newAssignment: { ...this.state.newAssignment, [name]: value } });
    };
    handleHeaterEventInputChange = (event) => {
        const { name, value } = event.target;
        this.setState({ heater_thermo_id: event.target.value });
    };
    datehandleChange1(e) {
        const { newEvent, newAssignment } = this.state;

        this.setState({
            from_date: this.dateconvertion(e[0]),
            to_date: this.dateconvertion(e[1])
        });
    }

    dateconvertion(e) {
        const sy = e.getFullYear();
        const sm = ("0" + (e.getMonth() + 1)).slice(-2);
        const sd = ("0" + e.getDate()).slice(-2);
        var x = sy.toString() + '-' + sm.toString() + '-' + sd.toString();
        return x
    }
    addEvent = () => {
        const { newEvent, newSchedule } = this.state;
        if (this.state.newEvent.time == '' || this.state.newEvent.operation == '') {
            apiService.Alerts_error('Enter Event Details');
            return false;
        }

        this.setState({
            newSchedule: {
                ...newSchedule,
                events: [...newSchedule.events, { ...newEvent }],
            },
            newEvent: {
                time: '',
                operation: '',
            },
        });
    };
    BindScheduleName(id) {
        if (this.allScheduleList.filter(s => s.id == id).length > 0)
            return this.allScheduleList.filter(s => s.id == id)[0]['schedule_name']
        else {
            return "";
        }
    }
    BindBoardName(id) {
        if (this.allLightBoardList.filter(s => s.id == id).length > 0)
            return this.allLightBoardList.filter(s => s.id == id)[0]['lboard']
        else {
            return "";
        }
    }
    GenerateCircuitName(event) {
        const values = event.map(item => this.BindDigitalCircuitName(item));
        return values.join(', ');
    }
    BindDigitalCircuitName(id) {
        if (this.allLightBreakerList.filter(s => s.id == id).length > 0)
            return this.allLightBreakerList.filter(s => s.id == id)[0]['breaker'].toString();
        else {
            return "";
        }
    }
    BindEquipmentValue(id) {
        return this.state.dropDownList.equipment.filter(s => s.id == id)[0]['value']
    }
    BindModeValue(id) {
        if (this.state.dropDownList.operation.filter(s => s.id == id).length > 0) {
            return this.state.dropDownList.operation.filter(s => s.id == id)[0]['value']
        } else {
            return "";
        }

    }
    BindSpeedValue(id) {
        return this.state.dropDownList.speed.filter(s => s.id == id)[0]['value']
    }
    BindSwingValue(id) {
        return this.state.dropDownList.swing.filter(s => s.id == id)[0]['value']
    }
    BindTemperatureValue(id) {
        return this.state.dropDownList.temperature.filter(s => s.id == id)[0]['value']
    }
    addSchedule = () => {
        if (this.state.newSchedule.schedule_name == '' || this.state.newSchedule.events.length == 0) {
            apiService.Alerts_error("Enter Schedule Name and Events");
            return false;
        }
        var timeZone = this.state.AllCustomerList.filter(s => s.id == this.selectedcustomer)[0]['time_zone']
        const updatedEvents = this.state.newSchedule.events.map(event => {
            return {
                ...event,
                time: this.convertToIndianTime(event.time, timeZone),
            };
        });
        this.setState(prevState => ({
            newSchedule: {
                ...prevState.newSchedule,
                events: updatedEvents,
            },
        }));
        const { newSchedule, schedules } = this.state;
        this.state.newSchedule.customer_id = this.selectedcustomer;
        var param = { schedule: [] };
        param.schedule = [this.state.newSchedule];
        param.schedule[0].events = updatedEvents
        this.setState({ loader: true })
        apiService.addLightScheduleCreation(param).then(response => {
            apiService.Alerts("Schedule Created Successfully")
            this.setState({ loader: false })
            this.setState({
                schedules: [...schedules, response.data],
                newSchedule: {
                    schedule_name: '',
                    events: [],
                },
                isAddingSchedule: false,
            });
            this.fetchSchedules();
        }).catch(error => {
            this.setState({ loader: false })
            apiService.ErrorMessage(error)
        })
    };
    handleLightBreakerSelectAll = () => {
        const { isAllCircuitBreakerSelected } = this.state;
        const allIds = this.state.allLightBreakerList.map((option) => option.id);
        // this.dailyreportForm.patchValue({
        //     meter: isAllCircuitBreakerSelected ? [] : allIds
        // })
        this.daily_pri_list = []
        this.setState({
            selectedCircuitBreaker: isAllCircuitBreakerSelected ? [] : allIds,
            isAllCircuitBreakerSelected: !isAllCircuitBreakerSelected,
        });
    };
    BreakerhandleChange = (event) => {
        const { value } = event.target;
        if (value.includes('all')) {
            this.handleLightBreakerSelectAll();
            return;
        }
        this.setState({ selectedCircuitBreaker: value, isAllCircuitBreakerSelected: false });
        if (this.state.allLightBreakerList.length == value.length) {
            this.setState({ isAllCircuitBreakerSelected: true });
        }
        // this.dailyreportForm.patchValue({
        //     meter: value
        // })

        // const value = event.target.value;

        // if (value[value.length - 1] === "all") {
        //   this.setState({
        //     selectedCircuitBreaker: this.state.selectedCircuitBreaker.length === this.state.allLightBreakerList.length ? [] : this.state.allLightBreakerList
        //   });
        //   return;
        // }
        // this.allLightBreakerList.map
        // this.setState({
        //   selectedCircuitBreaker: [this.state.allLightBreakerList.filter(s=>s.id==value)]
        // });
    };
    convertToIndianTime = (inputTime, timeZone) => {
        if (timeZone == 'Asia/Kolkata' || timeZone == '') {
            return inputTime;
        } else {
            const newYorkTime = DateTime.fromFormat(inputTime, 'HH:mm', { zone: timeZone });
            const istTime = newYorkTime.setZone('Asia/Kolkata');
            //const options = { hour: 'numeric', minute: 'numeric', hour12: false, timeZoneName: 'short' };
            const istTimeFormatted = istTime.toLocaleString(DateTime.TIME_SIMPLE);
            //const newTime = new Date(`2000-01-01T${istTimeFormatted}`);
            const [time, amPm] = istTimeFormatted.split(' ');
            const [hours, minutes] = istTimeFormatted.split(':');
            let hour = parseInt(hours, 10);

            if (amPm === 'PM' && hour !== 12) {
                hour += 12;
            } else if (amPm === 'AM' && hour === 12) {
                hour = 0;
            }
            const formattedTime = `${hour.toString().padStart(2, '0')}:${minutes}`;
            const [FinalTime, removeAM] = formattedTime.split(' ');
            return FinalTime;
        }
    };
    convertToZoneTime = (inputTime, timeZone) => {
        if (timeZone == 'Asia/Kolkata' && timeZone == '') {
            return inputTime;
        } else {
            const [hoursS, minutesS] = inputTime.split(':');
            let Nhours = hoursS;
            let Nminutes = minutesS;
            if (hoursS.length == 1) {
                Nhours = hoursS.padStart(2, '0');
            }
            if (minutesS.length == 1) {
                Nminutes = minutesS.padStart(2, '0');
            }
            const newTime = `${Nhours}:${Nminutes}`;
            const newYorkTime = DateTime.fromFormat(newTime, 'HH:mm', { zone: 'Asia/Kolkata' });
            const istTime = newYorkTime.setZone(timeZone);
            //const options = { hour: 'numeric', minute: 'numeric', hour12: false, timeZoneName: 'short' };
            const istTimeFormatted = istTime.toLocaleString(DateTime.TIME_SIMPLE);
            //const newTime = new Date(`2000-01-01T${istTimeFormatted}`);
            const [time, amPm] = istTimeFormatted.split(' ');
            const [hours, minutes] = istTimeFormatted.split(':');
            let hour = parseInt(hours, 10);

            if (amPm === 'PM' && hour !== 12) {
                hour += 12;
            } else if (amPm === 'AM' && hour === 12) {
                hour = 0;
            }
            const formattedTime = `${hour.toString().padStart(2, '0')}:${minutes}`;
            const [FinalTime, removeAM] = formattedTime.split(' ');
            return FinalTime;
        }
    };
    addAssignment = () => {
        if (this.state.newAssignment.lboard_id == '' || this.state.newAssignment.light_schedule_id == '') {
            apiService.Alerts_error("Enter all details");
            return false;
        }
        if (this.state.from_date == '' || this.state.to_date == '') {
            apiService.Alerts_error("Enter all details");
            return false;
        }
        const { newAssignment, assignments } = this.state;
        this.setState({ loader: true });
        var param = this.state.newAssignment;
        //param.thermo_desc = this.state.thermostatList.filter(s => s.id == param.thermo_id)[0]['thermo_desc'];
        //param.thermo_name = this.state.thermostatList.filter(s => s.id == param.thermo_id)[0]['thermo_name'];
        apiService.addHLightAssignmentForBoard(param, this.state.from_date, this.state.to_date).then(response => {
            apiService.Alerts("Assign Created Successfully")
            this.setState({ loader: false })
            this.fetchAssignment(this.state.AssignBoardselected);
        }).catch(error => {
            this.setState({ loader: false })
            apiService.ErrorMessage(error)
        })
    };
    addCircuitBreakerAssignment = () => {
        if (this.state.newAssignment.lboard_id == '' || this.state.newAssignment.light_schedule_id == '' || this.state.selectedCircuitBreaker.length == 0) {
            apiService.Alerts_error("Enter all details");
            return false;
        }
        if (this.state.from_date == '' || this.state.to_date == '') {
            apiService.Alerts_error("Enter all details");
            return false;
        }
        const { newAssignment, assignments } = this.state;
        this.setState({ loader: true });
        var param = this.state.newAssignment;
        param.breakers = this.state.selectedCircuitBreaker;
        //param.thermo_desc = this.state.thermostatList.filter(s => s.id == param.thermo_id)[0]['thermo_desc'];
        //param.thermo_name = this.state.thermostatList.filter(s => s.id == param.thermo_id)[0]['thermo_name'];
        apiService.addHLightAssignmentForCircuitBreaker(param, this.state.from_date, this.state.to_date).then(response => {
            apiService.Alerts("Assign Created Successfully")
            this.setState({ loader: false })
            this.fetchCircuitBreakerAssignment(this.state.AssignBoardselected);
        }).catch(error => {
            this.setState({ loader: false })
            apiService.ErrorMessage(error)
        })
    };
    editSchedule = (schedule) => {
        var newSchedules = {};
        newSchedules.schedule_name = schedule.schedule_name;
        newSchedules.events = schedule.events;
        this.setState({
            selectedSchedule: schedule,
            isEditingSchedule: true,
        });
        this.setState({
            newSchedule: newSchedules,
        });
        var timeZone = this.state.AllCustomerList.filter(s => s.id == this.selectedcustomer)[0]['time_zone']
        const updatedEvents = newSchedules.events.map(event => {
            return {
                ...event,
                time: this.convertToZoneTime(event.time, timeZone),
            };
        });
        this.setState(prevState => ({
            newSchedule: {
                ...prevState.newSchedule,
                events: updatedEvents,
            },
        }));
        // const { newEvent, newSchedule } = this.state;
        // this.setState({ newSchedule: { ...this.state.newSchedule, ['schedule_name']: schedule.schedule_name } });
        this.setState({
            newEvent: {
                time: '',
                operation: '',
            },
        });
    };
    componentDidMount() {
        // Fetch the list of schedules from the API and update the state
        //this.fetchSchedules();
    }
    updateSchedule = () => {
        const { newSchedule, schedules } = this.state;
        this.state.newSchedule.customer_id = this.selectedcustomer;
        var param = { schedule: [] };
        param.schedule = [this.state.newSchedule];
        this.setState({ loader: true })
        var timeZone = this.state.AllCustomerList.filter(s => s.id == this.selectedcustomer)[0]['time_zone']
        const updatedEvents = this.state.newSchedule.events.map(event => {
            return {
                ...event,
                time: this.convertToIndianTime(event.time, timeZone),
            };
        });
        this.setState(prevState => ({
            newSchedule: {
                ...prevState.newSchedule,
                events: updatedEvents,
            },
        }));
        param.schedule[0].events = updatedEvents
        apiService.updateLightScheduleCreation(param, this.state.selectedSchedule.id).then(response => {
            apiService.Alerts("Schedule Updated Successfully")
            this.setState({ loader: false })
            this.setState({
                schedules: [...schedules, response.data],
                newSchedule: {
                    schedule_name: '',
                    events: [],
                },
                isEditingSchedule: false,
            });
            this.fetchSchedules();
        }).catch(error => {
            this.setState({ loader: false })
            apiService.ErrorMessage(error)
        })
    };
    scheduleDelete(value) {
        swal({
            title: "Are you sure?",
            text: "Once deleted, you will not be able to recover this Assignment!",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((willDelete) => {
                if (willDelete) {
                    // event.preventDefault();
                    this.setState({ loader: true })
                    apiService.deleteLightScheduleCreation(value).then(response => {
                        this.setState({ loader: false })
                        apiService.Alerts("Schedule Deleted Successfully")
                        this.fetchSchedules();
                    }).catch(error => {
                        this.setState({ loader: false })
                        apiService.ErrorMessage(error)
                    })
                } else {
                    // swal("Your file is safe!");
                }
            });
    }
    assignmentDelete(value) {
        swal({
            title: "Are you sure?",
            text: "Once deleted, you will not be able to recover this Schedule!",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((willDelete) => {
                if (willDelete) {
                    // event.preventDefault();
                    this.setState({ loader: true })
                    apiService.deleteLightAssignScheduleForBoard(value).then(response => {
                        this.setState({ loader: false })
                        apiService.Alerts("Assignment Deleted Successfully")
                        this.fetchAssignment(this.state.AssignBoardselected);
                    }).catch(error => {
                        this.setState({ loader: false })
                        apiService.ErrorMessage(error)
                    })
                } else {
                    // swal("Your file is safe!");
                }
            });
    }
    assignmentCircuitBreakerDelete(value) {
        swal({
            title: "Are you sure?",
            text: "Once deleted, you will not be able to recover this Schedule!",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((willDelete) => {
                if (willDelete) {
                    // event.preventDefault();
                    this.setState({ loader: true })
                    apiService.deleteLightAssignScheduleForCircuitBreaker(value).then(response => {
                        this.setState({ loader: false })
                        apiService.Alerts("Assignment Deleted Successfully")
                        this.fetchCircuitBreakerAssignment(this.state.AssignBoardselected);
                    }).catch(error => {
                        this.setState({ loader: false })
                        apiService.ErrorMessage(error)
                    })
                } else {
                    // swal("Your file is safe!");
                }
            });
    }
    TupdateSchedule = () => {
        const { selectedSchedule } = this.state;
        axios
            .put(
                `http://127.0.0.1:8000/api/hvac/schedule/new_update/${selectedSchedule.id}/`,
                { schedule: [selectedSchedule] }
            )
            .then(() => {
                this.setState({
                    selectedSchedule: null,
                    isEditingSchedule: false,
                });
            })
            .catch((error) => {
                console.error('Error updating schedule:', error);
            });
    };

    deleteEvent = (index) => {
        const { newSchedule } = this.state;
        const updatedEvents = [...newSchedule.events];
        updatedEvents.splice(index, 1);
        this.setState({
            newSchedule: {
                ...newSchedule,
                events: updatedEvents,
            },
        });
    };
    render() {
        const currentDate = new Date();
        const filteredData = this.state.formatLightBoardList.filter(item =>
            Object.values(item).some(value =>
                String(value).toLowerCase().includes(this.state.searchTerm.toLowerCase())
            )
        );
        const filteredScheduleData = this.state.formatScheduleList.filter(item =>
            Object.values(item).some(value =>
                String(value).toLowerCase().includes(this.state.searchTerm.toLowerCase())
            )
        );
        const filteredBoardAssignData = this.state.formatBoardAssignList.filter(item =>
            Object.values(item).some(value =>
                String(value).toLowerCase().includes(this.state.searchTerm.toLowerCase())
            )
        );
        const filteredBreakerAssignData = this.state.formatBreakerAssignList.filter(item =>
            Object.values(item).some(value =>
                String(value).toLowerCase().includes(this.state.searchTerm.toLowerCase())
            )
        );
        const filteredLightBoardList = this.state.allLightBoardList.filter(item =>
            Object.values(item).some(value =>
                String(value).toLowerCase().includes(this.state.searchTerm.toLowerCase())
            )
        );

        const { selectedCircuitBreaker, isAllCircuitBreakerSelected } = this.state;
        const selectedNames = selectedCircuitBreaker.map(
            (selectedId) =>
                this.state.allLightBreakerList.find((option) => option.id === selectedId).breaker
        );
        const classes = ((theme) => ({
            formControl: {
                margin: theme.spacing(1),
                width: 300
            },
            indeterminateColor: {
                color: "#0044f5"
            },
            selectAllText: {
                fontWeight: 500
            },
            selectedAll: {
                backgroundColor: "#0044f5",
                "&:hover": {
                    backgroundColor: "#0044f5"
                }
            }
        }));
        function firstcolor(data) {
            var x = '#1092C1'
            if (data == 'ON') {
                x = 'green'
            } else if (data == 'OFF') {
                x = 'red'
            }
            return x
        }
        const {
            schedules,
            isAddingSchedule,
            isEditingSchedule,
            selectedSchedule,
            newSchedule,
            newEvent,
        } = this.state;

        return (
            <>
                <div className="content">
                    {
                        this.state.loader && <Loader />
                    }
                    <TopNavDropdown customerhandler={this.customerhandler} selectedcustomer={this.selectedcustomer} boardhandler={this.boardhandler} selectedboard={this.selectedboard} showProcessDropdown={true} />
                    {this.state.showcontent == true ?
                        <>
                            <Row>
                                <Col md="12">
                                    <Card className={"card-user " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                        <CardHeader>
                                            <CardTitle tag="h5" className="dashboardcardtitle" style={{ marginTop: 0 }}>Digital Breaker</CardTitle>
                                        </CardHeader>
                                    </Card>
                                </Col>
                                <Col md="12">
                                    <Card className={"card-user " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                        <CardHeader>
                                            <CardTitle tag="h5">
                                                <Nav tabs>
                                                    <NavItem>
                                                        <NavLink
                                                            className={classnames({ active: this.state.activetab === '3' }) + ' ' + (this.props.theme) + ' ' + (this.props.themecolor)}
                                                            onClick={() => { this.toggle('3'); }}
                                                        >
                                                            Customer Info
                                                        </NavLink>
                                                    </NavItem>
                                                    <NavItem>
                                                        <NavLink
                                                            className={classnames({ active: this.state.activetab === '5' }) + ' ' + (this.props.theme) + ' ' + (this.props.themecolor)}
                                                            onClick={() => { this.toggle('5'); }}
                                                        >
                                                            Manage Board
                                                        </NavLink>
                                                    </NavItem>
                                                    <NavItem>
                                                        <NavLink
                                                            className={classnames({ active: this.state.activetab === '6' }) + ' ' + (this.props.theme) + ' ' + (this.props.themecolor)}
                                                            onClick={() => { this.toggle('6'); }}
                                                        >
                                                            Digital Breaker Circuit
                                                        </NavLink>
                                                    </NavItem>
                                                    <NavItem>
                                                        <NavLink
                                                            className={classnames({ active: this.state.activetab === '1' }) + ' ' + (this.props.theme) + ' ' + (this.props.themecolor)}
                                                            onClick={() => { this.toggle('1'); }}
                                                        >
                                                            Schedule Creation
                                                        </NavLink>
                                                    </NavItem>
                                                    <NavItem>
                                                        <NavLink
                                                            className={classnames({ active: this.state.activetab === '2' }) + ' ' + (this.props.theme) + ' ' + (this.props.themecolor)}
                                                            onClick={() => { this.toggle('2'); }}
                                                        >
                                                            Schedule Assignment for Board
                                                        </NavLink>
                                                    </NavItem>
                                                    <NavItem>
                                                        <NavLink
                                                            className={classnames({ active: this.state.activetab === '4' }) + ' ' + (this.props.theme) + ' ' + (this.props.themecolor)}
                                                            onClick={() => { this.toggle('4'); }}
                                                        >
                                                            Schedule Assignment for Circuit Breaker
                                                        </NavLink>
                                                    </NavItem>

                                                </Nav>
                                            </CardTitle>
                                        </CardHeader>
                                        <CardBody>
                                            <TabContent activeTab={this.state.activetab}>
                                                <TabPane tabId="1">

                                                    {/* {!isAddingSchedule && !isEditingSchedule && (
                                                        <button onClick={() => this.setState({ isAddingSchedule: true })}>Add New Schedule</button>
                                                    )} */}
                                                    <div>
                                                        <Card className={"card-user " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                                            <CardHeader>
                                                                <CardTitle tag="h5" className="flexblock">
                                                                    {this.state.thermostatName} {isAddingSchedule ? 'Create Schedule' : isEditingSchedule ? 'Edit Schedule' : 'Schedule List'}
                                                                    {!isAddingSchedule && !isEditingSchedule && (
                                                                        <span><Button
                                                                            onClick={() => this.setState({ isAddingSchedule: true })}
                                                                            variant="contained"
                                                                            color="primary"
                                                                            className="flexblockbutton"
                                                                        >
                                                                            New Schedule
                                                                        </Button></span>
                                                                    )}

                                                                </CardTitle>

                                                            </CardHeader>
                                                            <CardBody style={{ textAlign: "center" }}>

                                                                {/* List */}
                                                                {!isAddingSchedule && !isEditingSchedule && (
                                                                    <>
                                                                        <Row>
                                                                            <Col className="pr-1" md="9">
                                                                            </Col>
                                                                            <Col className="pr-1" md="3">

                                                                                <div className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                                                                    <Grid className="Formfieldcss" container spacing={1}>
                                                                                        <Grid item>
                                                                                            <TextField type="text" fullWidth variant="outlined" label="Search" value={this.state.searchTerm} onChange={this.handleSearchInputChange} />
                                                                                        </Grid>

                                                                                    </Grid>
                                                                                </div>
                                                                                {/* <input className='MuiGrid-root MuiGrid-item'
                                                                                            type="text"
                                                                                            placeholder="Search..."
                                                                                            //value={searchTerm}
                                                                                            onChange={this.handleSearchInputChange}
                                                                                        /> */}
                                                                            </Col>
                                                                        </Row>
                                                                        <div className={"table-responsive " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                                                            <table id="logstable" className={"nbztable dataTable no-footer " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                                                                <thead>
                                                                                    <tr>
                                                                                        <th style={{ width: '100px' }}>Actions</th>
                                                                                        <th style={{ width: '100px' }}>Schedule Name</th>
                                                                                        <th style={{ width: '200px' }}>Created / Updated at</th>
                                                                                    </tr>
                                                                                </thead>
                                                                                <tbody>
                                                                                    {filteredScheduleData.map((value, index) => (
                                                                                        <tr key={index + 1}>
                                                                                            {/* <td>{index+1}</td> */}
                                                                                            <td>
                                                                                                <a href="javascript:void(0)" onClick={(e) => this.editSchedule(value)}><i className="fa fa-edit icons"></i></a>
                                                                                                <a href="javascript:void(0)" onClick={(e) => this.scheduleDelete(value.id)}><i className="fa fa-trash icons"></i></a>

                                                                                            </td>
                                                                                            <td>{value.schedule_name}</td>
                                                                                            <td>{Moment(value.updated_at).format('MMM DD YYYY HH:mm:ss')}</td>
                                                                                        </tr>

                                                                                    ))}
                                                                                </tbody>
                                                                            </table>
                                                                        </div>
                                                                    </>
                                                                )}

                                                                {/* Edit/Add */}
                                                                {(isAddingSchedule || isEditingSchedule) && (
                                                                    <div>
                                                                        <Row>
                                                                            <Col className="pr-1" md="3">
                                                                                <div className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                                                                    <Grid className={"Formfieldcss"} container spacing={1}>
                                                                                        <Grid item>
                                                                                            <TextField required fullWidth variant="outlined" type="text"
                                                                                                name="schedule_name"
                                                                                                label="Schedule Name"
                                                                                                value={newSchedule.schedule_name}
                                                                                                onChange={this.handleInputChange} />
                                                                                        </Grid>
                                                                                    </Grid>
                                                                                </div>
                                                                            </Col>
                                                                        </Row>
                                                                        {/* Event input fields */}
                                                                        <div>
                                                                            <CardHeader>
                                                                                <CardTitle tag="h5" className="flexblock">
                                                                                    Add Event
                                                                                    <p>
                                                                                        <Button
                                                                                            type="submit"
                                                                                            variant="contained"
                                                                                            color="primary"
                                                                                            // disabled={invalid}
                                                                                            onClick={this.addEvent}
                                                                                        >
                                                                                            Add
                                                                                        </Button>
                                                                                        <Button
                                                                                            onClick={() => this.cancel()}
                                                                                            variant="contained"
                                                                                            color="danger"
                                                                                        >
                                                                                            Reset
                                                                                        </Button>
                                                                                    </p>
                                                                                </CardTitle>

                                                                            </CardHeader>
                                                                            <Row>
                                                                                <Col className="pr-1" md="3">
                                                                                    <div className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                                                                        <Grid className={"Formfieldcss"} container spacing={1}>
                                                                                            <Grid item>
                                                                                                <TextField type="time" required fullWidth variant="outlined" name="time" onChange={this.handleEventInputChange} label="Start Time " value={this.state.newEvent.time} />
                                                                                            </Grid>
                                                                                        </Grid>
                                                                                    </div>
                                                                                </Col>
                                                                                <Col className="pr-1" md="3">
                                                                                    <div className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                                                                        <Grid className="Formfieldcss" container spacing={1}>
                                                                                            <Grid item>
                                                                                                <TextField fullWidth
                                                                                                    select
                                                                                                    label="Select Operation"
                                                                                                    variant="outlined"
                                                                                                    name="operation"
                                                                                                    value={this.state.newEvent.operation}
                                                                                                    onChange={this.handleEventInputChange}
                                                                                                >
                                                                                                    <MenuItem className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)} value='' >
                                                                                                        Select Operation
                                                                                                    </MenuItem>
                                                                                                    {this.state.dropDownList.operation.map(option => (
                                                                                                        <MenuItem className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)} key={option.id} value={option.id} >
                                                                                                            {option.value}
                                                                                                        </MenuItem>
                                                                                                    ))}
                                                                                                </TextField>
                                                                                            </Grid>
                                                                                        </Grid>

                                                                                    </div>

                                                                                </Col>
                                                                            </Row>
                                                                        </div>

                                                                        <div className={"table-responsive " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                                                            <CardHeader>
                                                                                <CardTitle tag="h5" className="flexblock">
                                                                                    Event List
                                                                                    {(isAddingSchedule || isEditingSchedule) && (<p>
                                                                                        {isAddingSchedule && (
                                                                                            <span><Button
                                                                                                onClick={this.addSchedule}
                                                                                                variant="contained"
                                                                                                color="primary"
                                                                                                className="flexblockbutton"
                                                                                            >
                                                                                                Save
                                                                                            </Button></span>
                                                                                        )}

                                                                                        {isEditingSchedule && (
                                                                                            <span><Button
                                                                                                onClick={this.updateSchedule}
                                                                                                variant="contained"
                                                                                                color="primary"
                                                                                                className="flexblockbutton"
                                                                                            >
                                                                                                Update
                                                                                            </Button></span>
                                                                                        )}
                                                                                        {(isAddingSchedule || isEditingSchedule) && (
                                                                                            <span><Button
                                                                                                onClick={() => this.scheduleCancel()}
                                                                                                variant="contained"
                                                                                                color="danger"
                                                                                                className="flexblockbutton"
                                                                                            >
                                                                                                Cancel
                                                                                            </Button></span>
                                                                                        )}
                                                                                    </p>)}
                                                                                </CardTitle>

                                                                            </CardHeader>
                                                                            <table id="Eventtable" className={"nbztable dataTable no-footer " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                                                                <thead>
                                                                                    <tr>
                                                                                        <th style={{ width: '100px' }}>Actions</th>
                                                                                        <th style={{ width: '100px' }}>Time</th>
                                                                                        <th style={{ width: '100px' }}>Operation</th>
                                                                                    </tr>
                                                                                </thead>
                                                                                <tbody>
                                                                                    {newSchedule.events.map((event, index) => (
                                                                                        <tr key={index}>
                                                                                            <td>
                                                                                                <a href="javascript:void(0)" onClick={() => this.deleteEvent(index)}><i className="fa fa-trash icons"></i></a>
                                                                                            </td>
                                                                                            <td>{event.time}</td>
                                                                                            <td>{this.BindModeValue(event.operation)}</td>
                                                                                            {/* <td>{this.state.dropDownList.equipment.filter(s=>s.id==event.equipment)[0]['value']}</td>
                                                                                            <td>{this.state.dropDownList.operation.filter(s=>s.id==event.operation)[0]['value']}</td>
                                                                                            <td>{this.state.dropDownList.operation.filter(s=>s.id==event.operation)[0]['value']}</td>
                                                                                            <td>{this.state.dropDownList.speed.filter(s=>s.id==event.fan_speed)[0]['value']}</td>
                                                                                            <td>{this.state.dropDownList.swing_status.filter(s=>s.id==event.swing_status)[0]['value']}</td>
                                                                                            <td>{this.state.dropDownList.temperature.filter(s=>s.id==event.temperature)[0]['value']}</td> */}
                                                                                        </tr>
                                                                                    ))}
                                                                                </tbody>
                                                                            </table>
                                                                        </div>


                                                                    </div>
                                                                )}
                                                            </CardBody>

                                                        </Card>
                                                        {/* <h3>Schedules</h3>
                                                        <ul>
                                                            {schedules.map((schedule) => (
                                                                <li key={schedule.id}>
                                                                    {schedule.schedule_name}
                                                                    <button onClick={() => this.editSchedule(schedule)}>Edit</button>
                                                                </li>
                                                            ))}
                                                        </ul> */}
                                                    </div>
                                                </TabPane>
                                                <TabPane tabId="2">
                                                    <div>

                                                        {/* Event input fields */}
                                                        <div>
                                                            <CardHeader>
                                                                <CardTitle tag="h5" className="flexblock">
                                                                    Assign Schedule
                                                                </CardTitle>

                                                            </CardHeader>
                                                            <Row>
                                                                <Col className="pr-1" md="3">
                                                                    <div className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                                                        <Grid className="Formfieldcss" container spacing={1}>
                                                                            <Grid item>
                                                                                <TextField fullWidth
                                                                                    select
                                                                                    label="Select Board"
                                                                                    variant="outlined"
                                                                                    name="lboard_id"
                                                                                    value={this.state.newAssignment.lboard_id}
                                                                                    onChange={this.handleAssignEventInputChange}
                                                                                >
                                                                                    <MenuItem className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)} value='' onClick={(e) => { this.fetchAssignment('0') }}>
                                                                                        Select Board
                                                                                    </MenuItem>
                                                                                    {this.state.allLightBoardList.map(option => (
                                                                                        <MenuItem className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)} key={option.id} value={option.id} onClick={(e) => { this.fetchAssignment(option.id) }}>
                                                                                            {option.lboard}
                                                                                        </MenuItem>
                                                                                    ))}
                                                                                </TextField>
                                                                            </Grid>
                                                                        </Grid>

                                                                    </div>
                                                                </Col>
                                                                <Col className="pr-1" md="3">
                                                                    <div className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                                                        <Grid className="Formfieldcss" container spacing={1}>
                                                                            <Grid item>
                                                                                <div className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                                                                    <Grid className="Formfieldcss" container spacing={1}>
                                                                                        <Grid item>
                                                                                            {/* <InputLabel id="demo-simple-select-outlined-label1">Date Range</InputLabel>   */}
                                                                                            <div className='control-pane'>
                                                                                                <div className='control-section'>
                                                                                                    <div className='datepicker-control-section'>
                                                                                                        <DateRangePickerComponent placeholder='Select a range' min={currentDate} onChange={(e) => this.datehandleChange1(e.target.value)} value={this.state.dateselected}>
                                                                                                        </DateRangePickerComponent>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </Grid>
                                                                                    </Grid>
                                                                                </div>
                                                                            </Grid>
                                                                        </Grid>

                                                                    </div>
                                                                </Col>
                                                                <Col className="pr-1" md="3">
                                                                    <div className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                                                        <Grid className="Formfieldcss" container spacing={1}>
                                                                            <Grid item>
                                                                                <TextField fullWidth
                                                                                    select
                                                                                    label="Select Schedule"
                                                                                    variant="outlined"
                                                                                    name="light_schedule_id"
                                                                                    value={this.state.newAssignment.light_schedule_id}
                                                                                    onChange={this.handleAssignEventInputChange}
                                                                                >
                                                                                    <MenuItem className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)} value='' >
                                                                                        Select Schedule
                                                                                    </MenuItem>
                                                                                    {this.allScheduleList.map(option => (
                                                                                        <MenuItem className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)} key={option.id} value={option.id} >
                                                                                            {option.schedule_name}
                                                                                        </MenuItem>
                                                                                    ))}
                                                                                </TextField>
                                                                            </Grid>
                                                                        </Grid>

                                                                    </div>
                                                                </Col>

                                                                <Col className="pr-1" md="1.5">
                                                                    <div className="login-button changepassword text-center">
                                                                        <Button
                                                                            type="submit"
                                                                            variant="contained"
                                                                            color="primary"
                                                                            // disabled={invalid}
                                                                            onClick={this.addAssignment}
                                                                        >
                                                                            Assign
                                                                        </Button>
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                        </div>
                                                        <Row>
                                                            <Col className="pr-1" md="9">
                                                            </Col>
                                                            <Col className="pr-1" md="3">

                                                                <div className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                                                    <Grid className="Formfieldcss" container spacing={1}>
                                                                        <Grid item>
                                                                            <TextField type="text" fullWidth variant="outlined" label="Search" value={this.state.searchTerm} onChange={this.handleSearchInputChange} />
                                                                        </Grid>

                                                                    </Grid>
                                                                </div>
                                                                {/* <input className='MuiGrid-root MuiGrid-item'
                                                                                            type="text"
                                                                                            placeholder="Search..."
                                                                                            //value={searchTerm}
                                                                                            onChange={this.handleSearchInputChange}
                                                                                        /> */}
                                                            </Col>
                                                        </Row>
                                                        <div className={"table-responsive " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                                            <CardHeader>
                                                                <CardTitle tag="h5" className="flexblock">
                                                                    Schedule List
                                                                </CardTitle>

                                                            </CardHeader>
                                                            <table id="Eventtable" className={"nbztable dataTable no-footer " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                                                <thead>
                                                                    <tr>
                                                                        <th style={{ width: '100px' }}>Actions</th>
                                                                        <th style={{ width: '200px' }}>Board</th>
                                                                        <th style={{ width: '200px' }}>Schedule Name</th>
                                                                        <th style={{ width: '200px' }}>Start Date - End Date</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {filteredBoardAssignData.map((event, index) => (
                                                                        <tr key={index}>
                                                                            <td>
                                                                                <a href="javascript:void(0)" onClick={() => this.assignmentDelete(event.id)}><i className="danger fa fa-trash icons"></i></a>
                                                                            </td>
                                                                            {/* <td>{this.BindBoardName(event.lboard_id)}</td> */}
                                                                            <td>{event.lboard_id}</td>
                                                                            {/* <td>{this.BindScheduleName(event.light_schedule_id)}</td> */}
                                                                            <td>{event.light_schedule_id}</td>
                                                                            {/* <td>{Moment(event.from_date).format('MMM DD YYYY')} to {Moment(event.to_date).format('MMM DD YYYY')}</td> */}
                                                                            <td>{event.updated_at}</td>
                                                                        </tr>
                                                                    ))}
                                                                </tbody>
                                                            </table>
                                                        </div>


                                                    </div>
                                                </TabPane>
                                                <TabPane tabId="3">
                                                    <div>
                                                        {this.state.showcontent == true ?
                                                            <>
                                                                <Row>
                                                                    <Col md="12">
                                                                        <Card className={"card-user " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                                                            <CardHeader>
                                                                                <CardTitle tag="h5"></CardTitle>
                                                                            </CardHeader>
                                                                            <CardBody>
                                                                                <FieldGroup
                                                                                    control={this.customerInfoForm}
                                                                                    strict={false}
                                                                                    render={({ get, invalid, value }) => (
                                                                                        <form>
                                                                                            <Row>
                                                                                                <Col className="pr-1" md="3">
                                                                                                    <FieldControl
                                                                                                        name="project_name"
                                                                                                        strict={false}
                                                                                                        render={({ handler, touched, hasError }) => (
                                                                                                            <div className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                                                                                                <Grid className={"Formfieldcss"} container spacing={1}>
                                                                                                                    <Grid item>
                                                                                                                        <TextField type="text" required fullWidth variant="outlined" error={touched && hasError("required")} label="Project Name" {...handler("text")} />
                                                                                                                    </Grid>
                                                                                                                    <span className="error">
                                                                                                                        {touched
                                                                                                                            && hasError("required")
                                                                                                                            && `* project name is required`}
                                                                                                                    </span>
                                                                                                                </Grid>
                                                                                                            </div>
                                                                                                        )}
                                                                                                    />
                                                                                                </Col>
                                                                                                <Col className="pr-1" md="3">
                                                                                                    <FieldControl
                                                                                                        name="main_breaker"
                                                                                                        strict={false}
                                                                                                        render={({ handler, touched, hasError }) => (
                                                                                                            <div className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                                                                                                <Grid className="Formfieldcss" container spacing={1}>
                                                                                                                    <Grid item>
                                                                                                                        <TextField type="text" required fullWidth variant="outlined" error={touched && hasError("required")} label="No of Main breaker" {...handler("text")} />
                                                                                                                    </Grid>
                                                                                                                    <span className="error">
                                                                                                                        {touched
                                                                                                                            && hasError("required")
                                                                                                                            && `* No of Main breaker is required`}
                                                                                                                    </span>
                                                                                                                </Grid>
                                                                                                            </div>
                                                                                                        )}
                                                                                                    />
                                                                                                </Col>
                                                                                                <Col className="pr-1" md="3">
                                                                                                    <FieldControl
                                                                                                        name="sub_main_breaker"
                                                                                                        strict={false}
                                                                                                        render={({ handler, touched, hasError }) => (
                                                                                                            <div className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                                                                                                <Grid className="Formfieldcss" container spacing={1}>
                                                                                                                    <Grid item>
                                                                                                                        <TextField type="text" required fullWidth variant="outlined" error={touched && hasError("required")} label="No of Sub-Main breaker" {...handler("text")} />
                                                                                                                    </Grid>
                                                                                                                    <span className="error">
                                                                                                                        {touched
                                                                                                                            && hasError("required")
                                                                                                                            && `* No of Sub-Main breaker is required`}
                                                                                                                    </span>
                                                                                                                </Grid>
                                                                                                            </div>
                                                                                                        )}
                                                                                                    />
                                                                                                </Col>
                                                                                                <Col className="pr-1" md="3">
                                                                                                    <FieldControl
                                                                                                        name="positions"
                                                                                                        strict={false}
                                                                                                        render={({ handler, touched, hasError }) => (
                                                                                                            <div className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                                                                                                <Grid className="Formfieldcss" container spacing={1}>
                                                                                                                    <Grid item>
                                                                                                                        <TextField type="text" required fullWidth variant="outlined" error={touched && hasError("required")} label="No of Positions" {...handler("text")} />
                                                                                                                    </Grid>
                                                                                                                    <span className="error">
                                                                                                                        {touched
                                                                                                                            && hasError("required")
                                                                                                                            && `* No of Positions is required`}
                                                                                                                    </span>
                                                                                                                </Grid>
                                                                                                            </div>
                                                                                                        )}
                                                                                                    />
                                                                                                </Col>
                                                                                                <Col className="pr-1" md="3">
                                                                                                    <FieldControl
                                                                                                        name="load_types"
                                                                                                        strict={false}
                                                                                                        render={({ handler, touched, hasError }) => (
                                                                                                            <div className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                                                                                                <Grid className="Formfieldcss" container spacing={1}>
                                                                                                                    <Grid item>
                                                                                                                        <TextField type="area" multiline required fullWidth variant="outlined" error={touched && hasError("required")} label="Type of Loads" {...handler("text")} />
                                                                                                                    </Grid>
                                                                                                                    <span className="error">
                                                                                                                        {touched
                                                                                                                            && hasError("required")
                                                                                                                            && `* Type of Loads is required`}
                                                                                                                    </span>
                                                                                                                </Grid>
                                                                                                            </div>
                                                                                                        )}
                                                                                                    />
                                                                                                </Col>
                                                                                                <Col className="pr-1" md="3">
                                                                                                    <FieldControl
                                                                                                        name="indoor_lights"
                                                                                                        strict={false}
                                                                                                        render={({ handler, touched, hasError }) => (
                                                                                                            <div className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                                                                                                <Grid className="Formfieldcss" container spacing={1}>
                                                                                                                    <Grid item>
                                                                                                                        <TextField type="text" required fullWidth variant="outlined" error={touched && hasError("required")} label="No of Indoor Lights" {...handler("text")} />
                                                                                                                    </Grid>
                                                                                                                    <span className="error">
                                                                                                                        {touched
                                                                                                                            && hasError("required")
                                                                                                                            && `* No of Indoor Lights is required`}
                                                                                                                    </span>
                                                                                                                </Grid>
                                                                                                            </div>
                                                                                                        )}
                                                                                                    />
                                                                                                </Col>
                                                                                                <Col className="pr-1" md="3">
                                                                                                    <FieldControl
                                                                                                        name="outdoor_lights"
                                                                                                        strict={false}
                                                                                                        render={({ handler, touched, hasError }) => (
                                                                                                            <div className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                                                                                                <Grid className="Formfieldcss" container spacing={1}>
                                                                                                                    <Grid item>
                                                                                                                        <TextField type="text" required fullWidth variant="outlined" error={touched && hasError("required")} label="No of Outdoor Lights" {...handler("text")} />
                                                                                                                    </Grid>
                                                                                                                    <span className="error">
                                                                                                                        {touched
                                                                                                                            && hasError("required")
                                                                                                                            && `* No of Outdoor Lights is required`}
                                                                                                                    </span>
                                                                                                                </Grid>
                                                                                                            </div>
                                                                                                        )}
                                                                                                    />
                                                                                                </Col>
                                                                                                <Col className="pr-1" md="3">
                                                                                                    <FieldControl
                                                                                                        name="sign_boards"
                                                                                                        strict={false}
                                                                                                        render={({ handler, touched, hasError }) => (
                                                                                                            <div className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                                                                                                <Grid className="Formfieldcss" container spacing={1}>
                                                                                                                    <Grid item>
                                                                                                                        <TextField type="text" required fullWidth variant="outlined" error={touched && hasError("required")} label="No of Sign boards" {...handler("text")} />
                                                                                                                    </Grid>
                                                                                                                    <span className="error">
                                                                                                                        {touched
                                                                                                                            && hasError("required")
                                                                                                                            && `* No of Sign boards is required`}
                                                                                                                    </span>
                                                                                                                </Grid>
                                                                                                            </div>
                                                                                                        )}
                                                                                                    />
                                                                                                </Col>
                                                                                                <Col className="pr-1" md="3">
                                                                                                    <FieldControl
                                                                                                        name="other_loads"
                                                                                                        strict={false}
                                                                                                        render={({ handler, touched, hasError }) => (
                                                                                                            <div className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                                                                                                <Grid className="Formfieldcss" container spacing={1}>
                                                                                                                    <Grid item>
                                                                                                                        <TextField type="text" required fullWidth variant="outlined" error={touched && hasError("required")} label="Other loads" {...handler("text")} />
                                                                                                                    </Grid>
                                                                                                                    <span className="error">
                                                                                                                        {touched
                                                                                                                            && hasError("required")
                                                                                                                            && `* Other loads is required`}
                                                                                                                    </span>
                                                                                                                </Grid>
                                                                                                            </div>
                                                                                                        )}
                                                                                                    />
                                                                                                </Col>
                                                                                                <Col className="pr-1" md="3">
                                                                                                    <div className="login-button changepassword text-center">
                                                                                                        <Button
                                                                                                            type="submit"
                                                                                                            variant="contained"
                                                                                                            color="primary"
                                                                                                            disabled={invalid}
                                                                                                            onClick={(event) => this.handleCustomerInfoSubmit(event, this.customerInfoForm.getRawValue())}
                                                                                                        >
                                                                                                            Save
                                                                                                        </Button>
                                                                                                    </div>
                                                                                                </Col>
                                                                                                <Col className="pr-1" md="3">
                                                                                                    <div className="login-button changepassword text-center">
                                                                                                        <Button
                                                                                                            onClick={() => this.CustomerInfocancel()}
                                                                                                            variant="contained"
                                                                                                            color="danger"
                                                                                                        >
                                                                                                            Cancel
                                                                                                        </Button>
                                                                                                    </div>
                                                                                                </Col>
                                                                                            </Row>
                                                                                            <Row>
                                                                                                <Col className="pr-1" md="3">
                                                                                                </Col>

                                                                                                <Col className="pr-1" md="3">
                                                                                                </Col>
                                                                                            </Row>
                                                                                        </form>
                                                                                    )}
                                                                                />
                                                                            </CardBody>

                                                                        </Card>
                                                                    </Col>
                                                                </Row>
                                                            </>
                                                            :
                                                            <Row>
                                                                <Col md="12">
                                                                    <Card className={"card-user " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                                                        <CardHeader>
                                                                        </CardHeader>
                                                                        <CardBody>
                                                                            <div style={{ width: "100%", height: "100px", fontSize: '22px', textAlign: 'center' }}>Please Choose atleast one Board to view the data ...</div>
                                                                        </CardBody>
                                                                    </Card>
                                                                </Col>
                                                            </Row>
                                                        }
                                                    </div>
                                                </TabPane>
                                                <TabPane tabId="4">
                                                    <div>

                                                        {/* Event input fields */}
                                                        <div>
                                                            <CardHeader>
                                                                <CardTitle tag="h5" className="flexblock">
                                                                    Assign Schedule
                                                                </CardTitle>

                                                            </CardHeader>
                                                            <Row>
                                                                <Col className="pr-1" md="3">
                                                                    <div className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                                                        <Grid className="Formfieldcss" container spacing={1}>
                                                                            <Grid item>
                                                                                <TextField fullWidth
                                                                                    select
                                                                                    label="Select Board"
                                                                                    variant="outlined"
                                                                                    name="lboard_id"
                                                                                    value={this.state.newAssignment.lboard_id}
                                                                                    onChange={this.handleAssignEventInputChange}
                                                                                >
                                                                                    <MenuItem className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)} value='' onClick={(e) => { this.getLightBreaker('0'); this.fetchCircuitBreakerAssignment('0') }}>
                                                                                        Select Board
                                                                                    </MenuItem>
                                                                                    {this.state.allLightBoardList.map(option => (
                                                                                        <MenuItem className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)} key={option.id} value={option.id} onClick={(e) => { this.getLightBreaker(option.id); this.fetchCircuitBreakerAssignment(option.id) }}>
                                                                                            {option.lboard}
                                                                                        </MenuItem>
                                                                                    ))}
                                                                                </TextField>
                                                                            </Grid>
                                                                        </Grid>

                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col className="pr-1" md="3">
                                                                    <div className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                                                        <Grid className="Formfieldcss" container spacing={1}>
                                                                            <Grid item>
                                                                                <div className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                                                                    <Grid className="Formfieldcss" container spacing={1}>
                                                                                        <Grid item>
                                                                                            {/* <InputLabel id="demo-simple-select-outlined-label1">Date Range</InputLabel>   */}
                                                                                            <div className='control-pane'>
                                                                                                <div className='control-section'>
                                                                                                    <div className='datepicker-control-section'>
                                                                                                        <DateRangePickerComponent placeholder='Select a range' min={currentDate} onChange={(e) => this.datehandleChange1(e.target.value)} value={this.state.dateselected}>
                                                                                                        </DateRangePickerComponent>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </Grid>
                                                                                    </Grid>
                                                                                </div>
                                                                            </Grid>
                                                                        </Grid>

                                                                    </div>
                                                                </Col>
                                                                <Col className="pr-1" md="3">
                                                                    <div className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                                                        <Grid className="Formfieldcss" container spacing={1}>
                                                                            <Grid item>
                                                                                <TextField fullWidth
                                                                                    select
                                                                                    label="Select Schedule"
                                                                                    variant="outlined"
                                                                                    name="light_schedule_id"
                                                                                    value={this.state.newAssignment.light_schedule_id}
                                                                                    onChange={this.handleAssignEventInputChange}
                                                                                >
                                                                                    <MenuItem className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)} value='' >
                                                                                        Select Schedule
                                                                                    </MenuItem>
                                                                                    {this.allScheduleList.map(option => (
                                                                                        <MenuItem className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)} key={option.id} value={option.id} >
                                                                                            {option.schedule_name}
                                                                                        </MenuItem>
                                                                                    ))}
                                                                                </TextField>
                                                                            </Grid>
                                                                        </Grid>

                                                                    </div>
                                                                </Col>
                                                                <Col className="pr-1" md="3">
                                                                    <FormControl className={classes.formControl} style={{ width: "100%", marginBottom: 32 }}>
                                                                        <InputLabel id="mutiple-select-label">Circuit Breaker</InputLabel>
                                                                        <Select
                                                                            labelId="mutiple-select-label"
                                                                            id="demo-mutiple-name-label3"
                                                                            fullWidth variant="outlined"
                                                                            multiple required
                                                                            label="Meter"
                                                                            onChange={this.BreakerhandleChange}
                                                                            value={this.state.selectedCircuitBreaker}
                                                                            renderValue={() => selectedNames.join(', ')}
                                                                        >
                                                                            <MenuItem
                                                                                value="all"
                                                                                classes={{
                                                                                    root: this.state.isAllCircuitBreakerSelected ? classes.selectedAll : ""
                                                                                }}
                                                                            >
                                                                                <ListItemIcon>
                                                                                    <Checkbox
                                                                                        classes={{ indeterminate: classes.indeterminateColor }}
                                                                                        checked={this.state.isAllCircuitBreakerSelected}
                                                                                        indeterminate={
                                                                                            this.state.selectedCircuitBreaker.length > 0 && this.state.selectedCircuitBreaker.length < this.state.allLightBreakerList.length
                                                                                        }
                                                                                    />
                                                                                </ListItemIcon>
                                                                                <ListItemText
                                                                                    classes={{ primary: classes.selectAllText }}
                                                                                    primary="Select All"
                                                                                />
                                                                            </MenuItem>
                                                                            {this.state.allLightBreakerList.map((option) => (
                                                                                <MenuItem className={"formfields processoptions " + (this.props.theme) + ' ' + (this.props.themecolor)} key={option.id} value={option.id}>
                                                                                    <ListItemIcon>
                                                                                        <Checkbox checked={this.state.selectedCircuitBreaker.includes(option.id)} />
                                                                                    </ListItemIcon>
                                                                                    <ListItemText primary={option.breaker} />
                                                                                </MenuItem>
                                                                            ))}
                                                                        </Select>
                                                                    </FormControl>
                                                                </Col>
                                                                <Col className="pr-1" md="1.5">
                                                                    <div className="login-button changepassword text-center">
                                                                        <Button
                                                                            type="submit"
                                                                            variant="contained"
                                                                            color="primary"
                                                                            // disabled={invalid}
                                                                            onClick={this.addCircuitBreakerAssignment}
                                                                        >
                                                                            Assign
                                                                        </Button>
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                        </div>
                                                        <Row>
                                                            <Col className="pr-1" md="9">
                                                            </Col>
                                                            <Col className="pr-1" md="3">

                                                                <div className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                                                    <Grid className="Formfieldcss" container spacing={1}>
                                                                        <Grid item>
                                                                            <TextField type="text" fullWidth variant="outlined" label="Search" value={this.state.searchTerm} onChange={this.handleSearchInputChange} />
                                                                        </Grid>

                                                                    </Grid>
                                                                </div>
                                                                {/* <input className='MuiGrid-root MuiGrid-item'
                                                                                            type="text"
                                                                                            placeholder="Search..."
                                                                                            //value={searchTerm}
                                                                                            onChange={this.handleSearchInputChange}
                                                                                        /> */}
                                                            </Col>
                                                        </Row>
                                                        <div className={"table-responsive " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                                            <CardHeader>
                                                                <CardTitle tag="h5" className="flexblock">
                                                                    Schedule List
                                                                </CardTitle>

                                                            </CardHeader>
                                                            <table id="Eventtable" className={"nbztable dataTable no-footer " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                                                <thead>
                                                                    <tr>
                                                                        <th style={{ width: '100px' }}>Actions</th>
                                                                        <th style={{ width: '200px' }}>Board</th>
                                                                        <th style={{ width: '200px' }}>Circuit Breaker</th>
                                                                        <th style={{ width: '200px' }}>Schedule Name</th>
                                                                        <th style={{ width: '200px' }}>Start Date - End Date</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {filteredBreakerAssignData.map((event, index) => (
                                                                        <tr key={index}>
                                                                            <td>
                                                                                <a href="javascript:void(0)" onClick={() => this.assignmentCircuitBreakerDelete(event.id)}><i className="danger fa fa-trash icons"></i></a>
                                                                            </td>
                                                                            {/* <td>{this.BindBoardName(event.lboard_id)}</td> */}
                                                                            <td>{event.lboard_id}</td>
                                                                            {this.state.activetab == "4" && event.breakers != null && event.breakers != undefined ? <td>
                                                                                {/* {event.breakers.map((item, index1) => (
                                                                                    <p>{this.BindDigitalCircuitName(item)}</p>
                                                                                ))} */}
                                                                                {event.breaker_name}
                                                                            </td> : <td></td>}
                                                                            {/* <td>{this.BindScheduleName(event.light_schedule_id)}</td> */}
                                                                            <td>{event.light_schedule_id}</td>
                                                                            {/* <td>{Moment(event.from_date).format('MMM DD YYYY')} to {Moment(event.to_date).format('MMM DD YYYY')}</td> */}
                                                                            <td>{event.updated_at}</td>
                                                                        </tr>
                                                                    ))}
                                                                </tbody>
                                                            </table>
                                                        </div>


                                                    </div>
                                                </TabPane>
                                                <TabPane tabId="6">
                                                    <div>
                                                        {this.state.showcontent == true ?
                                                            <>
                                                                <Row>
                                                                    <Col md="12">
                                                                        <Card className={"card-user " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                                                            <CardHeader>
                                                                                <CardTitle tag="h5"></CardTitle>
                                                                            </CardHeader>
                                                                            <CardBody>
                                                                                <FieldGroup
                                                                                    control={this.breakerCiruitForm}
                                                                                    strict={false}
                                                                                    render={({ get, invalid, value }) => (
                                                                                        <form>
                                                                                            <Row>
                                                                                                <Col className="pr-1" md="3">
                                                                                                    <FieldControl
                                                                                                        name="lboard_id"
                                                                                                        strict={false}

                                                                                                        render={({ handler, touched, hasError }) => (
                                                                                                            <div className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                                                                                                <Grid className="Formfieldcss" container spacing={1}>
                                                                                                                    <Grid item>
                                                                                                                        <FormControl variant="outlined"
                                                                                                                            style={{ width: "100%", marginBottom: 32 }}>
                                                                                                                            <InputLabel id="demo-mutiple-name-label300">Board</InputLabel>
                                                                                                                            <Select
                                                                                                                                labelId="demo-mutiple-name-label300"
                                                                                                                                id="demo-mutiple-name-label300"
                                                                                                                                fullWidth variant="outlined"
                                                                                                                                required
                                                                                                                                label="Board"
                                                                                                                                //value={this.state.selectedshift}
                                                                                                                                {...handler("text")}
                                                                                                                            >
                                                                                                                                <MenuItem className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)} value='' onClick={(e) => { this.getLightBreaker('0') }}>
                                                                                                                                    Select Board
                                                                                                                                </MenuItem>
                                                                                                                                {this.state.allLightBoardList.map(option => (
                                                                                                                                    <MenuItem className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)} key={option.id} value={option.id} onClick={(e) => { this.getLightBreaker(option.id) }}>
                                                                                                                                        {option.breaker_name} - {option.lboard}
                                                                                                                                    </MenuItem>
                                                                                                                                ))}
                                                                                                                            </Select>
                                                                                                                        </FormControl>
                                                                                                                    </Grid>
                                                                                                                    <span className="error">
                                                                                                                        {touched
                                                                                                                            && hasError("required")
                                                                                                                            && `* Board is required`}
                                                                                                                    </span>
                                                                                                                </Grid>
                                                                                                            </div>
                                                                                                        )}
                                                                                                    />
                                                                                                </Col>
                                                                                                <Col className="pr-1" md="3">
                                                                                                    <FieldControl
                                                                                                        name="circuit_type"
                                                                                                        strict={false}
                                                                                                        render={({ handler, touched, hasError }) => (
                                                                                                            <div className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                                                                                                <Grid className="Formfieldcss" container spacing={1}>
                                                                                                                    <Grid item>
                                                                                                                        <FormControl variant="outlined"
                                                                                                                            style={{ width: "100%", marginBottom: 32 }}>
                                                                                                                            <InputLabel id="demo-mutiple-name-label300">Circuit Type</InputLabel>
                                                                                                                            <Select
                                                                                                                                labelId="demo-mutiple-name-label300"
                                                                                                                                id="demo-mutiple-name-label300"
                                                                                                                                fullWidth variant="outlined"
                                                                                                                                required
                                                                                                                                label="Circuit Type"
                                                                                                                                //value={this.state.selectedshift}
                                                                                                                                {...handler("text")}
                                                                                                                            >
                                                                                                                                <MenuItem className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)} value='' >
                                                                                                                                    Select Circuit Type
                                                                                                                                </MenuItem>
                                                                                                                                <MenuItem className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)} key="Individual Lights" value="Individual Lights" >
                                                                                                                                    Individual Lights
                                                                                                                                </MenuItem>
                                                                                                                                <MenuItem className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)} key="Lights breaker circuit (< 3A)" value="Lights breaker circuit (< 3A)" >
                                                                                                                                    Lights breaker circuit (&lt; 3A)
                                                                                                                                </MenuItem>
                                                                                                                                <MenuItem className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)} key="Load breaker circuit (<15A)" value="Load breaker circuit (<15A)" >
                                                                                                                                    Load breaker circuit (&lt;15A)
                                                                                                                                </MenuItem>
                                                                                                                                <MenuItem className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)} key="Sub-breaker circuit (<60A)" value="Sub-breaker circuit (<60A)" >
                                                                                                                                    Sub-breaker circuit (&lt;60A)
                                                                                                                                </MenuItem>
                                                                                                                                <MenuItem className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)} key="Mains circuit (<250A)" value="Mains circuit (<250A)" >
                                                                                                                                    Mains circuit (&lt;250A)
                                                                                                                                </MenuItem>
                                                                                                                            </Select>
                                                                                                                        </FormControl>
                                                                                                                    </Grid>
                                                                                                                    <span className="error">
                                                                                                                        {touched
                                                                                                                            && hasError("required")
                                                                                                                            && `* Circuit Type is required`}
                                                                                                                    </span>
                                                                                                                </Grid>
                                                                                                            </div>
                                                                                                        )}
                                                                                                    />
                                                                                                </Col>
                                                                                                <Col className="pr-1" md="3">
                                                                                                    <FieldControl
                                                                                                        name="position"
                                                                                                        strict={false}
                                                                                                        render={({ handler, touched, hasError }) => (
                                                                                                            <div className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                                                                                                <Grid className="Formfieldcss" container spacing={1}>
                                                                                                                    <Grid item>
                                                                                                                        <TextField type="text" required fullWidth variant="outlined" error={touched && hasError("required")} label="Position" {...handler("text")} />
                                                                                                                    </Grid>
                                                                                                                    <span className="error">
                                                                                                                        {touched
                                                                                                                            && hasError("required")
                                                                                                                            && `* Position is required`}
                                                                                                                    </span>
                                                                                                                </Grid>
                                                                                                            </div>
                                                                                                        )}
                                                                                                    />
                                                                                                </Col>
                                                                                                <Col className="pr-1" md="3">
                                                                                                    <FieldControl
                                                                                                        name="breaker"
                                                                                                        strict={false}
                                                                                                        render={({ handler, touched, hasError }) => (
                                                                                                            <div className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                                                                                                <Grid className="Formfieldcss" container spacing={1}>
                                                                                                                    <Grid item>
                                                                                                                        <TextField type="text" required fullWidth variant="outlined" error={touched && hasError("required")} label="Digital Breaker ID" {...handler("text")} />
                                                                                                                    </Grid>
                                                                                                                    <span className="error">
                                                                                                                        {touched
                                                                                                                            && hasError("required")
                                                                                                                            && `* Digital Breaker ID is required`}
                                                                                                                    </span>
                                                                                                                </Grid>
                                                                                                            </div>
                                                                                                        )}
                                                                                                    />
                                                                                                </Col>
                                                                                                <Col className="pr-1" md="3">
                                                                                                    <div className="login-button changepassword text-center">
                                                                                                        <Button
                                                                                                            type="submit"
                                                                                                            variant="contained"
                                                                                                            color="primary"
                                                                                                            disabled={invalid}
                                                                                                            onClick={(event) => this.handleDegitalCircuitSubmit(event, this.breakerCiruitForm.getRawValue())}
                                                                                                        >
                                                                                                            {this.state.buttontext}
                                                                                                        </Button>
                                                                                                    </div>
                                                                                                </Col>
                                                                                                <Col className="pr-1" md="3">
                                                                                                    <div className="login-button changepassword text-center">
                                                                                                        <Button
                                                                                                            onClick={() => this.DigitalCircuitcancel()}
                                                                                                            variant="contained"
                                                                                                            color="danger"
                                                                                                        >
                                                                                                            Cancel
                                                                                                        </Button>
                                                                                                    </div>
                                                                                                </Col>
                                                                                            </Row>
                                                                                            <Row>
                                                                                                <Col className="pr-1" md="3">
                                                                                                </Col>

                                                                                                <Col className="pr-1" md="3">
                                                                                                </Col>
                                                                                            </Row>
                                                                                        </form>
                                                                                    )}
                                                                                />
                                                                            </CardBody>

                                                                        </Card>
                                                                    </Col>
                                                                </Row>
                                                                <Row>
                                                                    <Col md="12">
                                                                        <Card className={"card-user " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                                                            <CardHeader>
                                                                                <CardTitle tag="h5"></CardTitle>
                                                                            </CardHeader>
                                                                            <CardBody>
                                                                                <Row>
                                                                                    <Col className="pr-1" md="9">
                                                                                    </Col>
                                                                                    <Col className="pr-1" md="3">

                                                                                        <div className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                                                                            <Grid className="Formfieldcss" container spacing={1}>
                                                                                                <Grid item>
                                                                                                    <TextField type="text" fullWidth variant="outlined" label="Search" value={this.state.searchTerm} onChange={this.handleSearchInputChange} />
                                                                                                </Grid>

                                                                                            </Grid>
                                                                                        </div>
                                                                                        {/* <input className='MuiGrid-root MuiGrid-item'
                                                                                            type="text"
                                                                                            placeholder="Search..."
                                                                                            //value={searchTerm}
                                                                                            onChange={this.handleSearchInputChange}
                                                                                        /> */}
                                                                                    </Col>
                                                                                </Row>

                                                                                <div className={"table-responsive " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                                                                    <table id="boardtable" className={"nbztable dataTable no-footer " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                                                                        <thead>
                                                                                            <tr>
                                                                                                <th style={{ width: '100px' }}>Actions</th>
                                                                                                <th style={{ width: '100px' }}>Board</th>
                                                                                                <th style={{ width: '100px' }}>Circuit Type</th>
                                                                                                <th style={{ width: '100px' }}>Position</th>
                                                                                                <th style={{ width: '100px' }}>Digital Breaker Id</th>
                                                                                                <th style={{ width: '200px' }}>Created / Updated at</th>

                                                                                            </tr>

                                                                                        </thead>
                                                                                        <tbody>
                                                                                            {filteredData.map((value, index) => (
                                                                                                <tr key={index + 1}>
                                                                                                    {/* <td>{index+1}</td> */}
                                                                                                    <td>
                                                                                                        <a href="javascript:void(0)" onClick={(event) => this.lightBreakerdelete(event, value.id)}><i className="fa fa-trash icons"></i></a>&nbsp;&nbsp;&nbsp;&nbsp;
                                                                                                        <a href="javascript:void(0)" onClick={(e) => this.lightBreakeredit(value)}><i className="fa fa-edit icons"></i></a>
                                                                                                    </td>
                                                                                                    {/* <td>{this.BindBoardName(value.lboard_id)}</td> */}
                                                                                                    <td>{value.board_id}</td>
                                                                                                    <td>{value.circuit_type}</td>
                                                                                                    <td>{value.position}</td>
                                                                                                    <td>{value.breaker}</td>
                                                                                                    <td>{Moment(value.updated_at).format('MMM DD YYYY HH:mm:ss')}</td>

                                                                                                </tr>
                                                                                            ))}
                                                                                        </tbody>
                                                                                    </table>
                                                                                </div>
                                                                            </CardBody>
                                                                        </Card>
                                                                    </Col>
                                                                </Row>
                                                            </>
                                                            :
                                                            <Row>
                                                                <Col md="12">
                                                                    <Card className={"card-user " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                                                        <CardHeader>
                                                                        </CardHeader>
                                                                        <CardBody>
                                                                            <div style={{ width: "100%", height: "100px", fontSize: '22px', textAlign: 'center' }}>Please Choose atleast one Board to view the data ...</div>
                                                                        </CardBody>
                                                                    </Card>
                                                                </Col>
                                                            </Row>
                                                        }
                                                    </div>
                                                </TabPane>
                                                <TabPane tabId="5">
                                                    <div>
                                                        {this.state.showcontent == true ?
                                                            <>
                                                                <Row>
                                                                    <Col md="12">
                                                                        <Card className={"card-user " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                                                            <CardHeader>
                                                                                <CardTitle tag="h5"></CardTitle>
                                                                            </CardHeader>
                                                                            <CardBody>
                                                                                <FieldGroup
                                                                                    control={this.BoardForm}
                                                                                    strict={false}
                                                                                    render={({ get, invalid, value }) => (
                                                                                        <form>
                                                                                            <Row>
                                                                                                <Col className="pr-1" md="3">
                                                                                                    <FieldControl
                                                                                                        name="breaker_type"
                                                                                                        strict={false}
                                                                                                        render={({ handler, touched, hasError }) => (
                                                                                                            <div className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                                                                                                <Grid className="Formfieldcss" container spacing={1}>
                                                                                                                    <Grid item>
                                                                                                                        <FormControl variant="outlined"
                                                                                                                            style={{ width: "100%", marginBottom: 32 }}>
                                                                                                                            <InputLabel id="demo-mutiple-name-label300">Breaker Type</InputLabel>
                                                                                                                            <Select
                                                                                                                                labelId="demo-mutiple-name-label300"
                                                                                                                                id="demo-mutiple-name-label300"
                                                                                                                                fullWidth variant="outlined"
                                                                                                                                required
                                                                                                                                label="Breaker Type"
                                                                                                                                //value={this.state.selectedshift}
                                                                                                                                {...handler("text")}
                                                                                                                            >
                                                                                                                                <MenuItem className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)} value='' >
                                                                                                                                    Select Breaker Type
                                                                                                                                </MenuItem>
                                                                                                                                <MenuItem className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)} key="Main breaker" value="Main breaker" >
                                                                                                                                    Main breaker
                                                                                                                                </MenuItem>
                                                                                                                                <MenuItem className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)} key="Sub-Main breaker" value="Sub-Main breaker" >
                                                                                                                                    Sub-Main breaker
                                                                                                                                </MenuItem>
                                                                                                                            </Select>
                                                                                                                        </FormControl>
                                                                                                                    </Grid>
                                                                                                                    <span className="error">
                                                                                                                        {touched
                                                                                                                            && hasError("required")
                                                                                                                            && `* Breaker Type is required`}
                                                                                                                    </span>
                                                                                                                </Grid>
                                                                                                            </div>
                                                                                                        )}
                                                                                                    />
                                                                                                </Col>
                                                                                                <Col className="pr-1" md="3">
                                                                                                    <FieldControl
                                                                                                        name="breaker_name"
                                                                                                        strict={false}
                                                                                                        render={({ handler, touched, hasError }) => (
                                                                                                            <div className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                                                                                                <Grid className="Formfieldcss" container spacing={1}>
                                                                                                                    <Grid item>
                                                                                                                        <TextField type="text" required fullWidth variant="outlined" error={touched && hasError("required")} label="Breaker" {...handler("text")} />
                                                                                                                    </Grid>
                                                                                                                    <span className="error">
                                                                                                                        {touched
                                                                                                                            && hasError("required")
                                                                                                                            && `* Breaker is required`}
                                                                                                                    </span>
                                                                                                                </Grid>
                                                                                                            </div>
                                                                                                        )}
                                                                                                    />
                                                                                                </Col>

                                                                                                <Col className="pr-1" md="3">
                                                                                                    <FieldControl
                                                                                                        name="lboard"
                                                                                                        strict={false}
                                                                                                        render={({ handler, touched, hasError }) => (
                                                                                                            <div className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                                                                                                <Grid className="Formfieldcss" container spacing={1}>
                                                                                                                    <Grid item>
                                                                                                                        <TextField type="text" required fullWidth variant="outlined" error={touched && hasError("required")} label="Board ID" {...handler("text")} />
                                                                                                                    </Grid>
                                                                                                                    <span className="error">
                                                                                                                        {touched
                                                                                                                            && hasError("required")
                                                                                                                            && `* Board ID is required`}
                                                                                                                    </span>
                                                                                                                </Grid>
                                                                                                            </div>
                                                                                                        )}
                                                                                                    />
                                                                                                </Col>
                                                                                                <Col className="pr-1" md="3">
                                                                                                    <div className="login-button changepassword text-center">
                                                                                                        <Button
                                                                                                            type="submit"
                                                                                                            variant="contained"
                                                                                                            color="primary"
                                                                                                            disabled={invalid}
                                                                                                            onClick={(event) => this.handleBoardSubmit(event, this.BoardForm.getRawValue())}
                                                                                                        >
                                                                                                            {this.state.boardbuttontext}
                                                                                                        </Button>
                                                                                                    </div>
                                                                                                </Col>
                                                                                                <Col className="pr-1" md="3">
                                                                                                    <div className="login-button changepassword text-center">
                                                                                                        <Button
                                                                                                            onClick={() => this.Boardcancel()}
                                                                                                            variant="contained"
                                                                                                            color="danger"
                                                                                                        >
                                                                                                            Cancel
                                                                                                        </Button>
                                                                                                    </div>
                                                                                                </Col>
                                                                                            </Row>
                                                                                            <Row>
                                                                                                <Col className="pr-1" md="3">
                                                                                                </Col>

                                                                                                <Col className="pr-1" md="3">
                                                                                                </Col>
                                                                                            </Row>
                                                                                        </form>
                                                                                    )}
                                                                                />
                                                                            </CardBody>

                                                                        </Card>
                                                                    </Col>
                                                                </Row>
                                                                <Row>
                                                                    <Col md="12">
                                                                        <Card className={"card-user " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                                                            <CardHeader>
                                                                                <CardTitle tag="h5"></CardTitle>
                                                                            </CardHeader>
                                                                            <CardBody>
                                                                                <Row>
                                                                                    <Col className="pr-1" md="9">
                                                                                    </Col>
                                                                                    <Col className="pr-1" md="3">

                                                                                        <div className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                                                                            <Grid className="Formfieldcss" container spacing={1}>
                                                                                                <Grid item>
                                                                                                    <TextField type="text" fullWidth variant="outlined" label="Search" value={this.state.searchTerm} onChange={this.handleSearchInputChange} />
                                                                                                </Grid>

                                                                                            </Grid>
                                                                                        </div>
                                                                                        {/* <input className='MuiGrid-root MuiGrid-item'
                                                                                            type="text"
                                                                                            placeholder="Search..."
                                                                                            //value={searchTerm}
                                                                                            onChange={this.handleSearchInputChange}
                                                                                        /> */}
                                                                                    </Col>
                                                                                </Row>
                                                                                <div className={"table-responsive " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                                                                    <table id="boardtable" className={"nbztable dataTable no-footer " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                                                                        <thead>
                                                                                            <tr>
                                                                                                <th style={{ width: '100px' }}>Actions</th>
                                                                                                <th style={{ width: '100px' }}>Breaker Name</th>
                                                                                                <th style={{ width: '100px' }}>Breaker Type</th>
                                                                                                <th style={{ width: '100px' }}>Board</th>
                                                                                                <th style={{ width: '200px' }}>Created / Updated at</th>

                                                                                            </tr>

                                                                                        </thead>
                                                                                        <tbody>
                                                                                            {filteredLightBoardList.map((value, index) => (
                                                                                                <tr key={index + 1}>
                                                                                                    <td>
                                                                                                        <a href="javascript:void(0)" onClick={(event) => this.boarddelete(event, value.id)}><i className="fa fa-trash icons"></i></a>&nbsp;&nbsp;&nbsp;&nbsp;
                                                                                                        <a href="javascript:void(0)" onClick={(e) => this.boardedit(value)}><i className="fa fa-edit icons"></i></a>
                                                                                                    </td>
                                                                                                    <td>{value.breaker_name}</td>
                                                                                                    <td>{value.breaker_type}</td>
                                                                                                    <td>{value.lboard}</td>

                                                                                                    <td>{Moment(value.updated_at).format('MMM DD YYYY HH:mm:ss')}</td>
                                                                                                </tr>
                                                                                            ))}
                                                                                        </tbody>
                                                                                    </table>
                                                                                </div>
                                                                            </CardBody>
                                                                        </Card>
                                                                    </Col>
                                                                </Row>
                                                            </>
                                                            :
                                                            <Row>
                                                                <Col md="12">
                                                                    <Card className={"card-user " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                                                        <CardHeader>
                                                                        </CardHeader>
                                                                        <CardBody>
                                                                            <div style={{ width: "100%", height: "100px", fontSize: '22px', textAlign: 'center' }}>Please Choose atleast one Board to view the data ...</div>
                                                                        </CardBody>
                                                                    </Card>
                                                                </Col>
                                                            </Row>
                                                        }
                                                    </div>
                                                </TabPane>
                                            </TabContent>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        </>
                        :
                        <Row>

                        </Row>
                    }
                </div>
            </>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        theme: state.theme,
        themecolor: state.themecolor,
        charttheme: state.charttheme
    }
}
export default connect(mapStateToProps)(LightScheduleComponent);

