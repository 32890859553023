import React, { Component } from 'react';
import axios from 'axios';
import Loader from '../services/normalloader';
import TopNavDropdown from './TopDropdown';
import Moment from 'moment';
import * as apiService from '../services/apiService';
import { CardHeader, Card, CardBody, CardTitle, Col, Nav, NavItem, NavLink, Row, TabContent, TabPane, Button } from 'reactstrap';
import { MDBContainer, MDBBtn, MDBModal, MDBModalBody, MDBModalHeader, MDBModalFooter } from 'mdbreact';
import Switch from '@material-ui/core/Switch';
import classnames from 'classnames';
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import { DateTime } from 'luxon';
import {
    FormBuilder,
    FieldGroup,
    FieldControl,
    Validators,
} from "react-reactive-form";
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import { FormControl, InputLabel, Select } from '@material-ui/core';
import swal from 'sweetalert';
import { DateRangePickerComponent, PresetsDirective, PresetDirective } from '@syncfusion/ej2-react-calendars';
import Thermostat from './Thermostat';
import floor_image from '../assets/images/poorvika_blueprint.jpg';
import dial_image from '../assets/images/dial_nbz.png';
import swingicon from '../assets/images/swing2.png';
import swingicon1 from '../assets/images/displayswing.png';
import schedule from '../assets/images/schedule.png';
import remote from '../assets/images/remote.png';
const $ = require('jquery');
$.DataTable = require('datatables.net');
class HeaterScheduleComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loader: false,
            showcontent: false,
            modal: false,
            activetab: "3",
            dataChange: false,
            buttontext: 'Add',
            schedules: [],
            isAddingSchedule: false,
            isEditingSchedule: false,
            selectedSchedule: null,
            ChoosedThermoStat: null,
            heater_thermo_id: '',
            thermodropDownList: [],
            BoardList: [],
            AllCustomerList: [],
            dropDownList: {
                equipment: [],
                operation: [],
                speed: [],
                swing: [],
                temperature: []
            },
            newSchedule: {
                schedule_name: '',
                customer_id: '',
                events: [],
            },
            newEvent: {
                time: '',
                operation: '',
            },
            newAssignment: {
                //from_date: "",
                to_date: '',
                heater_schedule_id: '',
                thermo_id: ''
            },
            thermostatList: [],
            dateselected: [new Date(new Date().toDateString()), new Date(new Date().toDateString())],
            from_date: "",
            to_date: '',

            displayData: {
                temp: 0,
                power: 'AUTO',
                fan: 'AUTO',
                swing: 'AUTO',

            }
        };
        this.selectedcustomer = localStorage.getItem('selectedcustomer');
        this.selectedboard = ''
        this.customerhandler = this.customerhandler.bind(this);
        this.boardhandler = this.boardhandler.bind(this)

        this.togglemodal = this.togglemodal.bind(this)
        this.getRemote = this.getRemote.bind(this)
        this.fanControl = this.fanControl.bind(this)
        this.swingControl = this.swingControl.bind(this)
        this.increaseTemp = this.increaseTemp.bind(this)
        this.decreaseTemp = this.decreaseTemp.bind(this)
        this.closeRemote = this.closeRemote.bind(this)

        this.allScheduleList = [];
        this.allAssignList = [];
        //this.datehandleChange1([new Date(new Date().toDateString()), new Date(new Date().toDateString())]);
        this.getdropdowndata();
        this.getThermoList();
        this.getallcustomerdata();
    }
    thermostatForm = FormBuilder.group({
        id: [""],
        name: ["", [Validators.required]],
        desc: ["", [Validators.required]],
        customer_id: [""],
        board_id: [""],
    });
    handleSubmitControl(event, val) {
        event.preventDefault();
        this.setState({ loader: true })
        apiService.heaterController(val, this.state.heater_thermo_id).then(response => {
            this.setState({ loader: false })
            let msg = response.data[0].message
            apiService.Alerts(msg)
        }).catch(error => {
            this.setState({ loader: false })
            apiService.ErrorMessage(error)
        })
    }
    thermocancel() {
        this.setState({
            buttontext: 'Add'
        })
        this.thermostatForm.reset()
    }
    getthermodropdowndata() {
        this.setState({ loader: true })
        apiService.getThermostatDropdown().then(response => {


            this.setState({ thermodropDownList: response.data[4].temperature, loader: false })
        }).catch(error => {
            this.setState({ loader: false })
            apiService.ErrorMessage(error)
        })
    }

    handleSubmit(event, value) {

        // debugger;
        event.preventDefault();
        if (value.id == '' || value.id == undefined || value.id == null) {
            if (this.selectedcustomer == '' || this.selectedcustomer == undefined || this.selectedcustomer == null) {
                value.customer_id = localStorage.getItem('selectedcustomer');
            }
            else {
                value.customer_id = this.selectedcustomer;
            }
            value.board_id = this.selectedboard;
            this.setState({ loader: true })
            apiService.addHeaterThermostat(value).then(response => {
                this.setState({ loader: false })
                apiService.Alerts("Thermostat Added Successfully")
                this.getThermostat();
                this.thermocancel();
                this.toggle("3");
            }).catch(error => {
                this.setState({ loader: false })
                apiService.ErrorMessage(error)
            })
        } else {
            this.setState({ loader: true })
            apiService.updateHeaterThermostat(value).then(response => {
                this.setState({ loader: false })
                apiService.Alerts("Board Updated Successfully")
                this.getThermostat();
                this.thermocancel();
            }).catch(error => {
                this.setState({ loader: false })
                apiService.ErrorMessage(error)
            })
        }

    }

    thermostatedit(value) {
        this.thermostatForm.patchValue({
            id: value.id,
            name: value.thermo_name,
            desc: value.thermo_desc,
            board_id: value.board_id,
            customer_id: value.customer_id,
        })
        this.setState({
            buttontext: 'Update'
        })
    }

    thermostatdelete(event, value) {
        event.preventDefault();
        swal({
            title: "Are you sure?",
            text: "Once deleted, you will not be able to recover this Thermostat!",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((willDelete) => {
                if (willDelete) {
                    event.preventDefault();
                    this.setState({ loader: true })
                    apiService.deleteHeaterThermostat(value).then(response => {
                        this.setState({ loader: false })
                        apiService.Alerts("Thermostat Deleted Successfully")
                        this.getThermostat();
                        this.thermocancel();
                    }).catch(error => {
                        this.setState({ loader: false })
                        apiService.ErrorMessage(error)
                    })
                } else {
                    // swal("Your file is safe!");
                }
            });
    }
    getdropdowndata() {
        this.setState({ loader: true })
        apiService.getThermostatDropdown().then(response => {
            var x = {
                equipment: response.data[0].Equipment,
                operation: response.data[1].operation,
                speed: response.data[2].speed,
                swing: response.data[3].swing,
                temperature: response.data[4].temperature,

            }
            this.setState({ dropDownList: x, loader: false })
        }).catch(error => {
            this.setState({ loader: false })
            apiService.ErrorMessage(error)
        })
    }
    getallcustomerdata() {
        this.setState({ loader: true })
        apiService.topCustomerDropdown().then(response => {
            var x = response.data;
            this.setState({ AllCustomerList: x, loader: false })
        }).catch(error => {
            this.setState({ loader: false })
            apiService.ErrorMessage(error)
        })
    }
    cancel() {
        this.setState({
            newEvent: {
                time: '',
                operation: '',
            }
        });
    }
    getThermoList() {
        apiService.getHeaterThermostat().then(response => {
            this.setState({ thermostatList: response.data, loader: false })

        }, error => {
            this.setState({ loader: false })
            apiService.ErrorMessage(error)

        })
    }
    customerhandler(customerId) {
        this.selectedcustomer = customerId
        this.setState({
            showcontent: false
        });
        localStorage.setItem('selectedcustomer', customerId)
        this.fetchSchedules();
        this.toggle("3");
    }
    boardhandler(board_id) {
        this.selectedboard = board_id
        this.setState({
            showcontent: true,
            schedules: [],
            isAddingSchedule: false,
            isEditingSchedule: false,
            selectedSchedule: null,
            newSchedule: {
                schedule_name: '',
                events: [],
            },
            newEvent: {
                time: '',
                operation: '',
            },
        })
        localStorage.setItem('selectedboard', board_id)
        //this.getThermostat()
        //this.cancel()
        this.setState({
            showcontent: true
        })

        this.fetchSchedules();
        this.toggle("3");
        if (this.state.activetab == '1') {

        } else {
            if (this.state.activetab == '2') {
                this.datehandleChange1([new Date(new Date().toDateString()), new Date(new Date().toDateString())]);
                this.fetchAssignment();
            } else {
                if (this.state.activetab == '3') {
                    this.setState({
                        showcontent: true,
                        thermostatList: []
                    }, () => {
                        this.getThermostat()
                    })
                } else {
                    if (this.state.activetab == '4') {
                        this.setState({
                            showcontent: true,
                            ChoosedThermoStat: null,
                            thermostatName: "",
                            autoStatusSetting: "",
                            dataChange: false,
                            displayData: {
                                temp: 0,
                                power: 'AUTO',
                                fan: 'AUTO',
                                swing: 'AUTO',

                            },
                            buttontext: 'Add',

                            scheduleHistoryList: [],
                            ScheduleList: [],
                        })
                        this.getThermostat()
                        this.hvaccancel()
                        this.setState({
                            showcontent: true
                        })
                    }
                }
            }
        }

    }
    componentDidMount() {
        this.fetchSchedules();
        this.getthermodropdowndata();
        this.customerhandler(this);
    }
    scheduleCancel() {
        // this.setState({ isAddingSchedule: false, isEditingSchedule: false })
        this.setState({
            newEvent: {
                time: '',
                operation: '',
            },
            newSchedule: {
                schedule_name: '',
                customer_id: '',
                events: [],
            },
            isAddingSchedule: false,
            isEditingSchedule: false
        });
    }
    toggle(option) {
        var changeToggle = false;
        if (this.state.activetab != option) {
            if (this.state.activetab == '1' && (this.state.isAddingSchedule || this.state.isEditingSchedule)) {
                swal({
                    title: "There are some unsaved changes in the schedule!",
                    text: "Once the tab is switched, you will not be able to recover the entered schedule",
                    icon: "warning",
                    buttons: true,
                    dangerMode: true,
                })
                    .then((willDelete) => {
                        if (willDelete) {
                            if (true) {
                                this.setState({
                                    activetab: option
                                })
                                if (option == "2") {
                                    this.datehandleChange1([new Date(new Date().toDateString()), new Date(new Date().toDateString())]);
                                    this.fetchAssignment();
                                    this.getThermostat();
                                }
                                if (option == "3") {
                                    this.getThermostat();
                                }
                                if (option == "1") {
                                    this.fetchSchedules();
                                    this.scheduleCancel();
                                }
                                if (option == "4") {
                                    this.getThermoList();
                                }
                            }
                        } else {
                            changeToggle = false;
                        }
                    });
            } else {
                changeToggle = true;
            }
        } else {
            if (this.state.activetab == '3') {
                changeToggle = true;
            }
            else {
                changeToggle = false;
                return false;
            }

        }
        if (changeToggle) {
            this.setState({
                activetab: option
            })
            if (option == "2") {
                this.datehandleChange1([new Date(new Date().toDateString()), new Date(new Date().toDateString())]);
                this.fetchAssignment();
                this.getThermostat();
            }
            if (option == "3") {
                this.getThermostat();
            }
            if (option == "1") {
                this.fetchSchedules();
                this.scheduleCancel();
            }
            if (option == "4") {
                this.getThermoList();
            }
        }
    }
    fetchAssignment = () => {
        this.setState({ loader: true })
        apiService.getHeaterAssignmentList(this.selectedcustomer).then(response => {
            this.allAssignList = response.data;
            this.setState({ loader: false })
        }, error => {
            this.setState({ loader: false })
            apiService.ErrorMessage(error)

        });
    };
    fetchSchedules = () => {
        this.setState({ loader: true })
        apiService.getHeaterScheduleList(this.selectedcustomer).then(response => {
            this.allScheduleList = response.data;
            this.setState({ schedules: response.data, loader: false })
        }, error => {
            this.setState({ loader: false })
            apiService.ErrorMessage(error)

        });
    };

    handleInputChange = (event) => {
        const { name, value } = event.target;
        this.setState({ newSchedule: { ...this.state.newSchedule, [name]: value } });
    };

    handleEventInputChange = (event) => {
        const { name, value } = event.target;
        this.setState({ newEvent: { ...this.state.newEvent, [name]: value } });
    };
    handleAssignEventInputChange = (event) => {
        const { name, value } = event.target;
        this.setState({ newAssignment: { ...this.state.newAssignment, [name]: value } });
    };
    handleHeaterEventInputChange = (event) => {
        const { name, value } = event.target;
        this.setState({ heater_thermo_id: event.target.value });
    };
    datehandleChange1(e) {
        const { newEvent, newAssignment } = this.state;

        this.setState({
            from_date: this.dateconvertion(e[0]),
            to_date: this.dateconvertion(e[1])
        });
    }

    dateconvertion(e) {
        const sy = e.getFullYear();
        const sm = ("0" + (e.getMonth() + 1)).slice(-2);
        const sd = ("0" + e.getDate()).slice(-2);
        var x = sy.toString() + '-' + sm.toString() + '-' + sd.toString();
        return x
    }
    addEvent = () => {
        const { newEvent, newSchedule } = this.state;
        if (this.state.newEvent.time == '' || this.state.newEvent.operation == '') {
            apiService.Alerts_error('Enter Event Details');
            return false;
        }

        this.setState({
            newSchedule: {
                ...newSchedule,
                events: [...newSchedule.events, { ...newEvent }],
            },
            newEvent: {
                time: '',
                operation: '',
            },
        });
    };
    BindScheduleName(id) {
        if (this.allScheduleList.filter(s => s.id == id).length > 0)
            return this.allScheduleList.filter(s => s.id == id)[0]['schedule_name']
        else {
            return "";
        }
    }
    BindEquipmentValue(id) {
        return this.state.dropDownList.equipment.filter(s => s.id == id)[0]['value']
    }
    BindModeValue(id) {
        return this.state.dropDownList.operation.filter(s => s.id == id)[0]['value']
    }
    BindSpeedValue(id) {
        return this.state.dropDownList.speed.filter(s => s.id == id)[0]['value']
    }
    BindSwingValue(id) {
        return this.state.dropDownList.swing.filter(s => s.id == id)[0]['value']
    }
    BindTemperatureValue(id) {
        return this.state.dropDownList.temperature.filter(s => s.id == id)[0]['value']
    }
    addSchedule = () => {
        if (this.state.newSchedule.schedule_name == '' || this.state.newSchedule.events.length == 0) {
            apiService.Alerts_error("Enter Schedule Name and Events");
            return false;
        }
        var timeZone = this.state.AllCustomerList.filter(s => s.id == this.selectedcustomer)[0]['time_zone']
        const updatedEvents = this.state.newSchedule.events.map(event => {
            return {
                ...event,
                time: this.convertToIndianTime(event.time, timeZone),
            };
        });
        this.setState(prevState => ({
            newSchedule: {
                ...prevState.newSchedule,
                events: updatedEvents,
            },
        }));
        const { newSchedule, schedules } = this.state;
        this.state.newSchedule.customer_id = this.selectedcustomer;
        var param = { schedule: [] };
        param.schedule = [this.state.newSchedule];
        param.schedule[0].events = updatedEvents
        this.setState({ loader: true })
        apiService.addHeaterScheduleCreation(param).then(response => {
            apiService.Alerts("Schedule Created Successfully")
            this.setState({ loader: false })
            this.setState({
                schedules: [...schedules, response.data],
                newSchedule: {
                    schedule_name: '',
                    events: [],
                },
                isAddingSchedule: false,
            });
            this.fetchSchedules();
        }).catch(error => {
            this.setState({ loader: false })
            apiService.ErrorMessage(error)
        })
    };
    convertToIndianTime = (inputTime, timeZone) => {
        if (timeZone == 'Asia/Kolkata' || timeZone == '') {
            return inputTime;
        } else {
            const newYorkTime = DateTime.fromFormat(inputTime, 'HH:mm', { zone: timeZone });
            const istTime = newYorkTime.setZone('Asia/Kolkata');
            //const options = { hour: 'numeric', minute: 'numeric', hour12: false, timeZoneName: 'short' };
            const istTimeFormatted = istTime.toLocaleString(DateTime.TIME_SIMPLE);
            //const newTime = new Date(`2000-01-01T${istTimeFormatted}`);
            const [time, amPm] = istTimeFormatted.split(' ');
            const [hours, minutes] = istTimeFormatted.split(':');
            let hour = parseInt(hours, 10);

            if (amPm === 'PM' && hour !== 12) {
                hour += 12;
            } else if (amPm === 'AM' && hour === 12) {
                hour = 0;
            }
            const formattedTime = `${hour.toString().padStart(2, '0')}:${minutes}`;
            const [FinalTime, removeAM] = formattedTime.split(' ');
            return FinalTime;
        }
    };
    convertToZoneTime = (inputTime, timeZone) => {
        if (timeZone == 'Asia/Kolkata' && timeZone == '') {
            return inputTime;
        } else {
            const [hoursS, minutesS] = inputTime.split(':');
            let Nhours = hoursS;
            let Nminutes = minutesS;
            if (hoursS.length == 1) {
                Nhours = hoursS.padStart(2, '0');
            }
            if (minutesS.length == 1) {
                Nminutes = minutesS.padStart(2, '0');
            }
            const newTime = `${Nhours}:${Nminutes}`;
            const newYorkTime = DateTime.fromFormat(newTime, 'HH:mm', { zone: 'Asia/Kolkata' });
            const istTime = newYorkTime.setZone(timeZone);
            //const options = { hour: 'numeric', minute: 'numeric', hour12: false, timeZoneName: 'short' };
            const istTimeFormatted = istTime.toLocaleString(DateTime.TIME_SIMPLE);
            //const newTime = new Date(`2000-01-01T${istTimeFormatted}`);
            const [time, amPm] = istTimeFormatted.split(' ');
            const [hours, minutes] = istTimeFormatted.split(':');
            let hour = parseInt(hours, 10);

            if (amPm === 'PM' && hour !== 12) {
                hour += 12;
            } else if (amPm === 'AM' && hour === 12) {
                hour = 0;
            }
            const formattedTime = `${hour.toString().padStart(2, '0')}:${minutes}`;
            const [FinalTime, removeAM] = formattedTime.split(' ');
            return FinalTime;
        }
    };
    addAssignment = () => {
        if (this.state.newAssignment.thermo_id == '' || this.state.newAssignment.hvac_schedule_id == '') {
            apiService.Alerts_error("Enter all details");
            return false;
        }
        if (this.state.from_date == '' || this.state.to_date == '') {
            apiService.Alerts_error("Enter all details");
            return false;
        }
        const { newAssignment, assignments } = this.state;
        this.setState({ loader: true });
        var param = this.state.newAssignment;
        param.thermo_desc = this.state.thermostatList.filter(s => s.id == param.thermo_id)[0]['thermo_desc'];
        param.thermo_name = this.state.thermostatList.filter(s => s.id == param.thermo_id)[0]['thermo_name'];
        apiService.addHeaterAssignment(param, this.state.from_date, this.state.to_date).then(response => {
            apiService.Alerts("Assign Created Successfully")
            this.setState({ loader: false })
            this.fetchAssignment();
        }).catch(error => {
            this.setState({ loader: false })
            apiService.ErrorMessage(error)
        })
    };
    editSchedule = (schedule) => {
        var newSchedules = {};
        newSchedules.schedule_name = schedule.schedule_name;
        newSchedules.events = schedule.events;
        this.setState({
            selectedSchedule: schedule,
            isEditingSchedule: true,
        });
        this.setState({
            newSchedule: newSchedules,
        });
        var timeZone = this.state.AllCustomerList.filter(s => s.id == this.selectedcustomer)[0]['time_zone']
        const updatedEvents = newSchedules.events.map(event => {
            return {
                ...event,
                time: this.convertToZoneTime(event.time, timeZone),
            };
        });
        this.setState(prevState => ({
            newSchedule: {
                ...prevState.newSchedule,
                events: updatedEvents,
            },
        }));
        // const { newEvent, newSchedule } = this.state;
        // this.setState({ newSchedule: { ...this.state.newSchedule, ['schedule_name']: schedule.schedule_name } });
        this.setState({
            newEvent: {
                time: '',
                operation: '',
            },
        });
    };
    componentDidMount() {
        // Fetch the list of schedules from the API and update the state
        this.fetchSchedules();
        this.getthermodropdowndata();
    }
    updateSchedule = () => {
        const { newSchedule, schedules } = this.state;
        this.state.newSchedule.customer_id = this.selectedcustomer;
        var param = { schedule: [] };
        param.schedule = [this.state.newSchedule];
        this.setState({ loader: true })
        var timeZone = this.state.AllCustomerList.filter(s => s.id == this.selectedcustomer)[0]['time_zone']
        const updatedEvents = this.state.newSchedule.events.map(event => {
            return {
                ...event,
                time: this.convertToIndianTime(event.time, timeZone),
            };
        });
        this.setState(prevState => ({
            newSchedule: {
                ...prevState.newSchedule,
                events: updatedEvents,
            },
        }));
        param.schedule[0].events = updatedEvents
        apiService.updateHeaterScheduleCreation(param, this.state.selectedSchedule.id).then(response => {
            apiService.Alerts("Schedule Updated Successfully")
            this.setState({ loader: false })
            this.setState({
                schedules: [...schedules, response.data],
                newSchedule: {
                    schedule_name: '',
                    events: [],
                },
                isEditingSchedule: false,
            });
            this.fetchSchedules();
        }).catch(error => {
            this.setState({ loader: false })
            apiService.ErrorMessage(error)
        })
    };
    scheduleDelete(value) {
        swal({
            title: "Are you sure?",
            text: "Once deleted, you will not be able to recover this Assignment!",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((willDelete) => {
                if (willDelete) {
                    // event.preventDefault();
                    this.setState({ loader: true })
                    apiService.deleteHeaterScheduleCreation(value).then(response => {
                        this.setState({ loader: false })
                        apiService.Alerts("Schedule Deleted Successfully")
                        this.fetchSchedules();
                    }).catch(error => {
                        this.setState({ loader: false })
                        apiService.ErrorMessage(error)
                    })
                } else {
                    // swal("Your file is safe!");
                }
            });
    }
    assignmentDelete(value) {
        swal({
            title: "Are you sure?",
            text: "Once deleted, you will not be able to recover this Schedule!",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((willDelete) => {
                if (willDelete) {
                    // event.preventDefault();
                    this.setState({ loader: true })
                    apiService.deleteHeaterAssignSchedule(value).then(response => {
                        this.setState({ loader: false })
                        apiService.Alerts("Assignment Deleted Successfully")
                        this.fetchAssignment();
                    }).catch(error => {
                        this.setState({ loader: false })
                        apiService.ErrorMessage(error)
                    })
                } else {
                    // swal("Your file is safe!");
                }
            });
    }
    TupdateSchedule = () => {
        const { selectedSchedule } = this.state;
        axios
            .put(
                `http://127.0.0.1:8000/api/hvac/schedule/new_update/${selectedSchedule.id}/`,
                { schedule: [selectedSchedule] }
            )
            .then(() => {
                this.setState({
                    selectedSchedule: null,
                    isEditingSchedule: false,
                });
            })
            .catch((error) => {
                console.error('Error updating schedule:', error);
            });
    };

    deleteEvent = (index) => {
        const { newSchedule } = this.state;
        const updatedEvents = [...newSchedule.events];
        updatedEvents.splice(index, 1);
        this.setState({
            newSchedule: {
                ...newSchedule,
                events: updatedEvents,
            },
        });
    };


    //-----------------------------REMOTE CONTROLS START-----------------------------------------------------------------
    hvaccancel() {

        this.scheduleForm.reset()
        this.setState({
            buttontext: 'Add',
            dateselected: this.state.today
        })

    }

    selectThermoStat(data) {
        var x = {
            temp: data.set_temp,
            power: data.operation,
            fan: "AUTO",
            swing: "AUTO",

        }


        if (data.operation == "ON") {
            x = {
                temp: data.schedule_temp,
                power: data.operation,
                fan: data.fan_speed,
                swing: data.swing_status,

            }
        } else if (data.operation == "OFF") {
            x = {
                temp: data.set_temp,
                power: data.operation,
                fan: "--",
                swing: "--",

            }
        }

        if (data.fan_speed == "") {
            this.setState({
                remoteDataFan: 'LOW'
            })
        }
        else {
            this.setState({
                remoteDataFan: data.fan_speed
            })
        }

        if (data.swing_status == "") {
            this.setState({
                remoteDataSwing: 'OFF'
            })
        }
        else {
            this.setState({
                remoteDataSwing: data.swing_status
            })
        }

        if (data.operation == 'ON') {
            this.setState({
                remoteDataPower: data.operation,
                remoteDataPowerid: 1,
                remoteDataTemp: data.schedule_temp,
            })
        }
        else {
            this.setState({
                remoteDataPower: data.operation,
                remoteDataPowerid: 2,
                remoteDataTemp: data.set_temp,
            })
        }
        this.setState({
            ChoosedThermoStat: data,
            thermostatName: data.thermo_desc,
            autoStatusSetting: data.status,
            displayData: x,
            remoteThermo_id: data.id,
            remoteThermo_name: data.thermo_name
        }
        )

        sessionStorage.setItem("selectedThermoStatID", data.id);

    }

    getThermostat() {

        this.setState({ loader: true })
        apiService.getHeaterThermostat().then(response => {
            this.setState({ thermostatList: response.data })
            this.setState({ loader: false })
            //var obj = JSON.parse(sessionStorage.getItem('selectedThermoStatID'))
            //if (sessionStorage.getItem('selectedThermoStatID').length == 0) {
            this.setState({
                thermostatList: response.data
            })
            //}
            // else {
            //     var item = this.state.thermostatList.find(item => item.id === obj);
            //     this.selectThermoStat(item)
            // }
        }).catch(error => {
            this.setState({ loader: false })
            apiService.ErrorMessage(error)
        })
    }
    togglemodal() {
        this.setState({
            modal: !this.state.modal
        });
        // this.getThermostat()
    }

    closeRemote() {
        this.getThermostat()
    }

    remoteApicall(name, value) {
        if (name == '') {
            this.setState({ loader: true })
            apiService.hvacRemoteApi(this.state.remoteThermo_id, this.state.remoteThermo_name, this.state.remoteDataPowerid, this.state.remoteDataFanid, this.state.remoteDataSwingid, this.state.remoteDataTemp).then(response => {
                this.setState({ loader: false })
                const remoteResponse = response.data[1]
                sessionStorage.setItem("selectedThermoStat", remoteResponse);
                this.setState({
                    loader: false,
                    displayData: {
                        temp: remoteResponse.temperature,
                        power: remoteResponse.operation,
                        fan: remoteResponse.fan_speed,
                        swing: remoteResponse.swing_status,

                    },
                })
                apiService.Alerts("Command sent")
                this.hvaccancel();
            }).catch(error => {
                this.setState({ loader: false })
                apiService.ErrorMessage(error)
            })
        }

        else {
            this.setState({ loader: true })
            apiService.hvacRemoteApiSingle(this.state.remoteThermo_id, this.state.remoteThermo_name, name, value).then(response => {
                this.setState({ loader: false })
                const remoteResponse = response.data[1]
                sessionStorage.setItem("selectedThermoStat", remoteResponse);
                this.setState({
                    loader: false,
                    displayData: {
                        temp: remoteResponse.temperature,
                        power: remoteResponse.operation,
                        fan: remoteResponse.fan_speed,
                        swing: remoteResponse.swing_status,

                    },
                })
                apiService.Alerts("Command sent")
                this.hvaccancel();
            }).catch(error => {
                this.setState({ loader: false })
                apiService.ErrorMessage(error)
            })
        }

    }

    getRemote() {
        if (this.state.remoteDataPower == 'OFF' || this.state.remoteDataPower == 'AUTO') {
            this.setState({
                remoteDataPower: 'ON',
                remoteDataPowerid: 1
            }, () => {
                this.remoteApicall("", "")
            })
        }
        else {
            this.setState({
                remoteDataPower: 'OFF',
                remoteDataPowerid: 2
            }, () => {
                this.remoteApicall('operation', this.state.remoteDataPowerid)
            })
        }
    }

    fanControl() {
        if (this.state.remoteDataPower == 'ON') {
            if (this.state.remoteDataFan == 'LOW') {
                this.setState({
                    remoteDataFan: 'HIGH',
                    remoteDataFanid: 4
                }, () => {
                    this.remoteApicall("fan_speed", this.state.remoteDataFanid)
                })
            }
            else {
                this.setState({
                    remoteDataFan: 'LOW',
                    remoteDataFanid: 3
                }, () => {
                    this.remoteApicall("fan_speed", this.state.remoteDataFanid)
                })
            }
        }
    }

    swingControl() {
        if (this.state.remoteDataPower == 'ON') {
            if (this.state.remoteDataSwing == 'OFF') {
                this.setState({
                    remoteDataSwing: 'ON',
                    remoteDataSwingid: 5
                }, () => {
                    this.remoteApicall('swing_status', this.state.remoteDataSwingid)
                })
            }
            else {
                this.setState({
                    remoteDataSwing: 'OFF',
                    remoteDataSwingid: 6
                }, () => {
                    this.remoteApicall('swing_status', this.state.remoteDataSwingid)
                })
            }
        }
    }

    increaseTemp() {
        if (this.state.remoteDataPower == 'ON') {
            if (this.state.remoteDataTemp == 32) {
                this.setState({
                    remoteDataTemp: 32
                }, () => {
                    this.remoteApicall('temperature', this.state.remoteDataTemp)
                })
            }
            else {
                this.setState({
                    remoteDataTemp: this.state.remoteDataTemp + 1
                }, () => {
                    this.remoteApicall('temperature', this.state.remoteDataTemp)
                })
            }
        }
    }

    decreaseTemp() {
        if (this.state.remoteDataPower == 'ON') {
            if (this.state.remoteDataTemp == 16) {
                this.setState({
                    remoteDataTemp: 16
                }, () => {
                    this.remoteApicall('temperature', this.state.remoteDataTemp)
                })
            }
            else {
                this.setState({
                    remoteDataTemp: this.state.remoteDataTemp - 1
                }, () => {
                    this.remoteApicall('temperature', this.state.remoteDataTemp)
                })
            }
        }
    }

    //-----------------------------REMOTE CONTROLS END-----------------------------------------------------------------


    render() {
        const currentDate = new Date();
        function firstcolor(data) {
            var x = '#1092C1'
            if (data == 'ON') {
                x = 'green'
            } else if (data == 'OFF') {
                x = 'red'
            }
            return x
        }
        const {
            schedules,
            isAddingSchedule,
            isEditingSchedule,
            selectedSchedule,
            newSchedule,
            newEvent,
        } = this.state;

        return (
            <>
                <div className="content">
                    {
                        this.state.loader && <Loader />
                    }
                    <TopNavDropdown customerhandler={this.customerhandler} selectedcustomer={this.selectedcustomer} boardhandler={this.boardhandler} selectedboard={this.selectedboard} />
                    {this.state.showcontent == true ?
                        <>
                            <Row>
                                <Col md="12">
                                    <Card className={"card-user " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                        <CardHeader>
                                            <CardTitle tag="h5" className="dashboardcardtitle" style={{ marginTop: 0 }}>Neuroswitch Control Centre (Heater)</CardTitle>
                                        </CardHeader>
                                    </Card>
                                </Col>
                                <Col md="12">
                                    <Card className={"card-user " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                        <CardHeader>
                                            <CardTitle tag="h5">
                                                <Nav tabs>
                                                    <NavItem>
                                                        <NavLink
                                                            className={classnames({ active: this.state.activetab === '3' }) + ' ' + (this.props.theme) + ' ' + (this.props.themecolor)}
                                                            onClick={() => { this.toggle('3'); }}
                                                        >
                                                            Management
                                                        </NavLink>
                                                    </NavItem>
                                                    <NavItem>
                                                        <NavLink
                                                            className={classnames({ active: this.state.activetab === '1' }) + ' ' + (this.props.theme) + ' ' + (this.props.themecolor)}
                                                            onClick={() => { this.toggle('1'); }}
                                                        >
                                                            Schedule Creation
                                                        </NavLink>
                                                    </NavItem>
                                                    <NavItem>
                                                        <NavLink
                                                            className={classnames({ active: this.state.activetab === '2' }) + ' ' + (this.props.theme) + ' ' + (this.props.themecolor)}
                                                            onClick={() => { this.toggle('2'); }}
                                                        >
                                                            Schedule Assignment
                                                        </NavLink>
                                                    </NavItem>
                                                    <NavItem>
                                                        <NavLink
                                                            className={classnames({ active: this.state.activetab === '4' }) + ' ' + (this.props.theme) + ' ' + (this.props.themecolor)}
                                                            onClick={() => { this.toggle('4'); }}
                                                        >
                                                            Control
                                                        </NavLink>
                                                    </NavItem>
                                                    {/* <NavItem>
                                                        <NavLink
                                                            className={classnames({ active: this.state.activetab === '4' }) + ' ' + (this.props.theme) + ' ' + (this.props.themecolor)}
                                                            onClick={() => { this.toggle('4'); }}
                                                        >
                                                            Monitor
                                                        </NavLink>
                                                    </NavItem> */}
                                                </Nav>
                                            </CardTitle>
                                        </CardHeader>
                                        <CardBody>
                                            <TabContent activeTab={this.state.activetab}>
                                                <TabPane tabId="1">

                                                    {/* {!isAddingSchedule && !isEditingSchedule && (
                                                        <button onClick={() => this.setState({ isAddingSchedule: true })}>Add New Schedule</button>
                                                    )} */}
                                                    <div>
                                                        <Card className={"card-user " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                                            <CardHeader>
                                                                <CardTitle tag="h5" className="flexblock">
                                                                    {this.state.thermostatName} {isAddingSchedule ? 'Create Schedule' : isEditingSchedule ? 'Edit Schedule' : 'Schedule List'}
                                                                    {!isAddingSchedule && !isEditingSchedule && (
                                                                        <span><Button
                                                                            onClick={() => this.setState({ isAddingSchedule: true })}
                                                                            variant="contained"
                                                                            color="primary"
                                                                            className="flexblockbutton"
                                                                        >
                                                                            New Schedule
                                                                        </Button></span>
                                                                    )}

                                                                </CardTitle>

                                                            </CardHeader>
                                                            <CardBody style={{ textAlign: "center" }}>

                                                                {/* List */}
                                                                {!isAddingSchedule && !isEditingSchedule && (
                                                                    <div className={"table-responsive " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                                                        <table id="logstable" className={"nbztable dataTable no-footer " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                                                            <thead>
                                                                                <tr>
                                                                                    <th style={{ width: '100px' }}>Actions</th>
                                                                                    <th style={{ width: '100px' }}>Schedule Name</th>
                                                                                    <th style={{ width: '200px' }}>Created / Updated at</th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                                {this.allScheduleList.map((value, index) => (
                                                                                    <tr key={index + 1}>
                                                                                        {/* <td>{index+1}</td> */}
                                                                                        <td>
                                                                                            <a href="javascript:void(0)" onClick={(e) => this.editSchedule(value)}><i className="fa fa-edit icons"></i></a>
                                                                                            <a href="javascript:void(0)" onClick={(e) => this.scheduleDelete(value.id)}><i className="fa fa-trash icons"></i></a>

                                                                                        </td>
                                                                                        <td>{value.schedule_name}</td>
                                                                                        <td>{Moment(value.updated_at).format('MMM DD YYYY HH:mm:ss')}</td>
                                                                                    </tr>

                                                                                ))}
                                                                            </tbody>
                                                                        </table>
                                                                    </div>
                                                                )}

                                                                {/* Edit/Add */}
                                                                {(isAddingSchedule || isEditingSchedule) && (
                                                                    <div>
                                                                        <Row>
                                                                            <Col className="pr-1" md="3">
                                                                                <div className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                                                                    <Grid className={"Formfieldcss"} container spacing={1}>
                                                                                        <Grid item>
                                                                                            <TextField required fullWidth variant="outlined" type="text"
                                                                                                name="schedule_name"
                                                                                                label="Schedule Name"
                                                                                                value={newSchedule.schedule_name}
                                                                                                onChange={this.handleInputChange} />
                                                                                        </Grid>
                                                                                    </Grid>
                                                                                </div>
                                                                            </Col>
                                                                        </Row>
                                                                        {/* Event input fields */}
                                                                        <div>
                                                                            <CardHeader>
                                                                                <CardTitle tag="h5" className="flexblock">
                                                                                    Add Event
                                                                                    <p>
                                                                                        <Button
                                                                                            type="submit"
                                                                                            variant="contained"
                                                                                            color="primary"
                                                                                            // disabled={invalid}
                                                                                            onClick={this.addEvent}
                                                                                        >
                                                                                            Add
                                                                                        </Button>
                                                                                        <Button
                                                                                            onClick={() => this.cancel()}
                                                                                            variant="contained"
                                                                                            color="danger"
                                                                                        >
                                                                                            Reset
                                                                                        </Button>
                                                                                    </p>
                                                                                </CardTitle>

                                                                            </CardHeader>
                                                                            <Row>
                                                                                <Col className="pr-1" md="3">
                                                                                    <div className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                                                                        <Grid className={"Formfieldcss"} container spacing={1}>
                                                                                            <Grid item>
                                                                                                <TextField type="time" required fullWidth variant="outlined" name="time" onChange={this.handleEventInputChange} label="Start Time " value={this.state.newEvent.time} />
                                                                                            </Grid>
                                                                                        </Grid>
                                                                                    </div>
                                                                                </Col>
                                                                                <Col className="pr-1" md="3">
                                                                                    <div className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                                                                        <Grid className="Formfieldcss" container spacing={1}>
                                                                                            <Grid item>
                                                                                                <TextField fullWidth
                                                                                                    select
                                                                                                    label="Select Operation"
                                                                                                    variant="outlined"
                                                                                                    name="operation"
                                                                                                    value={this.state.newEvent.operation}
                                                                                                    onChange={this.handleEventInputChange}
                                                                                                >
                                                                                                    <MenuItem className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)} value='' >
                                                                                                        Select Operation
                                                                                                    </MenuItem>
                                                                                                    {this.state.dropDownList.operation.map(option => (
                                                                                                        <MenuItem className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)} key={option.id} value={option.id} >
                                                                                                            {option.value}
                                                                                                        </MenuItem>
                                                                                                    ))}
                                                                                                </TextField>
                                                                                            </Grid>
                                                                                        </Grid>

                                                                                    </div>

                                                                                </Col>
                                                                            </Row>
                                                                        </div>

                                                                        <div className={"table-responsive " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                                                            <CardHeader>
                                                                                <CardTitle tag="h5" className="flexblock">
                                                                                    Event List
                                                                                    {(isAddingSchedule || isEditingSchedule) && (<p>
                                                                                        {isAddingSchedule && (
                                                                                            <span><Button
                                                                                                onClick={this.addSchedule}
                                                                                                variant="contained"
                                                                                                color="primary"
                                                                                                className="flexblockbutton"
                                                                                            >
                                                                                                Save
                                                                                            </Button></span>
                                                                                        )}

                                                                                        {isEditingSchedule && (
                                                                                            <span><Button
                                                                                                onClick={this.updateSchedule}
                                                                                                variant="contained"
                                                                                                color="primary"
                                                                                                className="flexblockbutton"
                                                                                            >
                                                                                                Update
                                                                                            </Button></span>
                                                                                        )}
                                                                                        {(isAddingSchedule || isEditingSchedule) && (
                                                                                            <span><Button
                                                                                            onClick={() => this.scheduleCancel()}
                                                                                                variant="contained"
                                                                                                color="danger"
                                                                                                className="flexblockbutton"
                                                                                            >
                                                                                                Cancel
                                                                                            </Button></span>
                                                                                        )}
                                                                                    </p>)}
                                                                                </CardTitle>

                                                                            </CardHeader>
                                                                            <table id="Eventtable" className={"nbztable dataTable no-footer " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                                                                <thead>
                                                                                    <tr>
                                                                                        <th style={{ width: '100px' }}>Actions</th>
                                                                                        <th style={{ width: '100px' }}>Time</th>
                                                                                        <th style={{ width: '100px' }}>Operation</th>
                                                                                    </tr>
                                                                                </thead>
                                                                                <tbody>
                                                                                    {newSchedule.events.map((event, index) => (
                                                                                        <tr key={index}>
                                                                                            <td>
                                                                                                <a href="javascript:void(0)" onClick={() => this.deleteEvent(index)}><i className="fa fa-trash icons"></i></a>
                                                                                            </td>
                                                                                            <td>{event.time}</td>
                                                                                            <td>{this.BindModeValue(event.operation)}</td>
                                                                                            {/* <td>{this.state.dropDownList.equipment.filter(s=>s.id==event.equipment)[0]['value']}</td>
                                                                                            <td>{this.state.dropDownList.operation.filter(s=>s.id==event.operation)[0]['value']}</td>
                                                                                            <td>{this.state.dropDownList.operation.filter(s=>s.id==event.operation)[0]['value']}</td>
                                                                                            <td>{this.state.dropDownList.speed.filter(s=>s.id==event.fan_speed)[0]['value']}</td>
                                                                                            <td>{this.state.dropDownList.swing_status.filter(s=>s.id==event.swing_status)[0]['value']}</td>
                                                                                            <td>{this.state.dropDownList.temperature.filter(s=>s.id==event.temperature)[0]['value']}</td> */}
                                                                                        </tr>
                                                                                    ))}
                                                                                </tbody>
                                                                            </table>
                                                                        </div>


                                                                    </div>
                                                                )}
                                                            </CardBody>

                                                        </Card>
                                                        {/* <h3>Schedules</h3>
                                                        <ul>
                                                            {schedules.map((schedule) => (
                                                                <li key={schedule.id}>
                                                                    {schedule.schedule_name}
                                                                    <button onClick={() => this.editSchedule(schedule)}>Edit</button>
                                                                </li>
                                                            ))}
                                                        </ul> */}
                                                    </div>
                                                </TabPane>
                                                <TabPane tabId="2">
                                                    <div>

                                                        {/* Event input fields */}
                                                        <div>
                                                            <CardHeader>
                                                                <CardTitle tag="h5" className="flexblock">
                                                                    Assign Schedule
                                                                </CardTitle>

                                                            </CardHeader>
                                                            <Row>
                                                                <Col className="pr-1" md="3">
                                                                    <div className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                                                        <Grid className="Formfieldcss" container spacing={1}>
                                                                            <Grid item>
                                                                                <div className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                                                                    <Grid className="Formfieldcss" container spacing={1}>
                                                                                        <Grid item>
                                                                                            {/* <InputLabel id="demo-simple-select-outlined-label1">Date Range</InputLabel>   */}
                                                                                            <div className='control-pane'>
                                                                                                <div className='control-section'>
                                                                                                    <div className='datepicker-control-section'>
                                                                                                        <DateRangePickerComponent placeholder='Select a range' min={currentDate} onChange={(e) => this.datehandleChange1(e.target.value)} value={this.state.dateselected}>
                                                                                                        </DateRangePickerComponent>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </Grid>
                                                                                    </Grid>
                                                                                </div>
                                                                            </Grid>
                                                                        </Grid>

                                                                    </div>
                                                                </Col>
                                                                <Col className="pr-1" md="3">
                                                                    <div className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                                                        <Grid className="Formfieldcss" container spacing={1}>
                                                                            <Grid item>
                                                                                <TextField fullWidth
                                                                                    select
                                                                                    label="Select Schedule"
                                                                                    variant="outlined"
                                                                                    name="heater_schedule_id"
                                                                                    value={this.state.newAssignment.heater_schedule_id}
                                                                                    onChange={this.handleAssignEventInputChange}
                                                                                >
                                                                                    <MenuItem className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)} value='' >
                                                                                        Select Schedule
                                                                                    </MenuItem>
                                                                                    {this.allScheduleList.map(option => (
                                                                                        <MenuItem className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)} key={option.id} value={option.id} >
                                                                                            {option.schedule_name}
                                                                                        </MenuItem>
                                                                                    ))}
                                                                                </TextField>
                                                                            </Grid>
                                                                        </Grid>

                                                                    </div>
                                                                </Col>
                                                                <Col className="pr-1" md="3">
                                                                    <div className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                                                        <Grid className="Formfieldcss" container spacing={1}>
                                                                            <Grid item>
                                                                                <TextField fullWidth
                                                                                    select
                                                                                    label="Select Thermostat"
                                                                                    variant="outlined"
                                                                                    name="thermo_id"
                                                                                    value={this.state.newAssignment.thermo_id}
                                                                                    onChange={this.handleAssignEventInputChange}
                                                                                >
                                                                                    <MenuItem className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)} value='' >
                                                                                        Select Thermostat
                                                                                    </MenuItem>
                                                                                    {this.state.thermostatList.map(option => (
                                                                                        <MenuItem className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)} key={option.id} value={option.id} >
                                                                                            {option.thermo_desc}
                                                                                        </MenuItem>
                                                                                    ))}
                                                                                </TextField>
                                                                            </Grid>
                                                                        </Grid>

                                                                    </div>
                                                                </Col>
                                                                <Col className="pr-1" md="1.5">
                                                                    <div className="login-button changepassword text-center">
                                                                        <Button
                                                                            type="submit"
                                                                            variant="contained"
                                                                            color="primary"
                                                                            // disabled={invalid}
                                                                            onClick={this.addAssignment}
                                                                        >
                                                                            Assign
                                                                        </Button>
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                        </div>

                                                        <div className={"table-responsive " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                                            <CardHeader>
                                                                <CardTitle tag="h5" className="flexblock">
                                                                    History
                                                                </CardTitle>

                                                            </CardHeader>
                                                            <table id="Eventtable" className={"nbztable dataTable no-footer " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                                                <thead>
                                                                    <tr>
                                                                        <th style={{ width: '100px' }}>Actions</th>
                                                                        <th style={{ width: '200px' }}>Thermostat</th>
                                                                        <th style={{ width: '200px' }}>Schedule Name</th>
                                                                        <th style={{ width: '200px' }}>Start Date - End Date</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {this.allAssignList.map((event, index) => (
                                                                        <tr key={index}>
                                                                            <td>
                                                                                <a href="javascript:void(0)" onClick={() => this.assignmentDelete(event.id)}><i className="danger fa fa-trash icons"></i></a>
                                                                            </td>
                                                                            <td>{event.thermo_desc}</td>
                                                                            <td>{this.BindScheduleName(event.heater_schedule_id)}</td>
                                                                            <td>{Moment(event.from_date).format('MMM DD YYYY')} to {Moment(event.to_date).format('MMM DD YYYY')}</td>

                                                                        </tr>
                                                                    ))}
                                                                </tbody>
                                                            </table>
                                                        </div>


                                                    </div>
                                                </TabPane>
                                                <TabPane tabId="3">
                                                    <div>
                                                        {this.state.showcontent == true ?
                                                            <>
                                                                <Row>
                                                                    <Col md="12">
                                                                        <Card className={"card-user " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                                                            <CardHeader>
                                                                                <CardTitle tag="h5"></CardTitle>
                                                                            </CardHeader>
                                                                            <CardBody>
                                                                                <FieldGroup
                                                                                    control={this.thermostatForm}
                                                                                    strict={false}
                                                                                    render={({ get, invalid, value }) => (
                                                                                        <form>
                                                                                            <Row>
                                                                                                <Col className="pr-1" md="3">
                                                                                                    <FieldControl
                                                                                                        name="name"
                                                                                                        strict={false}
                                                                                                        render={({ handler, touched, hasError }) => (
                                                                                                            <div className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                                                                                                <Grid className={"Formfieldcss"} container spacing={1}>
                                                                                                                    <Grid item>
                                                                                                                        <TextField type="text" required fullWidth variant="outlined" error={touched && hasError("required")} label="Thermostat ID" {...handler("text")} />
                                                                                                                    </Grid>
                                                                                                                    <span className="error">
                                                                                                                        {touched
                                                                                                                            && hasError("required")
                                                                                                                            && `* Thermostat ID is required`}
                                                                                                                    </span>
                                                                                                                </Grid>
                                                                                                            </div>
                                                                                                        )}
                                                                                                    />
                                                                                                </Col>
                                                                                                <Col className="pr-1" md="3">
                                                                                                    <FieldControl
                                                                                                        name="desc"
                                                                                                        strict={false}
                                                                                                        render={({ handler, touched, hasError }) => (
                                                                                                            <div className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                                                                                                <Grid className="Formfieldcss" container spacing={1}>
                                                                                                                    <Grid item>
                                                                                                                        <TextField type="text" required fullWidth variant="outlined" error={touched && hasError("required")} label="Description" {...handler("text")} />
                                                                                                                    </Grid>
                                                                                                                    <span className="error">
                                                                                                                        {touched
                                                                                                                            && hasError("required")
                                                                                                                            && `* Description is required`}
                                                                                                                    </span>
                                                                                                                </Grid>
                                                                                                            </div>
                                                                                                        )}
                                                                                                    />
                                                                                                </Col>
                                                                                                <Col className="pr-1" md="3">
                                                                                                    <div className="login-button changepassword text-center">
                                                                                                        <Button
                                                                                                            type="submit"
                                                                                                            variant="contained"
                                                                                                            color="primary"
                                                                                                            disabled={invalid}
                                                                                                            onClick={(event) => this.handleSubmit(event, this.thermostatForm.getRawValue())}
                                                                                                        >
                                                                                                            {this.state.buttontext}
                                                                                                        </Button>
                                                                                                    </div>
                                                                                                </Col>
                                                                                                <Col className="pr-1" md="3">
                                                                                                    <div className="login-button changepassword text-center">
                                                                                                        <Button
                                                                                                            onClick={() => this.thermocancel()}
                                                                                                            variant="contained"
                                                                                                            color="danger"
                                                                                                        >
                                                                                                            Cancel
                                                                                                        </Button>
                                                                                                    </div>
                                                                                                </Col>
                                                                                            </Row>
                                                                                            <Row>
                                                                                                <Col className="pr-1" md="3">
                                                                                                </Col>

                                                                                                <Col className="pr-1" md="3">
                                                                                                </Col>
                                                                                            </Row>
                                                                                        </form>
                                                                                    )}
                                                                                />
                                                                            </CardBody>

                                                                        </Card>
                                                                    </Col>
                                                                </Row>
                                                                <Row>
                                                                    <Col md="12">
                                                                        <Card className={"card-user " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                                                            <CardHeader>
                                                                                <CardTitle tag="h5"></CardTitle>
                                                                            </CardHeader>
                                                                            <CardBody>
                                                                                <div className={"table-responsive " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                                                                    <table id="boardtable" className={"nbztable dataTable no-footer " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                                                                        <thead>
                                                                                            <tr>
                                                                                                <th style={{ width: '100px' }}>Actions</th>
                                                                                                <th style={{ width: '100px' }}>Heater Name</th>
                                                                                                <th style={{ width: '100px' }}>Description</th>
                                                                                                <th style={{ width: '200px' }}>Created / Updated at</th>

                                                                                            </tr>
                                                                                            {/* <tr>
                                            <th style={{width : '100px'}}>Actions</th>
                                            <th style={{width : '200px'}}>Board Name</th>
                                            <th style={{width : '200px'}}>IMEI</th>
                                            <th style={{width : '200px'}}>Expiry Date</th>
                                            <th style={{width : '100px'}}>Active</th>
                                            
                                        </tr> */}
                                                                                        </thead>
                                                                                        <tbody>
                                                                                            {this.state.thermostatList.map((value, index) => (
                                                                                                <tr key={index + 1}>
                                                                                                    {/* <td>{index+1}</td> */}
                                                                                                    <td>
                                                                                                        <a href="javascript:void(0)" onClick={(event) => this.thermostatdelete(event, value.id)}><i className="fa fa-trash icons"></i></a>&nbsp;&nbsp;&nbsp;&nbsp;
                                                                                                        <a href="javascript:void(0)" onClick={(e) => this.thermostatedit(value)}><i className="fa fa-edit icons"></i></a>
                                                                                                    </td>
                                                                                                    <td>{value.thermo_name}</td>
                                                                                                    <td>{value.thermo_desc}</td>

                                                                                                    <td>{Moment(value.updated_at).format('MMM DD YYYY HH:mm:ss')}</td>
                                                                                                    {/* <td>{value.board_name}</td>
                                            <td>{value.imei}</td>
                                            <td>{value.expiry_date}</td>
                                            <td>
                                                {value.active == true ? <span style={{color: 'green'}}><i className="fas fa-check"></i></span> : <span style={{color: 'red'}}><i className="fas fa-times"></i></span>}
                                            </td> */}

                                                                                                </tr>
                                                                                            ))}
                                                                                        </tbody>
                                                                                    </table>
                                                                                </div>
                                                                            </CardBody>
                                                                        </Card>
                                                                    </Col>
                                                                </Row>
                                                            </>
                                                            :
                                                            <Row>
                                                                <Col md="12">
                                                                    <Card className={"card-user " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                                                        <CardHeader>
                                                                        </CardHeader>
                                                                        <CardBody>
                                                                            <div style={{ width: "100%", height: "100px", fontSize: '22px', textAlign: 'center' }}>Please Choose atleast one Board to view the data ...</div>
                                                                        </CardBody>
                                                                    </Card>
                                                                </Col>
                                                            </Row>
                                                        }
                                                    </div>
                                                </TabPane>
                                                <TabPane tabId="4">
                                                    <div>
                                                        {this.state.showcontent == true ?
                                                            <>
                                                                <Row>
                                                                    <Col md="12">
                                                                        <Card className={"card-user " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                                                            <CardHeader>
                                                                            </CardHeader>
                                                                            <CardBody>
                                                                                <Row>
                                                                                    <Col className="pr-1" md="4">
                                                                                        <div className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                                                                            <Grid className="Formfieldcss" container spacing={1}>
                                                                                                <Grid item>
                                                                                                    <TextField fullWidth
                                                                                                        select
                                                                                                        label="Select Thermostat"
                                                                                                        variant="outlined"
                                                                                                        name="heater_thermo_id"
                                                                                                        value={this.state.heater_thermo_id}
                                                                                                        onChange={this.handleHeaterEventInputChange}
                                                                                                    >
                                                                                                        <MenuItem className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)} value='' >
                                                                                                            Select Thermostat
                                                                                                        </MenuItem>
                                                                                                        {this.state.thermostatList.map(option => (
                                                                                                            <MenuItem className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)} key={option.id} value={option.id} >
                                                                                                                {option.thermo_desc}
                                                                                                            </MenuItem>
                                                                                                        ))}
                                                                                                    </TextField>
                                                                                                </Grid>
                                                                                            </Grid>

                                                                                        </div>
                                                                                    </Col>
                                                                                    <Col className="pr-1" md="2">
                                                                                        <Button
                                                                                            type="submit"
                                                                                            variant="contained"
                                                                                            color="success"
                                                                                            onClick={(event) => this.handleSubmitControl(event, 1)}
                                                                                        >
                                                                                            ON
                                                                                        </Button>
                                                                                    </Col>
                                                                                    <Col className="pr-1" md="2">
                                                                                        <Button
                                                                                            type="submit"
                                                                                            variant="contained"
                                                                                            color="danger"
                                                                                            onClick={(event) => this.handleSubmitControl(event, 0)}
                                                                                        >
                                                                                            OFF
                                                                                        </Button>
                                                                                    </Col>
                                                                                    <Col className="pr-1" md="4"></Col>
                                                                                </Row>
                                                                            </CardBody>
                                                                        </Card>
                                                                    </Col>
                                                                </Row>
                                                            </>
                                                            :
                                                            <Row>
                                                                <Col md="12">
                                                                    <Card className={"card-user " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                                                        <CardHeader>
                                                                        </CardHeader>
                                                                        <CardBody>
                                                                            <div style={{ width: "100%", height: "100px", fontSize: '22px', textAlign: 'center' }}>Please Choose atleast one Board to view the data ...</div>
                                                                        </CardBody>
                                                                    </Card>
                                                                </Col>
                                                            </Row>
                                                        }
                                                    </div>
                                                </TabPane>
                                            </TabContent>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        </>
                        :
                        <Row>

                        </Row>
                    }
                </div>
            </>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        theme: state.theme,
        themecolor: state.themecolor,
        charttheme: state.charttheme
    }
}
export default connect(mapStateToProps)(HeaterScheduleComponent);

