import React from "react";
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Row,
    Col,
    TabContent, TabPane, Nav, NavItem, NavLink, CardText
} from "reactstrap";
import {
    FormBuilder,
    FieldGroup,
    FieldControl,
    Validators,
    FormArray,
    FieldArray,
    AbstractControl,
    FormGroup,
} from "react-reactive-form";
import classnames from 'classnames';
import Loader from '../services/loader';
import * as apiService from '../services/apiService';
import { connect } from 'react-redux';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Grid from '@material-ui/core/Grid';
import TopNavDropdown from './TopDropdown';
import { DateRangePickerComponent, PresetsDirective, PresetDirective } from '@syncfusion/ej2-react-calendars';
import swal from 'sweetalert';
import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import { chartPhase } from "../routes"
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
var AmCharts = require("@amcharts/amcharts3-react");
am4core.useTheme(am4themes_animated);
const $ = require('jquery');
const moment = require('moment');
$.DataTable = require('datatables.net');

export class SensorVisualization extends React.Component {
    constructor(props) {
        super();
        this.today = new Date(new Date().toDateString());
        this.weekStart = new Date(new Date(new Date().setDate(new Date().getDate() - (new Date().getDay() + 7) % 7)).toDateString());
        this.weekEnd = this.today;
        this.monthStart = new Date(new Date(new Date().setDate(1)).toDateString());
        this.monthEnd = this.today;
        this.lastStart = new Date(new Date(new Date(new Date().setMonth(new Date().getMonth() - 1)).setDate(1)).toDateString());
        this.lastStart2 = new Date(new Date(new Date(new Date().setMonth(new Date().getMonth() - 1)).setDate(30)).toDateString());
        this.lastEnd = this.today;
        this.yearStart = new Date(new Date(new Date().setDate(new Date().getDate() - 365)).toDateString());
        this.yearEnd = this.today;
        this.state = {
            loader: false,
            showcontent: false,
            activetab: '1',
            defaultrange: [this.today, this.today],
            operationlist: ["Facility_L103", "Facility_L104", "Facility_L015"],
            operationvalue: "Facility_L103",
            kwhdata: [],
            temprhtable: [],
            intervention_list: [],
            co2chart1series: [],
            co2chart2series: [],
            co2chart3series: [],
            temprhchart: [],
            backgroundcolor: 'white',
            textcolor: 'black',
            tempchart1series: [],
            tempchart2series: [],
            tempchart3series: [],
            pmEvent: {},
            highCo2: '',
            lowCo2: '',
            AvgCo2: '',
            hightvoc: '',
            lowtvoc: '',
            Avgtvoc: '',
            highpm10: '',
            lowpm10: '',
            Avgpm10: '',
            highpm25: '',
            lowpm25: '',
            Avgpm25: '',
            highpm1: '',
            lowpm1: '',
            Avgpm1: ''
        }

        this.highLowCo2handler = this.highLowCo2handler.bind(this);
        this.highLowtvochandler = this.highLowtvochandler.bind(this);
        this.highLowPMhandler = this.highLowPMhandler.bind(this);
        this.graphtype = 'line'
        this.selectedcustomer = ''
        this.selectedboard = ''
        this.airflowchartconfig1 = {}
        this.airflowchartconfig2 = {}
        this.rhchartconfig = {}
        this.co2chartconfig = {}
        this.pmchartconfig = {}
        this.tempchartconfig1 = {}
        this.tempchartconfig2 = {}
        this.occupancychartconfig = {}
        this.seriesdata = []
        this.seriesData2 = []
        this.co2var1 = [];
        this.co2var2 = [];
        this.co2var3 = [];
        this.pmvar1 = [];
        this.pmvar2 = [];
        this.pmvar3 = [];
    }

    Co2Form = FormBuilder.group({
        from_date: [new Date().getFullYear() + '-' + parseInt(new Date().getMonth() + 1) + '-' + new Date().getDate()],
        to_date: [new Date().getFullYear() + '-' + parseInt(new Date().getMonth() + 1) + '-' + new Date().getDate()],
    });
    
    interventionForm = FormBuilder.group({
        id: [""],
        intervention: ["", [Validators.required]],
        from_date: ["",[Validators.required]],
        to_date: ["",[Validators.required]],
        from_time: ['00:00',[Validators.required]],
        to_time: ['23:59',[Validators.required]],
        board_id:[localStorage.getItem('selectedboard')]
    });

    componentDidMount() {
    }
    interventionhandleSubmit(event, formvalues) {
        event.preventDefault();
        const firstDate = new Date(formvalues.from_date);
        const secondDate = new Date(formvalues.to_date);

        if (firstDate > secondDate) {
            apiService.Alerts_error("Start Date is greater than End Date")
            return;
        }
        formvalues.board_id = localStorage.getItem('selectedboard')
        formvalues.customer_id = localStorage.getItem('selectedcustomer')
        if (formvalues.id == '' || formvalues.id == undefined || formvalues.id == null) {
            if (formvalues.board_id == '' || formvalues.board_id == undefined || formvalues.board_id == null) {
                apiService.Alerts_info("Select board to Add Intervention")
            } else {
                this.setState({ loader: true })
                apiService.AddSensorInterventions(formvalues).then(response => {
                    this.setState({ loader: false })
                    apiService.Alerts("Intervention Added Successfully")
                    this.getinterventionlist(formvalues.board_id)
                    this.interventioncancel();
                }).catch(error => {
                    this.setState({ loader: false })
                    apiService.ErrorMessage(error)
                })
            }
        } else {
            if (formvalues.board_id == '' || formvalues.board_id == undefined || formvalues.board_id == null) {
                apiService.Alerts_info("Select board to Add Intervention")
            } else {
                this.setState({ loader: true })
                apiService.UpdateSensorInterventions(formvalues).then(response => {
                    this.setState({ loader: false })
                    apiService.Alerts("Intervention Updated Successfully")
                    this.getinterventionlist(localStorage.getItem('selectedboard'))
                    this.interventioncancel();
                }).catch(error => {
                    this.setState({ loader: false })
                    apiService.ErrorMessage(error)
                })
            }
        }
    }
    interventioncancel() {
        this.interventionForm.reset();
        this.interventionForm.patchValue({
            id: '',
            from_date: '',
            to_date: '',
            from_time: '',
            to_time: '',
            intervention: '',
            board_id:localStorage.getItem('selectedboard')
        })
        this.setState({
            buttontext: 'Add'
        })
    }
    interventionedit(values) {
        this.interventionForm.patchValue({
            id: values.id,
            from_date: values.from_date,
            to_date: values.to_date,
            from_time: values.from_time,
            to_time: values.to_time,
            intervention: values.intervention,
            board_id:values.board_id
        })
        this.setState({
            buttontext: 'Update'
        })
    }
    interventiondelete(event,value) {
        event.preventDefault();
        swal({
            title: "Are you sure?",
            text: "Once deleted, you will not be able to recover this Intervention!",
            icon: "warning",
            buttons: true,
            dangerMode: true,
          })
          .then((willDelete) => {
            if (willDelete) {
                event.preventDefault();
                this.setState({ loader: true })
                apiService.DeleteSensorInterventions(value).then(response => {
                    this.setState({ loader: false})
                    apiService.Alerts("Intervention Deleted Successfully")      
                    this.getinterventionlist(localStorage.getItem('selectedboard'))
                    this.interventioncancel();
                }).catch(error => {
                    this.setState({ loader: false })
                    apiService.ErrorMessage(error)
                })
            } else {
              // swal("Your file is safe!");
            }
        });
    }
    componentDidUpdate(prevProps) {
        if (prevProps.theme != this.props.theme||prevProps.selectedboard!=this.props.selectedboard) {
            this.toggle('1');
        }
    }

    getairflowchart1data(board, today) {
        const sy = today.getFullYear();
        const sm = today.getMonth() + 1;
        const sd = today.getDate();
        var dates = sy.toString() + '-' + sm.toString() + '-' + sd.toString();
        this.setState({ loader: true })
        apiService.NewDashboardrealtimev2(dates, dates, board, 'kWh').then(response => {
            this.setState({ loader: false, kwhdata: response.data.chart_data[0].kwh })
            this.createairflowchart1(response.data.chart_data[0].kwh, '', this.props.charttheme);
            this.createairflowchart2(response.data.chart_data[0].kwh, '', this.props.charttheme);
            this.createoccupancychart(response.data.chart_data[0].kwh, '', this.props.charttheme)
            this.createtempchart2(response.data.chart_data[0].kwh, '', this.props.charttheme)
        }).catch(error => {
            this.setState({ loader: false })
            apiService.ErrorMessage(error)
        })
    }

    convertdate(e, board_id) {
        const sy = e[0].getFullYear();
        const sm = e[0].getMonth() + 1;
        const sd = e[0].getDate();
        const ey = e[1].getFullYear();
        const em = e[1].getMonth() + 1;
        const ed = e[1].getDate();
        this.startdate = sy.toString() + '-' + sm.toString() + '-' + sd.toString();
        this.enddate = ey.toString() + '-' + em.toString() + '-' + ed.toString();
        const finaldate = [this.startdate, this.enddate]
        return finaldate
        // this.fetchdata(this.startdate,this.enddate,this.state.checkboxvalue,board_id,this.state.minutevalue);
    }

    convertdate1(e) {
        const sy = e.getFullYear();
        const sm = ("0" + (e.getMonth() + 1)).slice(-2);
        const sd = ("0" + e.getDate()).slice(-2);
        this.dailytoday = sy.toString() + '-' + sm.toString() + '-' + sd.toString();
        this.TempRHForm.patchValue({
            from_date: this.dailytoday,
            to_date: this.dailytoday,
        })
        this.TempRHLogForm.patchValue({
            from_date: this.dailytoday,
            to_date: this.dailytoday,
        })
    }

    datehandleChange(e) {
        this.setState({
            defaultrange: e.target.value
        })
        const finaldate = this.convertdate(e.target.value, localStorage.getItem('selectedboard'))
        console.log(finaldate)
        this.Co2Form.patchValue({
            from_date: finaldate[0],
            to_date: finaldate[1],
        })
    }

    toggle(option) {
        this.setState({
            activetab: option
        })
        if (option == '2') {
            this.getinterventionlist(localStorage.getItem('selectedboard'))
        } 
    }
    getinterventionlist(board_id) {
        if (board_id == '' || board_id == undefined || board_id == null) {
            apiService.Alerts_info("Choose Some Board")   
        } else {
            // if ($.fn.dataTable.isDataTable('#interventiontable')) {
            //     $('#interventiontable').DataTable().clear().destroy();
            // }  
            this.setState({ loader: true })
            apiService.SensorInterventionlist(board_id).then(response => {
                this.setState({intervention_list: response.data})
                // $(document).ready(function () {
                //     $('#interventiontable').DataTable({
                //       "bLengthChange": false,
                //       "pageLength": 5,
                //       "searching": true,
                //       "bInfo": true,
                //       "bSort": true,
                //       "bPaginate": true,
                //       "dom": 'Bfrtip',
                //       "buttons": []
                //     });
                // });
                this.setState({ loader: false })
            }).catch(error => {
                this.setState({ loader: false })
                apiService.ErrorMessage(error)
            })
        }
    }
    operationhandleChange(e) {
        e.preventDefault();
        this.sensorForm.patchValue({
            operation: e.target.value
        })
        this.setState({
            operationvalue: e.target.value
        })
        // this.show=false
    }

    handlesensorSubmit(event, values) {
        event.preventDefault()
        console.log(values);
        this.setState({ loader: true });
        values.board_id = localStorage.getItem('selectedboard');
        apiService.co2Visualizationchart(values).then(response => {
            this.setState({ loader: false, co2chart: response.data })
            this.createCo2chart(response.data[0]['co2_data'], '', this.props.charttheme)
        }).catch(error => {
            this.setState({ loader: false })
            apiService.ErrorMessage(error)
        })
        apiService.pmVisualizationchart(values).then(response => {
            this.setState({ loader: false, co2chart: response.data })
            this.createPMchart(response.data[1]['pm_data'], '', this.props.charttheme)
        }).catch(error => {
            this.setState({ loader: false })
            apiService.ErrorMessage(error)
        })
        apiService.tvocVisualizationchart(values).then(response => {
            this.setState({ loader: false, co2chart: response.data })
            this.createTvocchart(response.data[2]['tvoc'], '', this.props.charttheme)
        }).catch(error => {
            this.setState({ loader: false })
            apiService.ErrorMessage(error)
        })
    }

    createtempchart2(data, uom, theme) {
        for (let i = 0; i <= data.length - 1; i++) {
            if (data[i]['value'] <= 40) {
                data[i]['lineColor'] = '#00ff00'
            } else {
                data[i]['lineColor'] = '#ff0000'
            }
        }
        this.tempchartconfig2 = AmCharts.makeChart("tempchartdiv2", {
            "type": "serial",
            "theme": theme,
            "marginRight": 80,
            "dataProvider": data,
            "balloon": {
                "cornerRadius": 6,
                "horizontalPadding": 15,
                "verticalPadding": 10
            },
            'valueAxes': [{
                'position': 'left',
                'title': uom
            }],
            "graphs": [{
                "bullet": "round",
                "bulletBorderAlpha": 1,
                "bulletBorderThickness": 1,
                "fillAlphas": 0.0,
                "fillColorsField": "lineColor",
                "legendValueText": "[[value]]",
                "lineColorField": "lineColor",
                "valueField": "value"
            }],
            "chartScrollbar": {

            },
            'chartCursor': {
                'categoryBalloonDateFormat': 'JJ:NN, DD MMMM',
                'cursorPosition': 'mouse'
            },
            'categoryField': 'time',
            'categoryAxis': {
                'minPeriod': 'mm',
                'parseDates': true
            },
            "responsive": {
                "enabled": true
            },
        })
    }

    

    createco2chart(theme) {
        var data = []
        for (let i = 0; i < 250; i++) {
            data.push({ "day": i + 1, "value1": Math.floor(Math.random() * 31) + 20, "value2": Math.floor(Math.random() * 31) + 20 })
        }
        this.co2chartconfig = AmCharts.makeChart("co2chartdiv", {
            "type": "serial",
            "theme": theme,
            "dataProvider": data,
            "valueAxes": [{
                "gridColor": "#FFFFFF",
                "gridAlpha": 0.2,
                "dashLength": 0
            }],
            "gridAboveGraphs": true,
            "startDuration": 1,
            "graphs": [{
                "balloonText": "<b>[[value]]</b>",
                "lineAlpha": 0.0,
                "type": "step",
                "bullet": "circle",
                "valueField": "value1"
            },
            {
                "balloonText": "<b>[[value]]</b>",
                "lineAlpha": 0.0,
                "type": "step",
                "bullet": "circle",
                "valueField": "value2"
            }],
            "chartCursor": {
                "categoryBalloonEnabled": false,
                "cursorAlpha": 0,
                "zoomable": false
            },
            "categoryField": "day",
            "categoryAxis": {
                "gridPosition": "start",
                "gridAlpha": 0,
                "tickPosition": "start",
                "tickLength": 20
            },
            "export": {
                "enabled": true
            }

        })
    }

    createCo2chart(data, uom, theme) {
       // debugger;
        let chartdata1 = [];
        // let chartdata2 = [];
        if (theme == 'dark') {
            this.setState({
                backgroundcolor: 'transparent',
                textcolor: 'white'
            })
        } else {
            this.setState({
                backgroundcolor: 'white',
                textcolor: 'black'
            })
        }
        console.log(data)

        for (var i = 0; i <= data.length - 1; i++) {
            var time = new Date(data[i].datetime)
            var y = time.getUTCFullYear();
            var m = time.getUTCMonth();
            var d = time.getUTCDate();
            var h = time.getUTCHours();
            var min = time.getUTCMinutes();
            var s = time.getUTCSeconds();
            var tZ = new Date().getTimezoneOffset() / 30
            if (tZ === -17.5) {
                h -= -17;
                min -= -30;
            } else {
                h -= tZ;
            }
            var timestamp = new Date(y, m, d, h, min, s)

            var dat = new Date(timestamp);
            // console.log(data[0].time,y, m, d, h, min, s,dat)

            var final = dat.getTime();
            chartdata1.push([final, data[i].co2, data[i].board_id])
            // chartdata2.push([final, data[i].board_value])  
        }

        let var1 = chartdata1
        // let var2 = chartdata2
        this.co2var1 = chartdata1
        this.seriesData = [{
            name: 'Co₂',
            data: var1,
            type: this.graphtype,
            color: '#1e45db',
            fillOpacity: 1,
            threshold: null,
            // visible: true
        },

        ]
        this.setState({
            co2chart1series: this.seriesData,
            suffixCo2: 'ppm', prefix: ''
        })
    }

    createTvocchart(data, uom, theme) {
        let chartdata1 = [];
        // let chartdata2 = [];
        if (theme == 'dark') {
            this.setState({
                backgroundcolor: 'transparent',
                textcolor: 'white'
            })
        } else {
            this.setState({
                backgroundcolor: 'white',
                textcolor: 'black'
            })
        }
        console.log(data)

        for (var i = 0; i <= data.length - 1; i++) {
            var time = new Date(data[i].datetime)
            var y = time.getUTCFullYear();
            var m = time.getUTCMonth();
            var d = time.getUTCDate();
            var h = time.getUTCHours();
            var min = time.getUTCMinutes();
            var s = time.getUTCSeconds();
            var tZ = new Date().getTimezoneOffset() / 30
            if (tZ === -17.5) {
                h -= -17;
                min -= -30;
            } else {
                h -= tZ;
            }
            var timestamp = new Date(y, m, d, h, min, s)

            var dat = new Date(timestamp);
            // console.log(data[0].time,y, m, d, h, min, s,dat)

            var final = dat.getTime();
            chartdata1.push([final, data[i].tvoc, data[i].board_id])
            // chartdata2.push([final, data[i].board_value])  
        }

        let var1 = chartdata1
        // let var2 = chartdata2
        this.co2var2 = var1
        this.seriesTvocData = [{
            name: 'TVOC',
            data: var1,
            type: this.graphtype,
            color: '#07520a',
            fillOpacity: 1,
            threshold: null,
            // visible: true
        },
            // {
            // 	name: 'Current consumed (kWh)',
            // 	data: var2,
            // 	type: this.graphtype,
            // 	color: '#002DB3',
            // 	fillOpacity: 1,
            // 	// threshold: null,
            // 	// showInNavigator: true,
            //     // visible: false,

            //   }
        ]
        this.setState({
            co2chart3series: this.seriesTvocData,
            suffixtvoc: 'mg/m³', prefix: ''
        })

        // this.co2Data = [{
        //     name: 'Co2',
        //     data,
        //     type: 'line',
        //     color: '#ff0000',
        //     fillOpacity: 1,
        //     threshold: null,
        //     tooltip: {
        //         valueSuffix: '%RH'
        //     }
        // }]
    }
    createPMchart(data, uom, theme) {
        let chartdata1 = [];
        let chartdata2 = [];
        let chartdata3 = [];
        if (theme == 'dark') {
            this.setState({
                backgroundcolor: 'transparent',
                textcolor: 'white'
            })
        } else {
            this.setState({
                backgroundcolor: 'white',
                textcolor: 'black'
            })
        }
        console.log(data)

        for (var i = 0; i <= data.length - 1; i++) {
            var time = new Date(data[i].datetime)
            var y = time.getUTCFullYear();
            var m = time.getUTCMonth();
            var d = time.getUTCDate();
            var h = time.getUTCHours();
            var min = time.getUTCMinutes();
            var s = time.getUTCSeconds();
            var tZ = new Date().getTimezoneOffset() / 30
            if (tZ === -17.5) {
                h -= -17;
                min -= -30;
            } else {
                h -= tZ;
            }
            var timestamp = new Date(y, m, d, h, min, s)

            var dat = new Date(timestamp);
            // console.log(data[0].time,y, m, d, h, min, s,dat)

            var final = dat.getTime();
            // chartdata1.push({"Date" : data[i].datetime, "PM10": data[i].pm10, "PM25": data[i].pm25, "PM1": data[i].pm1})  
            chartdata1.push([final, data[i].pm10])
            chartdata2.push([final, data[i].pm25])
            chartdata3.push([final, data[i].pm1])
        }

        let var1 = chartdata1
        let var2 = chartdata2
        let var3 = chartdata3
        this.pmvar1 = var1
        this.pmvar2 = var2
        this.pmvar3 = var3

        this.seriesData3 = [{
            name: 'PM₁₀',
            data: var1,
            type: this.graphtype,
            color: '#f44336',
            fillOpacity: 1,
            threshold: null
        }, {
            name: 'PM₂.₅',
            data: var2,
            type: this.graphtype,
            threshold: null,
            color: '#2196f3',
            fillOpacity: 1

        }, {
            name: 'PM₁',
            data: var3,
            type: this.graphtype,
            threshold: null,
            color: '#ffc107',
            fillOpacity: 1,

        }]


        // {
        // 	name: 'Current consumed (kWh)',
        // 	data: var2,
        // 	type: this.graphtype,
        // 	color: '#002DB3',
        // 	fillOpacity: 1,
        // 	// threshold: null,
        // 	// showInNavigator: true,
        //     // visible: false,

        //   }
        this.setState({
            co2chart2series: this.seriesData3,
            suffixPM: 'µg/m³', prefix: ''
        })

        // this.co2Data = [{
        //     name: 'Co2',
        //     data,
        //     type: 'line',
        //     color: '#ff0000',
        //     fillOpacity: 1,
        //     threshold: null,
        //     tooltip: {
        //         valueSuffix: '%RH'
        //     }
        // }]
        this.newtest(this.state.pmEvent, chartdata1, "pm10");
        this.newtest(this.state.pmEvent, chartdata2, "pm25");
        this.newtest(this.state.pmEvent, chartdata3, "pm1");
    }
    date_conversion(date) {
        var x = date.split(" ")
        var z = "--"
        if (Array.isArray(x) && x.length == 2) {
            var y = moment(new Date(x[0])).format('MMM Do,YYYY')
            var timeString = x[1];
            var H = +timeString.substr(0, 2);
            var h = H % 12 || 12;
            var ampm = (H < 12 || H === 24) ? "AM" : "PM";
            timeString = h + timeString.substr(2, 3) + ampm;
            z = y + " at " + timeString

        }
        return z
    }

    

    highLowCo2handler(event) {
        this.newtest(event, this.co2var1, "co2")
    }

    highLowtvochandler(event) {
        this.newtest(event, this.co2var2, "tvoc")
    }

    highLowPMhandler(event) {
        this.setState({ pmEvent: event })
        this.newtest(event, this.pmvar1, "pm10");
        this.newtest(event, this.pmvar2, "pm25");
        this.newtest(event, this.pmvar3, "pm1");
    }


    newtest(event, co2var, charttype) {
        console.log('load');
        var timearray1 = []
        var timearray2 = []
        var timearray3 = []
        var array = []
        var max1 = null
        var min1 = null
        var sum = 0
        var x = 0
        var max = event.max;
        var min = event.min;

        if (event.target.series[0] && event.target.series[0]['xData']) {

            console.log(event.target.series[0]['xData'].length);
            if (event.target.series[0]['xData'].length > 0) {
                for (let i = 0; i <= event.target.series[0]['xData'].length - 1; i++) {
                    timearray1.push([event.target.series[0]['xData'][i], event.target.series[0]['yData'][i]])
                }
                co2var.forEach(row => {
                    if (row[0] > min) {
                        x = 1
                    }
                    if (row[0] > max) {
                        x = 0
                    }
                    if (x == 1) {
                        array.push(row)
                    }
                });
                if (array.length == 0) {
                    max1 = 0
                    min1 = 0
                    sum = 0
                    var average1 = 0
                    var obj = {
                        max: max1,
                        min: min1,
                        sum: sum,
                        average: average1
                    }
                } else {
                    max1 = Math.max.apply(Math, array.map(function (o) { return o[1]; }))
                    min1 = Math.min.apply(Math, array.map(function (o) { return o[1]; }))
                    sum = 0
                    array.forEach((key) => {
                        sum = sum + key[1]
                    })

                    var finalsum = 0
                    var average1
                    if (false) {
                        finalsum = array[array.length - 1][2] - array[0][2]
                        var l = array[array.length - 1][0] - array[0][0]
                        var diff = l / 3600000;
                        if (finalsum > 0) {
                            average1 = finalsum / diff

                        } else {
                            average1 = 0
                        }
                    } else {
                        finalsum = sum
                        average1 = sum / (array.length)

                    }

                    var obj = {
                        max: max1,
                        min: min1,
                        sum: finalsum,
                        average: average1
                    }
                }
                console.log("newtest -->", obj);
                if (charttype === "co2") {
                    this.setState({
                        highCo2: max1.toFixed(2),
                        lowCo2: min1.toFixed(2),
                        AvgCo2: average1.toFixed(2)
                    })
                }
                if (charttype === "tvoc") {
                    this.setState({
                        hightvoc: max1.toFixed(2),
                        lowtvoc: min1.toFixed(2),
                        Avgtvoc: average1.toFixed(2)
                    })
                }
                if (charttype === "pm10") {
                    this.setState({
                        highpm10: max1.toFixed(2),
                        lowpm10: min1.toFixed(2),
                        Avgpm10: average1.toFixed(2)
                    })
                }
                if (charttype === "pm25") {
                    this.setState({
                        highpm25: max1.toFixed(2),
                        lowpm25: min1.toFixed(2),
                        Avgpm25: average1.toFixed(2)
                    })
                }
                if (charttype === "pm1") {
                    this.setState({
                        highpm1: max1.toFixed(2),
                        lowpm1: min1.toFixed(2),
                        Avgpm1: average1.toFixed(2)
                    })
                }
            }
        }

    }

    render() {
        var tempchartconfig1 = {
            chart: {
                renderTo: 'tempchartdiv1',
                backgroundColor: this.state.backgroundcolor,
                zoomType: 'x',
                style: {
                    fontFamily: 'Montserrat',
                    fontSize: '18px',
                    color: this.state.textcolor,
                    fontWeight: 'bold',
                },
            },
            exporting: { enabled: false },
            credits: {
                enabled: false
            },
            rangeSelector: {
                allButtonsEnabled: true,
                inputEnabled: false,
                buttons: [{
                    type: 'all',
                    text: 'Reset',
                }],
                buttonTheme: {
                    width: 80,
                    style: {
                        fontSize: '13px',
                        fontWeight: '400',

                    }
                },
                selected: 0,
            },
            legend: {
                enabled: true,
                itemStyle: {
                    fontFamily: 'Montserrat',
                    color: this.state.textcolor,
                },
            },
            xAxis: {
                title: {
                    enabled: true,
                    text: 'Date / Time',
                    style: {
                        fontFamily: 'Montserrat',
                        fontSize: '12px',
                        color: this.state.textcolor,
                        fontWeight: 'bold',
                    }
                },
                labels: {
                    style: {
                        fontFamily: 'Montserrat',
                        color: this.state.textcolor,
                    }
                },
            },
            yAxis: {
                opposite: false,
                labels: {
                    style: {
                        fontFamily: 'Montserrat',
                        color: this.state.textcolor,
                    }
                },
                title: {
                    text: 'Relative Humidity %RH',
                    style: {
                        fontFamily: 'Montserrat',
                        fontSize: '14px',
                        fontWeight: 'bold',
                        color: this.state.textcolor,
                    },
                },
            },
            tooltip: {
                valueDecimals: 2,
                split: false,
                shared: true
            },
            plotOptions: {
                series: {
                    connectNulls: true
                }
            },
            series: this.state.tempchart1series
        }

        const PMchartconfig = {
            chart: {
                renderTo: 'PMchartdiv',
                backgroundColor: this.state.backgroundcolor,
                zoomType: 'x',
                style: {
                    fontFamily: 'Montserrat',
                    fontSize: '18px',
                    color: this.state.textcolor,
                    fontWeight: 'bold',
                },
            },
            exporting: { enabled: false },
            credits: {
                enabled: false
            },
            rangeSelector: {
                allButtonsEnabled: true,
                inputEnabled: false,
                buttons: [{
                    type: 'all',
                    text: 'Reset',
                }],
                buttonTheme: {
                    width: 80,
                    style: {
                        fontSize: '13px',
                        fontWeight: '400',

                    }
                },
                selected: 0,
            },

            legend: {
                enabled: true,
                // useHTML : true,
                // borderWidth: 0,
                labelFormatter: function () {
                    return this.name + ' (µg/m³)';
                },
                //  enabled: true,
                itemStyle: {
                    fontFamily: 'Montserrat',
                    color: this.state.textcolor,
                },
            },
            xAxis: {
                title: {
                    enabled: true,
                    text: 'Date / Time',
                    style: {
                        fontFamily: 'Montserrat',
                        fontSize: '12px',
                        color: this.state.textcolor,
                        fontWeight: 'bold',
                    }
                },
                labels: {
                    style: {
                        fontFamily: 'Montserrat',
                        color: this.state.textcolor,
                    }
                },
                events: {
                    afterSetExtremes: this.highLowPMhandler,
                    // setExtremes: this.newtest
                }
            },
            yAxis: {
                opposite: false,
                min: 0,
                labels: {
                    style: {
                        fontFamily: 'Montserrat',
                        color: this.state.textcolor,
                    }
                },
                title: {
                    text: "µg/m³",
                    style: {
                        fontFamily: 'Montserrat',
                        fontSize: '14px',
                        fontWeight: 'bold',
                        color: this.state.textcolor,
                    },
                },
            },
            tooltip: {
                headerFormat: '<span style="font-size: 14px">{point.key}</span><br/>',
                valuePrefix: this.state.prefix + ' ',
                valueSuffix: ' ' + this.state.suffixPM,
                valueDecimals: 2,
                split: false,
                shared: true,
                style: { fontSize: '14px' }
            },
            series: this.state.co2chart2series
        };

        var tempchartconfig2 = {
            chart: {
                renderTo: 'tempchartdiv2',
                backgroundColor: this.state.backgroundcolor,
                zoomType: 'x',
                style: {
                    fontFamily: 'Montserrat',
                    fontSize: '18px',
                    color: this.state.textcolor,
                    fontWeight: 'bold',
                },

            },
            exporting: { enabled: false },
            credits: {
                enabled: false
            },
            rangeSelector: {
                allButtonsEnabled: true,
                inputEnabled: false,
                buttons: [{
                    type: 'all',
                    text: 'Reset',
                }],
                buttonTheme: {
                    width: 80,
                    style: {
                        fontSize: '13px',
                        fontWeight: '400',

                    }
                },
                selected: 0,
            },
            legend: {
                enabled: true,
                itemStyle: {
                    fontFamily: 'Montserrat',
                    color: this.state.textcolor,
                },
            },
            xAxis: {
                title: {
                    enabled: true,
                    text: 'Date / Time',
                    style: {
                        fontFamily: 'Montserrat',
                        fontSize: '12px',
                        color: this.state.textcolor,
                        fontWeight: 'bold',
                    }
                },
                labels: {
                    style: {
                        fontFamily: 'Montserrat',
                        color: this.state.textcolor,
                    }
                },
            },
            yAxis: {
                opposite: false,
                labels: {
                    style: {
                        fontFamily: 'Montserrat',
                        color: this.state.textcolor,
                    }
                },
                title: {
                    text: 'Temperature °C',
                    style: {
                        fontFamily: 'Montserrat',
                        fontSize: '14px',
                        fontWeight: 'bold',
                        color: this.state.textcolor,
                    },
                },
            },
            tooltip: {
                valueDecimals: 2,
                split: false,
                shared: true
            },
            plotOptions: {
                series: {
                    connectNulls: true
                }
            },
            series: this.state.tempchart2series
        }

        var co2Config = {
            chart: {
                renderTo: 'co2pmchartdiv',
                backgroundColor: this.state.backgroundcolor,
                zoomType: 'x',
                style: {
                    fontFamily: 'Montserrat',
                    fontSize: '18px',
                    color: this.state.textcolor,
                    fontWeight: 'bold',
                },

            },
            exporting: { enabled: false },
            credits: {
                enabled: false
            },
            rangeSelector: {
                allButtonsEnabled: true,
                inputEnabled: false,
                buttons: [{
                    type: 'all',
                    text: 'Reset',
                }],
                buttonTheme: {
                    width: 80,
                    style: {
                        fontSize: '13px',
                        fontWeight: '400',

                    }
                },
                selected: 0,
            },
            legend: {
                enabled: true,
                labelFormatter: function () {
                    return this.name + '(ppm)';
                },
                itemStyle: {
                    fontFamily: 'Montserrat',
                    color: this.state.textcolor,
                },
            },
            xAxis: {
                title: {
                    enabled: true,
                    text: 'Date / Time',
                    style: {
                        fontFamily: 'Montserrat',
                        fontSize: '12px',
                        color: this.state.textcolor,
                        fontWeight: 'bold',
                    }
                },
                labels: {
                    style: {
                        fontFamily: 'Montserrat',
                        color: this.state.textcolor,
                    }
                },
                events: {
                    // afterSetExtremes: function (event) {
                    //   let min = event.min;
                    //   let max = event.max;
                    //   this.setState({highCo2: max, lowCo2: min, AvgCo2: (max+min/)})
                    //   var testObj={
                    //     max:max,
                    //     min:min
                    //   }
                    //   var obj1=JSON.stringify(testObj)
                    //   sessionStorage.setItem('bottomTableMaxMin',obj1)
                    // },
                    afterSetExtremes: this.highLowCo2handler,
                    // setExtremes: this.newtest
                }
            },
            yAxis: {
                opposite: false,
                min: 0,
                labels: {
                    style: {
                        fontFamily: 'Montserrat',
                        color: this.state.textcolor,
                    }
                },
                title: {
                    text: 'ppm',
                    style: {
                        fontFamily: 'Montserrat',
                        fontSize: '14px',
                        fontWeight: 'bold',
                        color: this.state.textcolor,
                    },
                },
            },
            tooltip: {
                headerFormat: '<span style="font-size: 14px">{point.key}</span><br/>',
                valuePrefix: this.state.prefix + ' ',
                valueSuffix: ' ' + this.state.suffixCo2,
                valueDecimals: 2,
                split: false,
                shared: true,
                style: { fontSize: '14px' }
            },
            series: this.state.co2chart1series
        }

        var tvocConfig = {
            chart: {
                renderTo: 'tvocchartdiv',
                backgroundColor: this.state.backgroundcolor,
                zoomType: 'x',
                style: {
                    fontFamily: 'Montserrat',
                    fontSize: '18px',
                    color: this.state.textcolor,
                    fontWeight: 'bold',
                },

            },
            exporting: { enabled: false },
            credits: {
                enabled: false
            },
            rangeSelector: {
                allButtonsEnabled: true,
                inputEnabled: false,
                buttons: [{
                    type: 'all',
                    text: 'Reset',
                }],
                buttonTheme: {
                    width: 80,
                    style: {
                        fontSize: '13px',
                        fontWeight: '400',

                    }
                },
                selected: 0,
            },
            legend: {
                enabled: true,
                labelFormatter: function () {
                    return this.name + ' (mg/m³)';
                },
                itemStyle: {
                    fontFamily: 'Montserrat',
                    color: this.state.textcolor,
                },
            },
            xAxis: {
                title: {
                    enabled: true,
                    text: 'Date / Time',
                    style: {
                        fontFamily: 'Montserrat',
                        fontSize: '12px',
                        color: this.state.textcolor,
                        fontWeight: 'bold',
                    }
                },
                labels: {
                    style: {
                        fontFamily: 'Montserrat',
                        color: this.state.textcolor,
                    }
                },
                events: {
                    afterSetExtremes: this.highLowtvochandler,
                    // setExtremes: this.newtest
                }
            },
            yAxis: {
                opposite: false,
                min: 0,
                labels: {
                    style: {
                        fontFamily: 'Montserrat',
                        color: this.state.textcolor,
                    }
                },
                title: {
                    text: 'mg/m³',
                    style: {
                        fontFamily: 'Montserrat',
                        fontSize: '14px',
                        fontWeight: 'bold',
                        color: this.state.textcolor,
                    },
                },
            },
            tooltip: {
                headerFormat: '<span style="font-size: 14px">{point.key}</span><br/>',
                valuePrefix: this.state.prefix + ' ',
                valueSuffix: ' ' + this.state.suffixtvoc,
                valueDecimals: 2,
                split: false,
                shared: true,
                style: { fontSize: '14px' }
            },
            series: this.state.co2chart3series
        }


        return (
            <>
                <div className="content">
                    {
                        this.state.loader && <Loader />
                    }
                    <>
                        <Row>
                            <Col md="12">
                                <Card className={"card-user " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                    <CardHeader>
                                        <CardTitle tag="h5">
                                            <Nav tabs>

                                                <NavItem>
                                                    <NavLink
                                                        className={classnames({ active: this.state.activetab === '1' }) + ' ' + (this.props.theme) + ' ' + (this.props.themecolor)}
                                                        onClick={() => { this.toggle('1'); }}
                                                    >
                                                        Display
                                                    </NavLink>
                                                </NavItem>
                                                <NavItem>
                                                    <NavLink
                                                        className={classnames({ active: this.state.activetab === '2' }) + ' ' + (this.props.theme) + ' ' + (this.props.themecolor)}
                                                        onClick={() => { this.toggle('2'); }}
                                                    >
                                                        Intervention
                                                    </NavLink>
                                                </NavItem>
                                            </Nav>
                                        </CardTitle>
                                    </CardHeader>
                                    <CardBody>
                                        <TabContent activeTab={this.state.activetab}>
                                            <TabPane tabId="1">
                                                <FieldGroup
                                                    control={this.Co2Form}
                                                    strict={false}
                                                    render={({ get, invalid, value }) => (
                                                        <form>
                                                            <Row>
                                                                <Col className="pr-1" md="4">
                                                                    <FieldControl
                                                                        name="datarange"
                                                                        strict={false}
                                                                        render={({ handler, touched, hasError }) => (
                                                                            <div className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                                                                <Grid className="Formfieldcss" container spacing={1}>
                                                                                    <Grid item>
                                                                                        <InputLabel id="demo-simple-select-outlined-label1">Date Range</InputLabel>
                                                                                        <div className='control-pane'>
                                                                                            <div className='control-section'>
                                                                                                <div className='datepicker-control-section'>
                                                                                                    <DateRangePickerComponent placeholder='Select a range' onChange={(e) => this.datehandleChange(e)} value={this.state.defaultrange}>
                                                                                                        <PresetsDirective>
                                                                                                            <PresetDirective label="Today" start={this.today} end={this.today}></PresetDirective>
                                                                                                            <PresetDirective label="This Week" start={this.weekStart} end={this.weekEnd}></PresetDirective>
                                                                                                            <PresetDirective label="This Month" start={this.monthStart} end={this.monthEnd}></PresetDirective>
                                                                                                            <PresetDirective label="Last Month" start={this.lastStart} end={this.lastEnd}></PresetDirective>
                                                                                                            <PresetDirective label="Last Year" start={this.yearStart} end={this.yearEnd}></PresetDirective>
                                                                                                        </PresetsDirective>
                                                                                                    </DateRangePickerComponent>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </Grid>
                                                                                </Grid>
                                                                            </div>
                                                                        )}
                                                                    />
                                                                </Col>
                                                                
                                                                <Col className="pr-1 mt-3" md="4">
                                                                    <div className="login-button changepassword text-center">
                                                                        <Button
                                                                            type="submit"
                                                                            variant="contained"
                                                                            color="primary"
                                                                            disabled={invalid}
                                                                            onClick={(event) => this.handlesensorSubmit(event, this.Co2Form.getRawValue())}
                                                                        >
                                                                            Submit
                                                                        </Button>
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                        </form>
                                                    )}
                                                />
                                                <hr />
                                                <div style={{ width: "100%" }}>
                                                    <h5>CO2 Chart</h5>
                                                    <div style={{ width: "100%" }}>
                                                        <HighchartsReact
                                                            style={{ width: "100%" }}
                                                            highcharts={Highcharts}
                                                            constructorType={'stockChart'}
                                                            options={co2Config}
                                                            containerProps={{ className: 'chartContainer' }}
                                                            id="co2pmchartdiv"
                                                        />
                                                    </div>
                                                    <Row>
                                                        <Col lg="4" md="6" sm="6">
                                                            <Card className={"card-stats " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                                                <CardBody>
                                                                    <Row>
                                                                        <Col md="3" xs="4">
                                                                            <div className="icon-big text-center icon-danger">
                                                                                <i className="fas fa-arrow-up text-danger" />
                                                                            </div>
                                                                        </Col>
                                                                        <Col md="9" xs="8">
                                                                            <div className="numbers text-center">
                                                                                <p className="card-category newsize">CO2 - High</p>
                                                                                <CardTitle tag="p" className="newsizetext">{this.state.highCo2} ppm</CardTitle>
                                                                                <p />
                                                                            </div>
                                                                        </Col>
                                                                    </Row>
                                                                </CardBody>
                                                            </Card>
                                                        </Col>
                                                        <Col lg="4" md="6" sm="6">
                                                            <Card className={"card-stats " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                                                <CardBody>
                                                                    <Row>
                                                                        <Col md="3" xs="4">
                                                                            <div className="icon-big text-center icon-info">
                                                                                <i className="fas fa-arrow-down text-info" />
                                                                            </div>
                                                                        </Col>
                                                                        <Col md="9" xs="8">
                                                                            <div className="numbers text-center">
                                                                                <p className="card-category newsize">CO2 - Low</p>
                                                                                <CardTitle tag="p" className="newsizetext">{this.state.lowCo2} ppm</CardTitle>
                                                                                <p />
                                                                            </div>
                                                                        </Col>
                                                                    </Row>
                                                                </CardBody>
                                                            </Card>
                                                        </Col>
                                                        <Col lg="4" md="6" sm="6">
                                                            <Card className={"card-stats " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                                                <CardBody>
                                                                    <Row>
                                                                        <Col md="3" xs="4">
                                                                            <div className="icon-big text-center icon-warning">
                                                                                <i className="fas fa-bars text-warning" />
                                                                            </div>
                                                                        </Col>
                                                                        <Col md="9" xs="8">
                                                                            <div className="numbers text-center">
                                                                                <p className="card-category newsize">CO2 - Average</p>
                                                                                <CardTitle tag="p" className="newsizetext">{this.state.AvgCo2} ppm</CardTitle>
                                                                                <p />
                                                                            </div>
                                                                        </Col>
                                                                    </Row>
                                                                </CardBody>
                                                            </Card>
                                                        </Col>
                                                    </Row>
                                                    <div style={{ width: "100%" }}>
                                                        <h5>PM Chart</h5>
                                                        <HighchartsReact
                                                            highcharts={Highcharts}
                                                            constructorType={'stockChart'}
                                                            options={PMchartconfig}
                                                            containerProps={{ className: 'chartContainer' }}
                                                            id="PMchartdiv"
                                                        />
                                                    </div>
                                                    <Row>
                                                        <Col lg="4" md="6" sm="6">
                                                            <Card className={"card-stats " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                                                <CardBody>
                                                                    <Row>
                                                                        <Col md="3" xs="4">
                                                                            <div className="icon-big text-center icon-danger">
                                                                                <i className="fas fa-arrow-up text-danger" />
                                                                            </div>
                                                                        </Col>
                                                                        <Col md="9" xs="8">
                                                                            <div className="numbers text-center">
                                                                                <p className="card-category newsize">PM10 - High</p>
                                                                                <CardTitle tag="p" className="newsizetext">{this.state.highpm10} µg/m³</CardTitle>
                                                                                <p />
                                                                            </div>
                                                                        </Col>
                                                                    </Row>
                                                                </CardBody>
                                                            </Card>
                                                        </Col>
                                                        <Col lg="4" md="6" sm="6">
                                                            <Card className={"card-stats " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                                                <CardBody>
                                                                    <Row>
                                                                        <Col md="3" xs="4">
                                                                            <div className="icon-big text-center icon-info">
                                                                                <i className="fas fa-arrow-down text-info" />
                                                                            </div>
                                                                        </Col>
                                                                        <Col md="9" xs="8">
                                                                            <div className="numbers text-center">
                                                                                <p className="card-category newsize">PM10 - Low</p>
                                                                                <CardTitle tag="p" className="newsizetext">{this.state.lowpm10} µg/m³</CardTitle>
                                                                                <p />
                                                                            </div>
                                                                        </Col>
                                                                    </Row>
                                                                </CardBody>
                                                            </Card>
                                                        </Col>
                                                        <Col lg="4" md="6" sm="6">
                                                            <Card className={"card-stats " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                                                <CardBody>
                                                                    <Row>
                                                                        <Col md="3" xs="4">
                                                                            <div className="icon-big text-center icon-warning">
                                                                                <i className="fas fa-bars text-warning" />
                                                                            </div>
                                                                        </Col>
                                                                        <Col md="9" xs="8">
                                                                            <div className="numbers text-center">
                                                                                <p className="card-category newsize">PM10 - Average</p>
                                                                                <CardTitle tag="p" className="newsizetext">{this.state.Avgpm10} µg/m³</CardTitle>
                                                                                <p />
                                                                            </div>
                                                                        </Col>
                                                                    </Row>
                                                                </CardBody>
                                                            </Card>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col lg="4" md="6" sm="6">
                                                            <Card className={"card-stats " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                                                <CardBody>
                                                                    <Row>
                                                                        <Col md="3" xs="4">
                                                                            <div className="icon-big text-center icon-danger">
                                                                                <i className="fas fa-arrow-up text-danger" />
                                                                            </div>
                                                                        </Col>
                                                                        <Col md="9" xs="8">
                                                                            <div className="numbers text-center">
                                                                                <p className="card-category newsize">PM2.5 - High</p>
                                                                                <CardTitle tag="p" className="newsizetext">{this.state.highpm25} µg/m³</CardTitle>
                                                                                <p />
                                                                            </div>
                                                                        </Col>
                                                                    </Row>
                                                                </CardBody>
                                                            </Card>
                                                        </Col>
                                                        <Col lg="4" md="6" sm="6">
                                                            <Card className={"card-stats " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                                                <CardBody>
                                                                    <Row>
                                                                        <Col md="3" xs="4">
                                                                            <div className="icon-big text-center icon-info">
                                                                                <i className="fas fa-arrow-down text-info" />
                                                                            </div>
                                                                        </Col>
                                                                        <Col md="9" xs="8">
                                                                            <div className="numbers text-center">
                                                                                <p className="card-category newsize">PM2.5 - Low</p>
                                                                                <CardTitle tag="p" className="newsizetext">{this.state.lowpm25} µg/m³</CardTitle>
                                                                                <p />
                                                                            </div>
                                                                        </Col>
                                                                    </Row>
                                                                </CardBody>
                                                            </Card>
                                                        </Col>
                                                        <Col lg="4" md="6" sm="6">
                                                            <Card className={"card-stats " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                                                <CardBody>
                                                                    <Row>
                                                                        <Col md="3" xs="4">
                                                                            <div className="icon-big text-center icon-warning">
                                                                                <i className="fas fa-bars text-warning" />
                                                                            </div>
                                                                        </Col>
                                                                        <Col md="9" xs="8">
                                                                            <div className="numbers text-center">
                                                                                <p className="card-category newsize">PM2.5 - Average</p>
                                                                                <CardTitle tag="p" className="newsizetext">{this.state.Avgpm25} µg/m³</CardTitle>
                                                                                <p />
                                                                            </div>
                                                                        </Col>
                                                                    </Row>
                                                                </CardBody>
                                                            </Card>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col lg="4" md="6" sm="6">
                                                            <Card className={"card-stats " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                                                <CardBody>
                                                                    <Row>
                                                                        <Col md="3" xs="4">
                                                                            <div className="icon-big text-center icon-danger">
                                                                                <i className="fas fa-arrow-up text-danger" />
                                                                            </div>
                                                                        </Col>
                                                                        <Col md="9" xs="8">
                                                                            <div className="numbers text-center">
                                                                                <p className="card-category newsize">PM1 - High</p>
                                                                                <CardTitle tag="p" className="newsizetext">{this.state.highpm1} µg/m³</CardTitle>
                                                                                <p />
                                                                            </div>
                                                                        </Col>
                                                                    </Row>
                                                                </CardBody>
                                                            </Card>
                                                        </Col>
                                                        <Col lg="4" md="6" sm="6">
                                                            <Card className={"card-stats " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                                                <CardBody>
                                                                    <Row>
                                                                        <Col md="3" xs="4">
                                                                            <div className="icon-big text-center icon-info">
                                                                                <i className="fas fa-arrow-down text-info" />
                                                                            </div>
                                                                        </Col>
                                                                        <Col md="9" xs="8">
                                                                            <div className="numbers text-center">
                                                                                <p className="card-category newsize">PM1 - Low</p>
                                                                                <CardTitle tag="p" className="newsizetext">{this.state.lowpm1} µg/m³</CardTitle>
                                                                                <p />
                                                                            </div>
                                                                        </Col>
                                                                    </Row>
                                                                </CardBody>
                                                            </Card>
                                                        </Col>
                                                        <Col lg="4" md="6" sm="6">
                                                            <Card className={"card-stats " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                                                <CardBody>
                                                                    <Row>
                                                                        <Col md="3" xs="4">
                                                                            <div className="icon-big text-center icon-warning">
                                                                                <i className="fas fa-bars text-warning" />
                                                                            </div>
                                                                        </Col>
                                                                        <Col md="9" xs="8">
                                                                            <div className="numbers text-center">
                                                                                <p className="card-category newsize">PM1 - Average</p>
                                                                                <CardTitle tag="p" className="newsizetext">{this.state.Avgpm1} µg/m³</CardTitle>
                                                                                <p />
                                                                            </div>
                                                                        </Col>
                                                                    </Row>
                                                                </CardBody>
                                                            </Card>
                                                        </Col>
                                                    </Row>
                                                    <h5>TVOC Chart</h5>
                                                    <div style={{ width: "100%" }}>
                                                        <HighchartsReact
                                                            style={{ width: "100%" }}
                                                            highcharts={Highcharts}
                                                            constructorType={'stockChart'}
                                                            options={tvocConfig}
                                                            containerProps={{ className: 'chartContainer' }}
                                                            id="tvocchartdiv"
                                                        />
                                                    </div>
                                                    <Row>
                                                        <Col lg="4" md="6" sm="6">
                                                            <Card className={"card-stats " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                                                <CardBody>
                                                                    <Row>
                                                                        <Col md="3" xs="4">
                                                                            <div className="icon-big text-center icon-danger">
                                                                                <i className="fas fa-arrow-up text-danger" />
                                                                            </div>
                                                                        </Col>
                                                                        <Col md="9" xs="8">
                                                                            <div className="numbers text-center">
                                                                                <p className="card-category newsize">TVOC - High</p>
                                                                                <CardTitle tag="p" className="newsizetext">{this.state.hightvoc} mg/m³</CardTitle>
                                                                                <p />
                                                                            </div>
                                                                        </Col>
                                                                    </Row>
                                                                </CardBody>
                                                            </Card>
                                                        </Col>
                                                        <Col lg="4" md="6" sm="6">
                                                            <Card className={"card-stats " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                                                <CardBody>
                                                                    <Row>
                                                                        <Col md="3" xs="4">
                                                                            <div className="icon-big text-center icon-info">
                                                                                <i className="fas fa-arrow-down text-info" />
                                                                            </div>
                                                                        </Col>
                                                                        <Col md="9" xs="8">
                                                                            <div className="numbers text-center">
                                                                                <p className="card-category newsize">TVOC - Low</p>
                                                                                <CardTitle tag="p" className="newsizetext">{this.state.lowtvoc} mg/m³</CardTitle>
                                                                                <p />
                                                                            </div>
                                                                        </Col>
                                                                    </Row>
                                                                </CardBody>
                                                            </Card>
                                                        </Col>
                                                        <Col lg="4" md="6" sm="6">
                                                            <Card className={"card-stats " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                                                <CardBody>
                                                                    <Row>
                                                                        <Col md="3" xs="4">
                                                                            <div className="icon-big text-center icon-warning">
                                                                                <i className="fas fa-bars text-warning" />
                                                                            </div>
                                                                        </Col>
                                                                        <Col md="9" xs="8">
                                                                            <div className="numbers text-center">
                                                                                <p className="card-category newsize">TVOC - Average</p>
                                                                                <CardTitle tag="p" className="newsizetext">{this.state.Avgtvoc} mg/m³</CardTitle>
                                                                                <p />
                                                                            </div>
                                                                        </Col>
                                                                    </Row>
                                                                </CardBody>
                                                            </Card>
                                                        </Col>
                                                    </Row>

                                                </div>
                                            </TabPane>
                                            <TabPane tabId="2">
                                                <FieldGroup
                                                    control={this.interventionForm}
                                                    strict={false}
                                                    render={({ get, invalid, value }) => (
                                                        <form>
                                                            <Row>
                                                                <Col className="pr-1" md="3">
                                                                    <FieldControl
                                                                        name="intervention"
                                                                        strict={false}
                                                                        render={({ handler, touched, hasError }) => (
                                                                            <div className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                                                                <Grid className="Formfieldcss" container spacing={1}>
                                                                                    <Grid item>
                                                                                        <TextField type="text" required fullWidth variant="outlined" error={touched && hasError("required")} label="Intervention" {...handler("text")} />
                                                                                    </Grid>
                                                                                    <span className="error">
                                                                                        {touched
                                                                                            && hasError("required")
                                                                                            && `* Intervention is required`}
                                                                                    </span>
                                                                                </Grid>
                                                                            </div>
                                                                        )}
                                                                    />
                                                                </Col>
                                                                <Col className="pr-1" md="2">
                                                                    <FieldControl
                                                                        name="from_date"
                                                                        strict={false}
                                                                        render={({ handler, touched, hasError }) => (
                                                                            <div className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                                                                <Grid className="Formfieldcss" container spacing={1}>
                                                                                    <Grid item>
                                                                                        <TextField type="date" required fullWidth variant="outlined" label="Start Date" error={touched && hasError("required")}  {...handler("text")} />
                                                                                    </Grid>
                                                                                    <span className="error">
                                                                                        {touched
                                                                                            && hasError("required")
                                                                                            && `* Start Date is required`}
                                                                                    </span>
                                                                                </Grid>
                                                                            </div>
                                                                        )}
                                                                    />
                                                                </Col>
                                                                <Col className="pr-1" md="2">
                                                                    <FieldControl
                                                                        name="to_date"
                                                                        strict={false}
                                                                        render={({ handler, touched, hasError }) => (
                                                                            <div className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                                                                <Grid className="Formfieldcss" container spacing={1}>
                                                                                    <Grid item>
                                                                                        <TextField type="date" required fullWidth variant="outlined" label="End Date" error={touched && hasError("required")}  {...handler("text")} />
                                                                                    </Grid>
                                                                                    <span className="error">
                                                                                        {touched
                                                                                            && hasError("required")
                                                                                            && `* End Date is required`}
                                                                                    </span>
                                                                                </Grid>
                                                                            </div>
                                                                        )}
                                                                    />
                                                                </Col>
                                                                <Col className="pr-1" md="2">
                                                                    <FieldControl
                                                                        name="from_time"
                                                                        strict={false}
                                                                        render={({ handler, touched, hasError }) => (
                                                                            <div className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                                                                <Grid className="Formfieldcss" container spacing={1}>
                                                                                    <Grid item>
                                                                                        <TextField type="time" required fullWidth variant="outlined" label="Start Time" error={touched && hasError("required")}  {...handler("text")} />
                                                                                    </Grid>
                                                                                    <span className="error">
                                                                                        {touched
                                                                                            && hasError("required")
                                                                                            && `* Start Time is required`}
                                                                                    </span>
                                                                                </Grid>
                                                                            </div>
                                                                        )}
                                                                    />
                                                                </Col>
                                                                <Col className="pr-1" md="2">
                                                                    <FieldControl
                                                                        name="to_time"
                                                                        strict={false}
                                                                        render={({ handler, touched, hasError }) => (
                                                                            <div className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                                                                <Grid className="Formfieldcss" container spacing={1}>
                                                                                    <Grid item>
                                                                                        <TextField type="time" required fullWidth variant="outlined" label="End Time" error={touched && hasError("required")}  {...handler("text")} />
                                                                                    </Grid>
                                                                                    <span className="error">
                                                                                        {touched
                                                                                            && hasError("required")
                                                                                            && `* End Time is required`}
                                                                                    </span>
                                                                                </Grid>
                                                                            </div>
                                                                        )}
                                                                    />
                                                                </Col>
                                                                <Col className="pr-1" md="2">
                                                                    <div className="login-button changepassword text-center">
                                                                        <Button
                                                                            type="submit"
                                                                            variant="contained"
                                                                            color="primary"
                                                                            disabled={invalid}
                                                                            onClick={(event) => this.interventionhandleSubmit(event, this.interventionForm.getRawValue())}
                                                                        >
                                                                            Submit
                                                                        </Button>
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                        </form>
                                                    )}
                                                />
                                                <hr />
                                                <Row>
                                                    <Col>
                                                        <div className={"table-responsive " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                                            <table id="interventiontable" className={"nbztable dataTable no-footer " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                                                <thead>
                                                                    <tr>
                                                                        <th>Actions</th>
                                                                        <th>Intervention</th>
                                                                        <th>Start Date</th>
                                                                        <th>End Date</th>
                                                                        <th>Start Time</th>
                                                                        <th>End Time</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {this.state.intervention_list.map((value, index) => (
                                                                        <tr key={index + 1}>
                                                                            <td>
                                                                                <a href="javascript:void(0)" onClick={(event) => this.interventiondelete(event, value.id)}><i className="fa fa-trash icons"></i></a>&nbsp;&nbsp;&nbsp;&nbsp;
                                                                                <a href="javascript:void(0)" onClick={(e) => this.interventionedit(value)}><i className="fa fa-edit icons"></i></a>
                                                                            </td>
                                                                            <td>{value.intervention}</td>
                                                                            <td>{value.from_date}</td>
                                                                            <td>{value.to_date}</td>
                                                                            <td>{value.from_time}</td>
                                                                            <td>{value.to_time}</td>
                                                                        </tr>
                                                                    ))}
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </TabPane>
                                        </TabContent>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </>
                </div>
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        theme: state.theme,
        themecolor: state.themecolor,
        charttheme: state.charttheme
    }
}

export default connect(mapStateToProps)(SensorVisualization);
